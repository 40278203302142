<template>
  <div>
    <!-- <pre class="text-warning">{{ searchObjectMaster }}</pre>
    <pre class="text-warning">{{ categoryDescLong }}</pre> -->

    <!-- <pre class="text-warning">{{ multiSelectIncomeGuidList }}</pre>
    <pre class="text-warning">{{ multiSelectExpenseGuidList }}</pre> -->
    <!-- <pre class="text-warning">{{ multiSelectIncomeMonths }}</pre>
    <pre class="text-warning">{{ multiSelectExpenseMonths }}</pre> -->

    <!-- <input
      id="checkboxMultiAll"
      @click="setSelectedMultiAll($event)"
      class="form-check-input"
      type="checkbox"
    /> -->

    <!-- <div class="text-warning">Sum expenses is {{ multiSelectExpense }}</div>

    <div class="text-warning">Sum incomes is {{ multiSelectIncome }}</div> -->

    <!-- #region == BUTTONS WITH YEARS & MONTHS == -->

    <!-- <font-awesome-icon icon="fa-solid fa-user" />
    <font-awesome-icon icon="fa-solid fa-user-secret" />
    <font-awesome-icon icon="fa-solid fa-house" />
    <font-awesome-icon icon="fa-solid fa-circle-arrow-left" /> -->
    <!-- <pre class="text-warning">{{ getDataExpenseYear }}</pre> -->

    <!-- #region == TOP OF PAGE == -->
    <!-- TOP OF PAGE -->

    <div aria-live="polite" aria-atomic="true" class="position-relative">
      <div class="toast-container position-absolute top-0 end-0 p-3 primary">
        <base-toast :toastMessage="toastMessage1" id="mytoast1"></base-toast>
      </div>
    </div>

    <div
      class="toast align-items-center text-white bg-primary border-0"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">Hello, world! This is a toast message.</div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>

    <div v-if="debug">
      <pre>{{ year }}</pre>
      <pre>{{ getYear }}</pre>

      {{ getYear.previous }}
      {{ getYear.next }}
    </div>

    <div class="row">
      <div class="col-md-12">
        <!-- select year -->
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn btn-warning" @click="changeYear(-1)">
            <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
          </button>

          <button
            :class="getClassYear(getYear.previous)"
            type="button"
            class="btn btn-warning"
            @click="selectYear(getYear.previous)"
          >
            {{ getYear.previous }}
          </button>

          <button
            :class="getClassYear(getYear.current)"
            type="button"
            class="btn btn-warning"
            @click="selectYear(getYear.current)"
          >
            {{ getYear.current }}
          </button>

          <button
            :class="getClassYear(getYear.next)"
            type="button"
            class="btn btn-warning"
            @click="selectYear(getYear.next)"
          >
            {{ getYear.next }}
          </button>

          <button type="button" class="btn btn-warning" @click="changeYear(1)">
            <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
          </button>
        </div>

        &nbsp;<button
          type="button"
          class="btn btn-warning"
          @click="crossCheckYear()"
        >
          Cross check (year)</button
        >&nbsp;<button
          type="button"
          class="btn btn-warning"
          @click="crossCheckMonth()"
        >
          Cross check (months)
        </button>
        &nbsp;

        <base-button
          type="button"
          @click="crossCheckRepair()"
          :disabled="crossCheckErrorArrComp.length == 0"
          >Repair
          <!-- <font-awesome-icon icon="fa-pen-to-square" /> -->
        </base-button>

        <!-- <button
          :disabled="crossCheckErrorArrComp.length == 0"
          type="button"
          class="btn btn-warning"
          @click="crossCheckRepair()"
        >
          Repair
        </button> -->
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <textarea
          v-show="textareaVisible"
          v-model="textareaText"
          style="width: 100%; height: 300px"
        ></textarea>

        <textarea
          v-show="textareaVisible"
          v-model="textareaTextErr"
          style="width: 100%; height: 300px"
        ></textarea>
      </div>
    </div>

    <!-- #endregion -->

    <!-- #region == SAVINGS == -->
    <!-- SAVINGS -->
    <div class="row mt-3">
      <div class="col-md-12">
        <div
          v-if="getDataIncomeYear.length > 0"
          class="card border-warning bg-dark"
        >
          <div class="card-header bg-warning">
            <font-awesome-icon icon="fa-solid fa-table-cells" />
            <span translate=".savingsFor"> Savings for </span
            ><span> {{ getYear.selected }} </span>
          </div>
          <div class="card-body">
            <div class="card-text">
              <table class="table table-striped table-dark">
                <analysis-table-header
                  :year-comp="getYear.selected"
                  :category-visible="false"
                ></analysis-table-header>

                <tbody>
                  <tr>
                    <th></th>
                    <th></th>
                    <th style="vertical-align: middle" class="text-end">
                      {{ appUtils.formatCurrency(sumSavings.x01, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x02, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x03, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x04, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x05, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x06, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x07, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x08, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x09, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x10, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x11, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x12, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumSavings.x13, false) }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 class="text-warning ms-2">
            No data for year {{ getYear.selected }} !
          </h4>
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region == INCOMES == -->
    <div class="row mt-3">
      <div class="col-md-12">
        <!-- INCOMES -->
        <div
          v-if="getDataIncomeYear.length > 0"
          class="card border-warning bg-dark"
        >
          <div class="card-header bg-warning text-dark">
            <!-- <div class="card-header btn-warning text-white"> -->
            <font-awesome-icon icon="fa-solid fa-table-cells" /><span
              translate=".analysisIncome"
            >
              Income Analysis by Categories</span
            ><span> ({{ getYear.selected }}) </span>
            <span v-if="multiSelectIncomeMonths.x13 != 0"
              >[Sum of selected
              {{
                appUtils.formatCurrency(multiSelectIncomeMonths.x13, false)
              }}]</span
            >
          </div>

          <!-- <pre>{{ tableDataIncomeYear }}</pre> -->

          <div class="card-body">
            <span class="text-light"
              >Number of records ({{ getDataIncomeYear.length }})</span
            >

            <div class="card-text">
              <span class="text-light me-2">Select All</span>

              <input
                :checked="isCheckedAllIncome"
                @click="selectAllIncomesExpences(enumIncomeExpense.INCOME)"
                class="form-check-input"
                type="checkbox"
                id="checkbox_all_incomes"
              />

              <table class="table table-striped table-dark">
                <analysis-table-header
                  :year-comp="getYear.selected"
                  :excepences-incomes="'I'"
                  @sort-column="fOrder"
                ></analysis-table-header>

                <!-- ng-repeat-start="item in tableDataIncomeYear | orderBy: gridSortOrder.incomes" -->
                <tbody>
                  <analysis-row
                    v-for="item in getDataIncomeYear"
                    :key="item.guid"
                    :guid="item.guid"
                    :lvl="item.lvl"
                    :description="item.description"
                    :x01="appUtils.formatCurrency(item.x01, false)"
                    :x02="appUtils.formatCurrency(item.x02, false)"
                    :x03="appUtils.formatCurrency(item.x03, false)"
                    :x04="appUtils.formatCurrency(item.x04, false)"
                    :x05="appUtils.formatCurrency(item.x05, false)"
                    :x06="appUtils.formatCurrency(item.x06, false)"
                    :x07="appUtils.formatCurrency(item.x07, false)"
                    :x08="appUtils.formatCurrency(item.x08, false)"
                    :x09="appUtils.formatCurrency(item.x09, false)"
                    :x10="appUtils.formatCurrency(item.x10, false)"
                    :x11="appUtils.formatCurrency(item.x11, false)"
                    :x12="appUtils.formatCurrency(item.x12, false)"
                    :x13="appUtils.formatCurrency(item.x13, false)"
                    :typeofdata="enumIncomeExpense.INCOME"
                    :isclicked="
                      checkSelection(item.guid, enumIncomeExpense.INCOME)
                    "
                    @select-guid="selectGuid"
                    @select-multi-id="selectMultiId"
                  >
                    <template
                      style="margin-left: 10px"
                      v-if="showLevel2(item.guid)"
                      #details2
                    >
                      <analysis-row
                        v-for="item2 in getDataYearLevel2.filter(
                          (el) => el.guid_parent === item.guid
                        )"
                        :key="item2.guid"
                        :guid="item2.guid"
                        :lvl="item2.lvl"
                        :description="item2.description"
                        :x01="appUtils.formatCurrency(item2.x01, false)"
                        :x02="appUtils.formatCurrency(item2.x02, false)"
                        :x03="appUtils.formatCurrency(item2.x03, false)"
                        :x04="appUtils.formatCurrency(item2.x04, false)"
                        :x05="appUtils.formatCurrency(item2.x05, false)"
                        :x06="appUtils.formatCurrency(item2.x06, false)"
                        :x07="appUtils.formatCurrency(item2.x07, false)"
                        :x08="appUtils.formatCurrency(item2.x08, false)"
                        :x09="appUtils.formatCurrency(item2.x09, false)"
                        :x10="appUtils.formatCurrency(item2.x10, false)"
                        :x11="appUtils.formatCurrency(item2.x11, false)"
                        :x12="appUtils.formatCurrency(item2.x12, false)"
                        :x13="appUtils.formatCurrency(item2.x13, false)"
                        :typeofdata="enumIncomeExpense.INCOME"
                        :isclicked="
                          checkSelection(item2.guid, enumIncomeExpense.INCOME)
                        "
                        @select-guid="selectGuid"
                        @select-multi-id="selectMultiId"
                      >
                        <template v-if="showLevel3(item2.guid)" #details3>
                          <analysis-row
                            v-for="item3 in getDataYearLevel3.filter(
                              (el) => el.guid_parent === item2.guid
                            )"
                            :key="item3.guid"
                            :guid="item3.guid"
                            :lvl="item3.lvl"
                            :description="item3.description"
                            :x01="appUtils.formatCurrency(item3.x01, false)"
                            :x02="appUtils.formatCurrency(item3.x02, false)"
                            :x03="appUtils.formatCurrency(item3.x03, false)"
                            :x04="appUtils.formatCurrency(item3.x04, false)"
                            :x05="appUtils.formatCurrency(item3.x05, false)"
                            :x06="appUtils.formatCurrency(item3.x06, false)"
                            :x07="appUtils.formatCurrency(item3.x07, false)"
                            :x08="appUtils.formatCurrency(item3.x08, false)"
                            :x09="appUtils.formatCurrency(item3.x09, false)"
                            :x10="appUtils.formatCurrency(item3.x10, false)"
                            :x11="appUtils.formatCurrency(item3.x11, false)"
                            :x12="appUtils.formatCurrency(item3.x12, false)"
                            :x13="appUtils.formatCurrency(item3.x13, false)"
                            :typeofdata="enumIncomeExpense.INCOME"
                            :isclicked="
                              checkSelection(
                                item3.guid,
                                enumIncomeExpense.INCOME
                              )
                            "
                            @select-guid="selectGuid"
                            @select-multi-id="selectMultiId"
                          >
                            <template v-if="showLevel4(item3.guid)" #details4>
                              <analysis-row
                                v-for="item4 in getDataYearLevel4.filter(
                                  (el) => el.guid_parent === item3.guid
                                )"
                                :key="item4.guid"
                                :guid="item4.guid"
                                :lvl="item4.lvl"
                                :description="item4.description"
                                :x01="appUtils.formatCurrency(item4.x01)"
                                :x02="appUtils.formatCurrency(item4.x02)"
                                :x03="appUtils.formatCurrency(item4.x03)"
                                :x04="appUtils.formatCurrency(item4.x04)"
                                :x05="appUtils.formatCurrency(item4.x05)"
                                :x06="appUtils.formatCurrency(item4.x06)"
                                :x07="appUtils.formatCurrency(item4.x07)"
                                :x08="appUtils.formatCurrency(item4.x08)"
                                :x09="appUtils.formatCurrency(item4.x09)"
                                :x10="appUtils.formatCurrency(item4.x10)"
                                :x11="appUtils.formatCurrency(item4.x11)"
                                :x12="appUtils.formatCurrency(item4.x12)"
                                :x13="appUtils.formatCurrency(item4.x13)"
                                :typeofdata="enumIncomeExpense.INCOME"
                                :isclicked="
                                  checkSelection(
                                    item4.guid,
                                    enumIncomeExpense.INCOME
                                  )
                                "
                                @select-guid="selectGuid"
                                @select-multi-id="selectMultiId"
                              >
                              </analysis-row>
                            </template>
                          </analysis-row>
                        </template>
                      </analysis-row>
                    </template>
                  </analysis-row>
                  <!-- </tbody> -->
                  <tr>
                    <th><span></span></th>
                    <th><span translate="general.sum">Total:</span></th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x01, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x02, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x03, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x04, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x05, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x06, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x07, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x08, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x09, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x10, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x11, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x12, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(sumIncome.x13, false) }}
                    </th>
                  </tr>

                  <tr v-if="multiSelectIncomeMonths.x13 != 0">
                    <td><span></span></td>
                    <td>
                      <span class="text-warning" translate="general.sum"
                        >Total selected:</span
                      >
                    </td>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x01"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x02"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x03"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x04"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x05"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x06"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x07"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x08"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x09"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x10"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x11"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x12"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectIncomeMonths.x13"
                    >
                    </selected-category>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{tableDataIncomeYear2 | json}}</pre>-->
    <!-- #endregion -->

    <!-- #region == EXPENSES == -->
    <!-- EXPENSES -->
    <div class="row mt-3">
      <div class="col-md-12">
        <div
          v-if="getDataExpenseYear.length > 0"
          class="card border-warning bg-dark"
        >
          <div class="card-header bg-warning text-dark">
            <font-awesome-icon icon="fa-solid fa-table-cells" /><span
              translate=".analysisExpense"
            >
              Expense Analysis by Categories</span
            ><span> ({{ getYear.selected }}) </span
            ><span v-if="multiSelectExpenseMonths.x13 != 0"
              >[Sum of selected
              {{
                appUtils.formatCurrency(multiSelectExpenseMonths.x13, false)
              }}]</span
            >
          </div>
          <!-- style="background-color: rgba(0, 0, 0, 0.87)" -->
          <div class="card-body">
            <span class="text-light"
              >Number of records ({{ getDataExpenseYear.length }})
            </span>

            <div class="card-text">
              <span class="text-light me-2">Select All</span>
              <input
                :checked="isCheckedAllExpence"
                @click="selectAllIncomesExpences(enumIncomeExpense.EXPENSE)"
                class="form-check-input"
                type="checkbox"
                id="checkbox_all_expenses"
              />

              <table class="table table-striped table-dark">
                <analysis-table-header
                  :year-comp="getYear.selected"
                  :excepences-incomes="'E'"
                  @sort-column="fOrder"
                ></analysis-table-header>

                <tbody>
                  <analysis-row
                    v-for="item in getDataExpenseYear"
                    :key="item.guid"
                    :guid="item.guid"
                    :lvl="item.lvl"
                    :description="item.description"
                    :x01="appUtils.formatCurrency(item.x01, false)"
                    :x02="appUtils.formatCurrency(item.x02, false)"
                    :x03="appUtils.formatCurrency(item.x03, false)"
                    :x04="appUtils.formatCurrency(item.x04, false)"
                    :x05="appUtils.formatCurrency(item.x05, false)"
                    :x06="appUtils.formatCurrency(item.x06, false)"
                    :x07="appUtils.formatCurrency(item.x07, false)"
                    :x08="appUtils.formatCurrency(item.x08, false)"
                    :x09="appUtils.formatCurrency(item.x09, false)"
                    :x10="appUtils.formatCurrency(item.x10, false)"
                    :x11="appUtils.formatCurrency(item.x11, false)"
                    :x12="appUtils.formatCurrency(item.x12, false)"
                    :x13="appUtils.formatCurrency(item.x13, false)"
                    :typeofdata="enumIncomeExpense.EXPENSE"
                    :isclicked="
                      checkSelection(item.guid, enumIncomeExpense.EXPENSE)
                    "
                    @select-guid="selectGuid"
                    @select-multi-id="selectMultiId"
                  >
                    <template v-if="showLevel2(item.guid)" #details2>
                      <analysis-row
                        v-for="item2 in getDataYearLevel2.filter(
                          (el) => el.guid_parent === item.guid
                        )"
                        :key="item2.guid"
                        :guid="item2.guid"
                        :lvl="item2.lvl"
                        :description="item2.description"
                        :x01="appUtils.formatCurrency(item2.x01, false)"
                        :x02="appUtils.formatCurrency(item2.x02, false)"
                        :x03="appUtils.formatCurrency(item2.x03, false)"
                        :x04="appUtils.formatCurrency(item2.x04, false)"
                        :x05="appUtils.formatCurrency(item2.x05, false)"
                        :x06="appUtils.formatCurrency(item2.x06, false)"
                        :x07="appUtils.formatCurrency(item2.x07, false)"
                        :x08="appUtils.formatCurrency(item2.x08, false)"
                        :x09="appUtils.formatCurrency(item2.x09, false)"
                        :x10="appUtils.formatCurrency(item2.x10, false)"
                        :x11="appUtils.formatCurrency(item2.x11, false)"
                        :x12="appUtils.formatCurrency(item2.x12, false)"
                        :x13="appUtils.formatCurrency(item2.x13, false)"
                        :typeofdata="enumIncomeExpense.EXPENSE"
                        :isclicked="
                          checkSelection(item2.guid, enumIncomeExpense.EXPENSE)
                        "
                        @select-guid="selectGuid"
                        @select-multi-id="selectMultiId"
                      >
                        <template v-if="showLevel3(item2.guid)" #details3>
                          <analysis-row
                            v-for="item3 in getDataYearLevel3.filter(
                              (el) => el.guid_parent === item2.guid
                            )"
                            :key="item3.guid"
                            :guid="item3.guid"
                            :lvl="item3.lvl"
                            :description="item3.description"
                            :x01="appUtils.formatCurrency(item3.x01, false)"
                            :x02="appUtils.formatCurrency(item3.x02, false)"
                            :x03="appUtils.formatCurrency(item3.x03, false)"
                            :x04="appUtils.formatCurrency(item3.x04, false)"
                            :x05="appUtils.formatCurrency(item3.x05, false)"
                            :x06="appUtils.formatCurrency(item3.x06, false)"
                            :x07="appUtils.formatCurrency(item3.x07, false)"
                            :x08="appUtils.formatCurrency(item3.x08, false)"
                            :x09="appUtils.formatCurrency(item3.x09, false)"
                            :x10="appUtils.formatCurrency(item3.x10, false)"
                            :x11="appUtils.formatCurrency(item3.x11, false)"
                            :x12="appUtils.formatCurrency(item3.x12, false)"
                            :x13="appUtils.formatCurrency(item3.x13, false)"
                            :typeofdata="enumIncomeExpense.EXPENSE"
                            :isclicked="
                              checkSelection(
                                item3.guid,
                                enumIncomeExpense.EXPENSE
                              )
                            "
                            @select-guid="selectGuid"
                            @select-multi-id="selectMultiId"
                          >
                            <template v-if="showLevel4(item3.guid)" #details4>
                              <analysis-row
                                v-for="item4 in getDataYearLevel4.filter(
                                  (el) => el.guid_parent === item3.guid
                                )"
                                :key="item4.guid"
                                :guid="item4.guid"
                                :lvl="item4.lvl"
                                :description="item4.description"
                                :x01="appUtils.formatCurrency(item4.x01)"
                                :x02="appUtils.formatCurrency(item4.x02)"
                                :x03="appUtils.formatCurrency(item4.x03)"
                                :x04="appUtils.formatCurrency(item4.x04)"
                                :x05="appUtils.formatCurrency(item4.x05)"
                                :x06="appUtils.formatCurrency(item4.x06)"
                                :x07="appUtils.formatCurrency(item4.x07)"
                                :x08="appUtils.formatCurrency(item4.x08)"
                                :x09="appUtils.formatCurrency(item4.x09)"
                                :x10="appUtils.formatCurrency(item4.x10)"
                                :x11="appUtils.formatCurrency(item4.x11)"
                                :x12="appUtils.formatCurrency(item4.x12)"
                                :x13="appUtils.formatCurrency(item4.x13)"
                                :typeofdata="enumIncomeExpense.EXPENSE"
                                :isclicked="
                                  checkSelection(
                                    item.guid4,
                                    enumIncomeExpense.EXPENSE
                                  )
                                "
                                @select-guid="selectGuid"
                                @select-multi-id="selectMultiId"
                              >
                              </analysis-row>
                            </template>
                          </analysis-row>
                        </template>
                      </analysis-row>
                    </template>
                  </analysis-row>
                  <!-- </tbody> -->

                  <tr>
                    <th><span></span></th>
                    <th><span translate="general.sum">Total:</span></th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x01, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x02, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x03, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x04, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x05, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x06, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x07, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x08, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x09, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x10, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x11, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x12, false) }}
                    </th>
                    <th style="vertical-align: middle; text-align: right">
                      {{ appUtils.formatCurrency(getSum.x13, false) }}
                    </th>
                  </tr>

                  <tr v-if="multiSelectExpenseMonths.x13 != 0">
                    <td><span></span></td>
                    <td>
                      <span class="text-warning" translate="general.sum"
                        >Total selected:</span
                      >
                    </td>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x01"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x02"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x03"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x04"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x05"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x06"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x07"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x08"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x09"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x10"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x11"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x12"
                    >
                    </selected-category>

                    <selected-category
                      :multiSelectMonthValue="multiSelectExpenseMonths.x13"
                    >
                    </selected-category>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region Transactions details -->
    <transition name="fade">
      <transactions-for-analysis
        :getFilteredTrans="getFilteredTrans"
        :showTransactionsFlag="showTransactionsFlag"
      ></transactions-for-analysis>
    </transition>
    <!-- #endregion -->
  </div>
</template>

<style scoped>
.jractive {
  background-color: #282828;
  font-weight: bold;
}

/* ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
} */
</style>

<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted } from 'vue';
import appUtils from '../../components/utils/appUtils.js';
import utilsAnalysis from '../../components/utils/utilsAnalysis.js';
import AnalysisRow from '../../components/analysis/AnalysisRow.vue';
import SelectedCategory from '../../components/analysis/SelectedCategory.vue';
import AnalysisTableHeader from '../../components/analysis/AnalysisTableHeader.vue';
import { getDatabase } from 'firebase/database';
import { useTransStore } from '../../stores/TransStore';
import { toNumber } from '@vue/shared';
import { saveToFirebaseWithOptions } from '../../components/utils/firebaseUtils';
import { Toast } from 'bootstrap';
import TransactionsForAnalysis from '../../components/transactions/TransactionsForAnalysis.vue';

// import {
//   getInitialSearchObject,
//   fastFilterTrans,
//   fixSearchObj,
// } from '../../pages/transactions/utilsTransactions.js';

import {
  fixSearchObj,
  getInitialSearchObject,
} from '../../pages/transactions/utilsTransactions.js';

const debug = false;
const currentOffset = ref(0);
let year = reactive({});
let arrDataYearLvl1 = reactive([]);
let arrDataYearLvl2 = reactive([]);
let arrDataYearLvl3 = reactive([]);
let arrDataYearLvl4 = reactive([]);
let arrTotalIncomes = reactive({});
let arrTotalExpenses = reactive({});
let arrTotalSavings = reactive({});
let selectetGuidArr11 = reactive([]);
let selectetGuidArr22 = reactive([]);
let selectetGuidArr33 = reactive([]);
let sortExpences = ref('description');
let sortIncomes = ref('description');
const sortOrderExpences = ref('asc');
const sortOrderIncomes = ref('asc');

let isCheckedAllIncome = ref(false);
let isCheckedAllExpence = ref(false);

let textareaVisible = ref(false);
let textareaText = ref('');
let textareaTextErr = ref('');
let crossCheckErrorArr = reactive([]);
let toastList = reactive([]);
let toastMessage1 = ref('');

let showTransactionsFlag = ref(false);
let categoryDescLong = ref('');
let searchObjectMaster = {};
// const dataSorting = ref('');
// const dataSortingOrder = ref('asc');
// const searchText = ref(null);
//const pageStore = useTransStore();

// let multiSelectExpense = ref(0);
// let multiSelectIncome = ref(0);

const multiSelectIncomeMonths = reactive({});
const multiSelectExpenseMonths = reactive({});

const multiSelectIncomeGuidList = reactive([]);
const multiSelectExpenseGuidList = reactive([]);

const enumIncomeExpense = {
  INCOME: 'income',
  EXPENSE: 'expense',
};

//for child component
// let guidForChildComponent = ref();
// let lvlForChildComponent = ref();

//let transMultiId = reactive([]);

onBeforeMount(() => {
  year = reactive(utilsAnalysis.getYears());
  selectYear(year.current);
});

onMounted(() => {
  //console.clear();

  console.log('onMounted');
  resetMultiSelection();

  var toastElList = [].slice.call(document.querySelectorAll('.toast'));
  toastList = toastElList.map(function (toastEl) {
    return new Toast(toastEl, { autohide: true, delay: 2000 });
  });
});

function resetMultiSelection() {
  resetMultiSelectionIncome();
  resetMultiSelectionExpense();
}

function resetMultiSelectionIncome() {
  multiSelectIncomeGuidList.length = 0;
  utilsAnalysis.initializeSums(multiSelectIncomeMonths);
}

function resetMultiSelectionExpense() {
  multiSelectExpenseGuidList.length = 0;
  utilsAnalysis.initializeSums(multiSelectExpenseMonths);
}

function getData() {
  resetMultiSelection();
  utilsAnalysis.initializeArr(arrDataYearLvl1, appUtils.getCategory(1));
  utilsAnalysis.initializeArr(arrDataYearLvl2, appUtils.getCategory(2));
  utilsAnalysis.initializeArr(arrDataYearLvl3, appUtils.getCategory(3));
  utilsAnalysis.initializeArr(arrDataYearLvl4, appUtils.getCategory(4));

  utilsAnalysis.initializeSums(arrTotalIncomes);
  utilsAnalysis.initializeSums(arrTotalExpenses);
  utilsAnalysis.initializeSums(arrTotalSavings);

  selectetGuidArr11.length = 0;
  selectetGuidArr22.length = 0;
  selectetGuidArr33.length = 0;

  let promises = [];

  for (let i = 1; i < 13; i++) {
    let month = ('0' + String(i)).slice(-2);
    promises.push(
      appUtils.getAnalysisYearMonth(year.selected + '-' + month, true)
    );
  }

  Promise.all(promises).then((values) => {
    for (let i = 0; i < 13 - 1; i++) {
      utilsAnalysis.prepareAnalysisYear(
        values[i],
        arrDataYearLvl1,
        arrDataYearLvl2,
        arrDataYearLvl3,
        arrDataYearLvl4,
        arrTotalIncomes,
        arrTotalExpenses
      );

      //prepareAnalysisYear(values[i]);
    }

    utilsAnalysis.getSumMonths(arrDataYearLvl1);
    utilsAnalysis.getSumMonths(arrDataYearLvl2);
    utilsAnalysis.getSumMonths(arrDataYearLvl3);
    utilsAnalysis.getSumMonths(arrDataYearLvl4);

    utilsAnalysis.getYearlyTotals(
      arrTotalIncomes,
      arrTotalExpenses,
      arrTotalSavings
    );

    utilsAnalysis.countChildren(arrDataYearLvl1, arrDataYearLvl2);
    utilsAnalysis.countChildren(arrDataYearLvl2, arrDataYearLvl3);
    utilsAnalysis.countChildren(arrDataYearLvl3, arrDataYearLvl4);
  });
}

function changeYear(offset) {
  var tmp = year.selected;
  currentOffset.value = currentOffset.value + offset;
  const tmpObj = utilsAnalysis.getYearsOffset(currentOffset.value);
  tmpObj.selected = tmp;
  Object.assign(year, tmpObj);
}

const getYear = computed(function () {
  return year;
});

const sumSavings = computed(() => {
  return arrTotalSavings;
});

const sumIncome = computed(() => {
  return arrTotalIncomes;
});

const getSum = computed(() => {
  return arrTotalExpenses;
});

const getDataYearLevel2 = computed(() => {
  return getDataYearLevelUtil(arrDataYearLvl2);
});

const getDataYearLevel3 = computed(() => {
  return getDataYearLevelUtil(arrDataYearLvl3);
});

const getDataYearLevel4 = computed(() => {
  return getDataYearLevelUtil(arrDataYearLvl4);
});

function getDataYearLevelUtil(parArray) {
  return parArray
    .filter((el) => el.x13 != 0)
    .sort((a, b) => {
      return b.x13 - a.x13;
    });
}

const getDataIncomeYear = computed(() => {
  // return arrDataYearLvl1
  //   .filter((el) => el.x13 != 0 && el.type == true)
  //   .sort((a, b) => {
  //     return b.x13 - a.x13;
  //   });
  return arrDataYearLvl1
    .filter((el) => el.x13 != 0 && el.type == true)
    .sort((a, b) =>
      appUtils.sortByKey1(a, b, sortIncomes.value, sortOrderIncomes.value)
    );
});

const getDataExpenseYear = computed(() => {
  return arrDataYearLvl1
    .filter((el) => el.x13 != 0 && el.type == false)
    .sort((a, b) =>
      appUtils.sortByKey1(a, b, sortExpences.value, sortOrderExpences.value)
    );
});

const crossCheckErrorArrComp = computed(() => {
  return crossCheckErrorArr;
});

function fOrder(params) {
  let field = params[0];
  let excepencesIncomes = params[1];

  // appUtils.conlog('sortIncomes =>', sortIncomes.value);
  // appUtils.conlog('sortOrderIncomes =>', sortOrderIncomes.value);
  // appUtils.conlog('excepencesIncomes =>', excepencesIncomes);

  if (excepencesIncomes == 'E') {
    //expences
    if (field == sortExpences.value) {
      if (sortOrderExpences.value == 'asc') sortOrderExpences.value = 'desc';
      else sortOrderExpences.value = 'asc';
    } else sortOrderExpences.value = 'asc';
    sortExpences.value = field;
  } else if (excepencesIncomes == 'I') {
    //incomes
    if (field == sortIncomes.value) {
      if (sortOrderIncomes.value == 'asc') sortOrderIncomes.value = 'desc';
      else sortOrderIncomes.value = 'asc';
    } else sortOrderIncomes.value = 'asc';
    sortIncomes.value = field;
  }

  //appUtils.conlog('field =>', field);
  //appUtils.conlog('sortOrder.value', sortOrderExpences.value);
}

function selectYear(parYear) {
  year.selected = parYear;
  showTransactionsFlag.value = false;
  getData();
}

function getClassYear(parYear) {
  if (year.selected === parYear) {
    return 'jractive';
  } else {
    return '';
  }
}

function showLevelUtil(parArray, guid) {
  if (parArray.includes(guid) && getDataYearLevel2.value.length > 0)
    return true;
  else return false;
}

function showLevel2(guid) {
  return showLevelUtil(selectetGuidArr11, guid);
}

function showLevel3(guid) {
  return showLevelUtil(selectetGuidArr22, guid);
}

function showLevel4(guid) {
  return showLevelUtil(selectetGuidArr33, guid);
}

function crossCheckMonth() {
  textareaText.value = '';
  textareaTextErr.value = '';

  crossCheckErrorArr.length = 0;

  var appendZero = '0';
  let promises = [];
  for (var i = 1; i <= 12; i++) {
    if (i >= 10) appendZero = '';

    promises.push(
      searchTrans1({
        searchTermin: year.selected + '-' + appendZero + i.toString(),
      })
    );
  }

  Promise.all(promises).then(() => {
    if (textareaText.value != '') textareaVisible.value = true;
  });
}

function crossCheckYear() {
  textareaText.value = '';
  textareaTextErr.value = '';

  searchTrans1({ searchTermin: year.selected }).then(() => {
    if (textareaText.value != '') textareaVisible.value = true;
  });
}

function crossCheckGetSumTrans(guidknt1ArrCross, transCrossCheck) {
  //calculate sum for each unique guidknt1 from transactions
  var sumaArrCross = [];
  for (var i = 0; i < guidknt1ArrCross.length; i++) {
    var suma = 0;
    for (var j = 0; j < transCrossCheck.length; j++) {
      if (transCrossCheck[j].guidknt1 != null) {
        if (transCrossCheck[j].guidknt1 === guidknt1ArrCross[i]) {
          if (typeof transCrossCheck[j].amount == 'string') {
            suma = appUtils.getSumofTwoNumbers(
              suma,
              toNumber(transCrossCheck[j].amount, 2)
            );
            //console.log(transCrossCheck[j]);
          } else {
            suma = appUtils.getSumofTwoNumbers(suma, transCrossCheck[j].amount);
          }
        }
      }
    }
    sumaArrCross.push({
      guid: guidknt1ArrCross[i],
      suma: suma.toFixed(2),
      sumaNum: suma,
    });
  }

  //console.log(' ========= sumaArrCross ========= ');
  //console.log(sumaArrCross);
  return sumaArrCross;
}

function crossCheckRepair() {
  console.log(crossCheckErrorArr);

  let promises = [];

  for (var i = 0; i < crossCheckErrorArrComp.value.length; i++) {
    if (crossCheckErrorArrComp.value[i].termin.length == 7) {
      //only if termin in format YYYY-MM (7 char long)
      let parTermin = crossCheckErrorArrComp.value[i].termin;
      let parCategoryGuid = crossCheckErrorArrComp.value[i].guidKonto;

      let optionObj = {
        url: `balance_konto/{uid}/${parTermin}/${parCategoryGuid}/balance`,
      };

      let saveObj = crossCheckErrorArrComp.value[i].balance;
      //we don't need object just value since we are updating just balance value

      promises.push(saveToFirebaseWithOptions(optionObj, saveObj));
    }
  }

  if (promises.length > 0) {
    Promise.all(promises).then(() => {
      console.log('crossCheckRepair DONE!');
      toastMessage1.value = 'Repair Done!';
      toastList[0].show();
      getData();
      crossCheckErrorArr.length = 0;
    });
  }

  //console.log(toastList);
}

function crossCheckTransAndAnalysis(transCrossCheck, searchTermin) {
  var guidknt1ArrCross = [];

  var arrayField = '';
  if (searchTermin.length == 4) {
    arrayField = 'x13';
  } else {
    arrayField = 'x' + appUtils.getMonth(searchTermin);
  }

  // console.log(searchTermin);
  // console.log(arrayField);

  //fill up array with distinct guidknt1
  for (var i = 0; i < transCrossCheck.length; i++) {
    if (transCrossCheck[i].guidknt1 != null) {
      if (guidknt1ArrCross.indexOf(transCrossCheck[i].guidknt1) === -1) {
        guidknt1ArrCross.push(transCrossCheck[i].guidknt1);
      }
    }
  }

  var sumaArrCross = crossCheckGetSumTrans(guidknt1ArrCross, transCrossCheck);
  var sumTotalDifference = 0;
  //compare sum from transactions with analysis sum
  for (i = 0; i < sumaArrCross.length; i++) {
    for (var j = 0; j < getDataIncomeYear.value.length; j++) {
      if (getDataIncomeYear.value[j].guid == sumaArrCross[i].guid) {
        if (getDataIncomeYear.value[j][arrayField] == sumaArrCross[i].suma) {
          setTextforTextarea(
            searchTermin,
            getDataIncomeYear.value[j].guid,
            getDataIncomeYear.value[j].description,
            sumaArrCross[i].suma,
            getDataIncomeYear.value[j][arrayField]
          );
        } else {
          setTextforTextarea(
            searchTermin,
            getDataIncomeYear.value[j].guid,
            getDataIncomeYear.value[j].description,
            sumaArrCross[i].suma,
            getDataIncomeYear.value[j][arrayField],
            '======== ERROR | '
          );

          crossCheckErrorArr.push({
            termin: searchTermin,
            guidKonto: getDataIncomeYear.value[j].guid,
            balance: appUtils.getSumofTwoNumbers(sumaArrCross[i].sumaNum, 0),
          });

          sumTotalDifference = appUtils.getSumofTwoNumbers(
            sumTotalDifference,
            getDataExpenseYear.value[j][arrayField]
          );
          sumTotalDifference = appUtils.getSumofTwoNumbers(
            sumTotalDifference,
            -1 * sumaArrCross[i].suma
          );
        }
      }
    }

    for (j = 0; j < getDataExpenseYear.value.length; j++) {
      if (getDataExpenseYear.value[j].guid == sumaArrCross[i].guid) {
        if (getDataExpenseYear.value[j][arrayField] == sumaArrCross[i].suma) {
          setTextforTextarea(
            searchTermin,
            getDataExpenseYear.value[j].guid,
            getDataExpenseYear.value[j].description,
            sumaArrCross[i].suma,
            getDataExpenseYear.value[j][arrayField]
          );
        } else {
          setTextforTextarea(
            searchTermin,
            getDataExpenseYear.value[j].guid,
            getDataExpenseYear.value[j].description,
            sumaArrCross[i].suma,
            getDataExpenseYear.value[j][arrayField],
            '======== ERROR | '
          );

          crossCheckErrorArr.push({
            termin: searchTermin,
            guidKonto: getDataExpenseYear.value[j].guid,
            balance: appUtils.getSumofTwoNumbers(sumaArrCross[i].sumaNum, 0),
          });

          sumTotalDifference = appUtils.getSumofTwoNumbers(
            sumTotalDifference,
            getDataExpenseYear.value[j][arrayField]
          );
          sumTotalDifference = appUtils.getSumofTwoNumbers(
            sumTotalDifference,
            -1 * sumaArrCross[i].suma
          );
        }
      }
    }

    if (i == sumaArrCross.length - 1) {
      textareaText.value +=
        '=====================================================' + '\n';
    }
  }
}

function setTextforTextarea(
  termin,
  category,
  categoryDesc,
  sumTrans,
  sumAnalysis,
  errorTxt = ''
) {
  //setTextforTextarea(searchTermin,getDataExpenseYear.value[j].guid , getDataExpenseYear.value[j].description, sumaArrCross[i].suma, getDataExpenseYear.value[j][arrayField] )

  textareaText.value +=
    errorTxt +
    termin +
    ' => Sum for *' +
    category +
    '* (' +
    (categoryDesc + '                            ').substring(0, 26) +
    ') Sum trans=' +
    ('          ' + sumTrans.toString()).slice(-10) +
    ' | Sum analysis=' +
    ('          ' + sumAnalysis.toFixed(2).toString()).slice(-10) +
    (errorTxt == '' ? '' : ' ========') +
    '\n';

  if (errorTxt !== '') {
    textareaTextErr.value +=
      errorTxt +
      termin +
      ' => Sum for *' +
      category +
      '* (' +
      (categoryDesc + '                            ').substring(0, 26) +
      ') Sum trans=' +
      ('          ' + sumTrans.toString()).slice(-10) +
      ' | Sum analysis=' +
      ('          ' + sumAnalysis.toFixed(2).toString()).slice(-10) +
      (errorTxt == '' ? '' : ' ========') +
      '\n';
  }
}

function searchTrans1(searchObj) {
  let promise = new Promise((resolve, reject) => {
    try {
      const db = getDatabase();
      const transStore = useTransStore();
      transStore
        .getLastTrans({
          db,
          searchObj,
        })
        .then(() => {
          const transCrossCheck = transStore.transactions;
          crossCheckTransAndAnalysis(transCrossCheck, searchObj.searchTermin);
          resolve();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          //console.log('finally searchTrans Analysis()');
        });
    } catch (error) {
      reject(new Error(error.message));
    }
  });

  return promise;
}

function removeChildren(parGuidParent, parGuidList) {
  //check if any child is selected then we need to remove it from list
  //if parent category is selected we remove all children

  let tmpArr = appUtils.getObjectCopy(parGuidList);
  for (var i = 0; i < tmpArr.length; i++) {
    let tmpObj = appUtils.getGuidKontoObj(tmpArr[i]);
    if (tmpObj.guid_parent == parGuidParent) {
      parGuidList.splice(parGuidList.indexOf(tmpObj.guid), 1);
    }
  }
}

function reCalculateSumOfSelected(incomeExpence) {
  if (incomeExpence == enumIncomeExpense.INCOME) {
    /* =============== INCOMES =============== */

    //setting sums for all months to 0
    utilsAnalysis.initializeSums(multiSelectIncomeMonths);

    for (var i = 0; i < multiSelectIncomeGuidList.length; i++) {
      let tmpObj = appUtils.getGuidKontoObj(multiSelectIncomeGuidList[i]);
      calculateSumOfSelected(
        multiSelectIncomeMonths,
        multiSelectIncomeGuidList[i],
        tmpObj.lvl,
        incomeExpence,
        1
      );
    }
  } else if (incomeExpence == enumIncomeExpense.EXPENSE) {
    /* =============== EXPENCES =============== */

    //setting sums for all months to 0
    utilsAnalysis.initializeSums(multiSelectExpenseMonths);

    for (i = 0; i < multiSelectExpenseGuidList.length; i++) {
      let tmpObj = appUtils.getGuidKontoObj(multiSelectExpenseGuidList[i]);
      calculateSumOfSelected(
        multiSelectExpenseMonths,
        multiSelectExpenseGuidList[i],
        tmpObj.lvl,
        incomeExpence,
        1
      );
    }
  }
}

function calculateSumOfSelected(
  parMultiSelectMonths,
  transGuid,
  parLvl,
  typeofdata,
  plusMinus
) {
  let trans;

  if (typeofdata == enumIncomeExpense.EXPENSE) {
    trans = getDataExpenseYear;
  } else if (typeofdata == enumIncomeExpense.INCOME) {
    trans = getDataIncomeYear;
  } else {
    return;
  }

  if (parLvl == 2) {
    trans = getDataYearLevel2;
  } else if (parLvl == 3) {
    trans = getDataYearLevel3;
  } else if (parLvl == 4) {
    trans = getDataYearLevel4;
  }

  for (var i = 0; i < trans.value.length; i++) {
    if (trans.value[i].guid === transGuid) {
      for (var j = 0; j < 14; j++) {
        let month = 'x' + ('0' + String(j)).slice(-2);
        parMultiSelectMonths[month] = appUtils.getSumofTwoNumbers(
          parMultiSelectMonths[month],
          plusMinus * trans.value[i][month]
        );
      }
      //console.log(parMultiSelectMonths);
    }
  }
}

function selectGuid(params) {
  /* handler for child-component*/

  let guid = params[0];
  let lvl = params[1];

  if (lvl === 1) {
    utilsAnalysis.selectGuidUtil(selectetGuidArr11, guid);
  } else if (lvl === 2) {
    utilsAnalysis.selectGuidUtil(selectetGuidArr22, guid);
  } else if (lvl === 3) {
    utilsAnalysis.selectGuidUtil(selectetGuidArr33, guid);
  }

  // guidForChildComponent.value = guid;
  // lvlForChildComponent.value = lvl;
  searchMaster(guid, lvl);
}

function selectMultiId(eventArg) {
  //let transGuid = eventArg.guid;
  //let typeofdata = eventArg.typeofdata;
  //selectMultiIdTool(transGuid, typeofdata);

  let parGuid = eventArg.guid;
  let parTypeofdata = eventArg.typeofdata;
  //let eventLvl = eventArg.lvl;

  let parGuidList;

  if (parTypeofdata == enumIncomeExpense.EXPENSE) {
    parGuidList = multiSelectExpenseGuidList;
  } else if (parTypeofdata == enumIncomeExpense.INCOME) {
    parGuidList = multiSelectIncomeGuidList;
  }

  if (parGuidList.includes(parGuid)) {
    //if already included remove from list
    parGuidList.splice(parGuidList.indexOf(parGuid), 1);

    //remove also children
    removeChildren(parGuid, parGuidList);
  } else {
    //if not in list add it to list
    parGuidList.push(parGuid);
    removeChildren(parGuid, parGuidList);
  }

  reCalculateSumOfSelected(parTypeofdata);
}

function checkSelection(guid, incomeExpence) {
  let tmp;
  if (incomeExpence == enumIncomeExpense.INCOME) {
    tmp = multiSelectIncomeGuidList;
  } else if (incomeExpence == enumIncomeExpense.EXPENSE) {
    tmp = multiSelectExpenseGuidList;
  }

  if (tmp === undefined) {
    return false;
  }

  if (tmp.includes(guid)) {
    return true;
  } else {
    return false;
  }
}

function selectAllIncomesExpences(incomeExpence) {
  let tmp;
  var i = 0;
  if (incomeExpence == enumIncomeExpense.INCOME) {
    /* =============== INCOMES =============== */
    resetMultiSelectionIncome();
    tmp = getDataIncomeYear;
    isCheckedAllIncome.value = !isCheckedAllIncome.value;

    if (isCheckedAllIncome.value) {
      //select all guids
      for (i = 0; i < tmp.value.length; i++) {
        multiSelectIncomeGuidList.push(tmp.value[i].guid);
      }
    }
  } else if (incomeExpence == enumIncomeExpense.EXPENSE) {
    /* =============== EXPENCES =============== */
    resetMultiSelectionExpense();
    tmp = getDataExpenseYear;
    isCheckedAllExpence.value = !isCheckedAllExpence.value;

    if (isCheckedAllExpence.value) {
      //select all guids
      for (i = 0; i < tmp.value.length; i++) {
        multiSelectExpenseGuidList.push(tmp.value[i].guid);
      }
    }
  }
  reCalculateSumOfSelected(incomeExpence);
}

/* ================================== 13.08.2024 ==================*/

const getFilteredTrans = computed(function () {
  const transStore = useTransStore();
  return transStore.transactions;
});

function searchTrans() {
  const db = getDatabase();
  const transStore = useTransStore();

  transStore
    .getLastTrans({
      db,
      searchObj: searchObjectMaster,
    })
    .then(() => {
      showTransactionsFlag.value = true;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      console.log('finally searchTrans()');
    });
}

function fixSearchObj1(guidKonto, lvl, searchObject) {
  //here lvl is drillevel so is actually for 1 higher than real level
  lvl = lvl + 1;
  fixSearchObj(guidKonto, lvl, searchObject, categoryDescLong);
}

function searchMaster(guidKonto, lvl) {
  //here lvl is drillevel so is actually for 1 higher than real level
  console.log(`searchMaster: guidKonto: ${guidKonto} | lvl: ${lvl}`);

  let tempYear = appUtils.getObjectCopy(year);
  let searchObject = getInitialSearchObject(guidKonto, tempYear.selected);
  fixSearchObj1(guidKonto, lvl, searchObject);
  searchObjectMaster = {};
  searchObjectMaster = searchObject;
  searchTrans();
}

/* ================================== end 13.08.2024 ==================*/
</script>
