<template>
  <thead class="text-warning">
    <tr>
      <th>
        <!-- <input
          id="checkboxMultiAll"
          @click="setSelectedMultiAll($event)"
          class="form-check-input"
          type="checkbox"
        /> -->
      </th>

      <th @click="sortColumn('description')">
        <a v-if="categoryVisible" class="jr-link">Category</a>
      </th>
      <th @click="sortColumn('x01')" class="text-end">
        <a class="jr-link">{{ yearComp + '.01' }}</a>
      </th>
      <th @click="sortColumn('x02')" class="text-end">
        <a class="jr-link">{{ yearComp + '.02' }}</a>
      </th>
      <th @click="sortColumn('x03')" class="text-end">
        <a class="jr-link">{{ yearComp + '.03' }}</a>
      </th>
      <th @click="sortColumn('x04')" class="text-end">
        <a class="jr-link">{{ yearComp + '.04' }}</a>
      </th>
      <th @click="sortColumn('x05')" class="text-end">
        <a class="jr-link">{{ yearComp + '.05' }}</a>
      </th>
      <th @click="sortColumn('x06')" class="text-end">
        <a class="jr-link">{{ yearComp + '.06' }}</a>
      </th>
      <th @click="sortColumn('x07')" class="text-end">
        <a class="jr-link">{{ yearComp + '.07' }}</a>
      </th>
      <th @click="sortColumn('x08')" class="text-end">
        <a class="jr-link">{{ yearComp + '.08' }}</a>
      </th>
      <th @click="sortColumn('x09')" class="text-end">
        <a class="jr-link">{{ yearComp + '.09' }}</a>
      </th>
      <th @click="sortColumn('x10')" class="text-end">
        <a class="jr-link">{{ yearComp + '.10' }}</a>
      </th>
      <th @click="sortColumn('x11')" class="text-end">
        <a class="jr-link">{{ yearComp + '.11' }}</a>
      </th>
      <th @click="sortColumn('x12')" class="text-end">
        <a class="jr-link">{{ yearComp + '.12' }}</a>
      </th>
      <th @click="sortColumn('x13')" class="text-end">
        <a class="jr-link"> {{ yearComp }}</a>
      </th>
    </tr>
  </thead>
</template>

<script setup>
//import { useStore } from 'vuex';
//import { computed, defineEmits, defineProps, onUpdated, ref } from 'vue';
import { defineProps, defineEmits } from 'vue';
//import { computed, onUpdated, ref } from 'vue';

//const store = useStore();
//let selectedAcc = ref('');

//const emit = defineEmits(['onchange']);
//defineProps(['selectedAccComp']);

// const props = defineProps({
//   yearComp: String,
// });

const props = defineProps({
  yearComp: String,
  excepencesIncomes: String,
  categoryVisible: { type: Boolean, required: false, default: true },
});

const emits = defineEmits(['sortColumn']);

function sortColumn(column) {
  emits('sortColumn', [column, props.excepencesIncomes]);
}
</script>
