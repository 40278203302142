import { getDatabase, ref as reffb, update } from 'firebase/database';

import appUtils from '../../components/utils/appUtils.js';
import { getAuth } from 'firebase/auth';
//import store from '../../store/index.js';
import { usePeriodicStore } from '../../stores/PeriodicStore';
import utilsTransactions from './utilsTransactions.js';
import { useTransStore } from '../../stores/TransStore';

export function deleteTransaction(transGuid, searchObject = {}) {
  let promise = new Promise(function (resolve, reject) {
    try {
      let transForDelete = getTransByGuid(transGuid);
      const db = getDatabase();
      let userUid = getAuth().currentUser.uid;
      const updates = {};

      let promise1 = null;
      let promise2 = null;
      let promise3 = null;
      let promise4 = null;
      let promise5 = null;
      let kontoSelectedObj = null;

      if (transForDelete.guidknt1) {
        kontoSelectedObj = { guidKonto: transForDelete.guidknt1 };

        promise1 = deleteTrans_updateKontoBalance(
          db,
          updates,
          userUid,
          kontoSelectedObj,
          transForDelete
        );
      }

      if (transForDelete.guidknt2) {
        kontoSelectedObj = { guidKonto: transForDelete.guidknt2 };

        promise2 = deleteTrans_updateKontoBalance(
          db,
          updates,
          userUid,
          kontoSelectedObj,
          transForDelete
        );
      }

      if (transForDelete.guidknt3) {
        kontoSelectedObj = { guidKonto: transForDelete.guidknt3 };

        promise3 = deleteTrans_updateKontoBalance(
          db,
          updates,
          userUid,
          kontoSelectedObj,
          transForDelete
        );
      }

      if (transForDelete.guidknt4) {
        kontoSelectedObj = { guidKonto: transForDelete.guidknt4 };

        promise4 = deleteTrans_updateKontoBalance(
          db,
          updates,
          userUid,
          kontoSelectedObj,
          transForDelete
        );
      }

      if (transForDelete.guidaccount) {
        const guidAccoutObj = appUtils.getGuidAccountObj(
          transForDelete.guidaccount
        );
        promise5 = utilsTransactions.addBalanceAccount(
          db,
          updates,
          guidAccoutObj.description,
          -1 * transForDelete.amount,
          `balance_acc/${userUid}/${transForDelete.guidaccount}`
        );
      }

      Promise.all([promise1, promise2, promise3, promise4, promise5]).then(
        (values) => {
          console.log('values');
          console.log(values);

          updates[
            '/transactions/' +
              userUid +
              '/' +
              transForDelete.year +
              '/' +
              transGuid
          ] = null;

          update(reffb(db), updates)
            .then(() => {
              const transStore = useTransStore();
              transStore
                .getLastTrans({
                  db: db,
                  searchObj: searchObject,
                })
                // store
                //   .dispatch('transactions/getLastTrans', {
                //     db: db,
                //     searchObj: searchObject,
                //   })
                .then(() => {
                  resolve();
                  console.log('---- HERE 1 deleteId() ----');
                })
                .catch((error) => {
                  reject(new Error(error.message));
                });
            })
            .catch((error) => {
              reject(new Error(error.message));
            });
        }
      );
    } catch (error) {
      reject(new Error(error.message));
    }
  });

  return promise;
}

function deleteTrans_updateKontoBalance(
  db,
  updates,
  userUid,
  kontoSelectedObj,
  transForDelete
) {
  let promise = new Promise(function (resolve, reject) {
    try {
      let url = `balance_konto/${userUid}/${transForDelete.termin}/${kontoSelectedObj.guidKonto}`;
      //console.log('url1: ', url);
      utilsTransactions
        .addBalanceKontoUpdate(
          db,
          updates,
          kontoSelectedObj,
          -1 * transForDelete.amount,
          url
        )
        .then(() => {
          resolve({ url, balance_change: -1 * transForDelete.amount });
        });
    } catch (error) {
      reject(new Error(error.message));
    }
  });

  return promise;
}

function getTransByGuid(transGuid) {
  if (!transGuid) return null;

  const transStore = useTransStore();
  const trans = transStore.transactions;

  let transaction = null;
  for (var i = 0; i < trans.length; i++) {
    if (trans[i].guid === transGuid) {
      transaction = trans[i];
      break;
    }
  }
  return transaction;
}

export function deletePeriodic(transGuid) {
  const db = getDatabase();
  let userUid = getAuth().currentUser.uid;
  const updates = {};
  updates['/periodic/' + userUid + '/' + transGuid] = null;

  update(reffb(db), updates).then(() => {
    const periodicStore = usePeriodicStore();
    periodicStore
      .loadPeriodic({
        db: db,
      })
      // store
      //   .dispatch('periodic/loadPeriodic', {
      //     db: db,
      //   })
      .then(() => {
        console.log('---- delete Periodic finished ----');
      });
  });
}
