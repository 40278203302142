import { child, get, getDatabase, ref, set, push } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useUserStore } from '../../stores/UserStore';

export function loadFromFirebasePinia(
  payload,
  path,
  executeAction = null,
  guid = null
) {
  // console.log(
  //   `loadFromFirebasePinia => path: ${path}, commitCmd: ${commitCmd}`
  // );

  //console.log(`loadFromFirebasePinia => payload: ${payload}`);

  let promise = new Promise((resolve, reject) => {
    try {
      let db = payload.db;
      if (!db) db = getDatabase();

      const userUid = useUserStore().userId;
      // console.log('loadFromFirebasePinia => userUid');
      // console.log(userUid);
      let url;
      if (guid) url = `${path}/${userUid}/${guid}`;
      else url = `${path}/${userUid}`;

      get(child(ref(db), url))
        .then((snapshot) => {
          if (snapshot.exists()) {
            //context.commit(commitCmd, snapshot.val());

            //commitCmd = snapshot.val();
            if (executeAction) executeAction(snapshot.val());
            //userStore.actionMsg();

            resolve(snapshot);
          } else {
            console.log('No data available.');
            resolve(null);
          }
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    } catch (error) {
      reject(error.message);
      throw error;
    }
  });

  return promise;
}

// export function loadFromFirebase(context, payload, path, commitCmd) {
//   console.log(`loadFromFirebase => path: ${path}, commitCmd: ${commitCmd}`);

//   let promise = new Promise((resolve, reject) => {
//     try {
//       const db = payload.db;
//       const userUid = context.rootGetters.getCurrentUser;

//       get(child(ref(db), `${path}/${userUid}`))
//         .then((snapshot) => {
//           if (snapshot.exists()) {
//             context.commit(commitCmd, snapshot.val());
//             resolve(snapshot);
//           } else {
//             console.log('No settings available.');
//             resolve(null);
//           }
//         })
//         .catch((error) => {
//           console.error(error);
//           throw error;
//         });
//     } catch (error) {
//       reject(error.message);
//       throw error;
//     }
//   });

//   return promise;
// }

export function saveToFirebaseWithOptions(
  optionsObj,
  objToSave,
  dispatchRef = null,
  runFunc = null
) {
  let promise = new Promise((resolve, reject) => {
    try {
      let db = getDatabase();
      let url = getUrl(optionsObj.url);
      //console.log(`url:${url}`);

      // if (url.includes('{uid}'))
      //   url = url.replace('{uid}', getAuth().currentUser.uid);

      // if (url.includes('{newKey}')) {
      //   let urlTmp = url.replace('{newKey}', '');
      //   let newKey = push(child(ref(db), `${urlTmp}`)).key;
      //   console.log(newKey);
      //   url = url.replace('{newKey}', newKey);
      // }

      set(ref(db, url), objToSave)
        .then(() => {
          if (dispatchRef) {
            dispatchRef({ db }).then(() => {
              resolve();
              if (runFunc) runFunc();
            });
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(error.message);
          throw error;
        });
    } catch (error) {
      reject(error.message);
      throw error;
    }
  });
  return promise;
}

function getUrl(url) {
  if (url.includes('{uid}'))
    url = url.replace('{uid}', getAuth().currentUser.uid);

  if (url.includes('{newKey}')) {
    let db = getDatabase();

    let urlTmp = url.replace('{newKey}', '');
    let newKey = push(child(ref(db), `${urlTmp}`)).key;
    url = url.replace('{newKey}', newKey);
  }
  return url;
}

export function saveToFirebase(url, obj, dispatchRef = null, runFunc = null) {
  let db = getDatabase();

  set(ref(db, url), obj)
    .then(() => {
      if (dispatchRef) {
        dispatchRef({ db }).then(() => {
          if (runFunc) runFunc();
        });

        // store.dispatch(dispatchRef, { db }).then(() => {
        //   if (runFunc) runFunc();
        // });
      }
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteFromFirebase(
  optionsObj,
  dispatchRef = null,
  runFunc = null
) {
  return saveToFirebaseWithOptions(optionsObj, null, dispatchRef, runFunc);
}
