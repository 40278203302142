<template>
  <div>
    <!-- <pre class="text-light">
      <div>
        {{ selectedKontos }}
      </div>
      <div>
        {{categoryDescLong}}
      </div> 

      <div>{{ filterArray1 }}</div>
    </pre> -->

    <!-- Select categories [accordion] -->
    <div class="accordion accordion-flush mt-2" id="accordion1">
      <div class="accordion-item bg-dark">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <h4 class="text-warning mt-1">Select categories</h4>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordion1"
        >
          <div class="accordion-body">
            <base-card>
              <div class="row justify-content-center">
                <div class="col-sm-5 text-light">
                  Select categories you would like to include in analysis.
                </div>
              </div>

              <div class="row justify-content-center mb-3">
                <div class="col-sm-5 text-light">
                  If you don't select anything all categories are included!
                </div>
              </div>

              <div class="row justify-content-center mb-3">
                <div class="col-sm-4">
                  <category-select-checkbox
                    v-for="item in getAllKontosActiveLvl1"
                    :key="item.guid"
                    :index="item.guid"
                    :item="item"
                    @set-selected-multi="setSelectedMulti"
                  >
                  </category-select-checkbox>
                </div>
              </div>
            </base-card>
          </div>
        </div>
      </div>
    </div>

    <!-- Select period [accordion] -->
    <transition name="fade">
      <div class="accordion accordion-flush mt-2" id="accordion2">
        <div class="accordion-item bg-dark">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed bg-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <h4 class="text-warning mt-1">Select period</h4>
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordion2"
          >
            <div class="accordion-body">
              <base-card>
                <div class="row justify-content-center">
                  <div class="col-sm-4">
                    <form @submit.prevent="">
                      <label for="searchTermin" class="fw-normal mb-2"
                        >Analysis for YYYY-MM (Month) | YYYY (Year) | YYYY-MM
                        comma separated | YYYY-YYYY (year1 till year2)</label
                      >
                      <input
                        class="form-control mb-1"
                        style="margin-bottom: 4px"
                        type="text"
                        id="searchTermin"
                        v-model.trim="searchTermin"
                      />
                      <!-- <base-button class="mt-2" @click="getAnalysisForMonth()">
              Month
            </base-button>
            <base-button @click="getAnalysisForYear()"> Yearly </base-button> -->
                      <base-button @click="getAnalysisForMultipleYear()">
                        Get Analysis
                      </base-button>
                    </form>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Analysis result -->
    <transition name="fade">
      <base-card v-if="getAnalysisArray1.length > 0">
        <h5>Categories (click to drill in)</h5>
        <!-- {{ selectedGuid1 }} <br />
      {{ getAnalysisArray2.length }} -->
        <ul>
          <analysis-item
            v-for="item in getAnalysisArray1"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :description="item.description"
            :drillvl="2"
            :balance="item.balance"
            :lvl="item.lvl"
            :type="item.type"
            @select-guid="filterlvl"
          >
            <!-- v-if="selectedGuid1 === item.guid && getAnalysisArray2.length > 0" -->
            <template #details2>
              <div v-if="showLevel2(item.guid)">
                <!-- <p>Here's some contact info</p> -->
                <!-- <p>{{ getAnalysisArray2.length }}</p> -->
                <analysis-item
                  v-for="item2 in getAnalysisArray2.filter(
                    (el) => el.guid_parent === item.guid
                  )"
                  :key="item2.guid"
                  :index="item2.guid"
                  :guid="item2.guid"
                  :description="item2.description"
                  :drillvl="3"
                  :balance="item2.balance"
                  :lvl="item2.lvl"
                  :type="item.type"
                  @select-guid="filterlvl"
                >
                  <template #details3>
                    <div v-if="showLevel3(item2.guid)">
                      <analysis-item
                        v-for="item3 in getAnalysisArray3.filter(
                          (el) => el.guid_parent === item2.guid
                        )"
                        :key="item3.guid"
                        :index="item3.guid"
                        :guid="item3.guid"
                        :description="item3.description"
                        :drillvl="4"
                        :balance="item3.balance"
                        :lvl="item3.lvl"
                        :type="item.type"
                        @select-guid="filterlvl"
                      >
                        <template #details4>
                          <div v-if="showLevel4(item3.guid)">
                            <analysis-item
                              v-for="item4 in getAnalysisArray4.filter(
                                (el) => el.guid_parent === item3.guid
                              )"
                              :key="item4.guid"
                              :index="item4.guid"
                              :guid="item4.guid"
                              :description="item4.description"
                              :drillvl="5"
                              :balance="item4.balance"
                              :lvl="item4.lvl"
                              :type="item.type"
                              @select-guid="filterlvl"
                            >
                            </analysis-item>
                          </div>
                        </template>
                      </analysis-item>
                    </div>
                  </template>
                </analysis-item>
              </div>
            </template>
          </analysis-item>
          <li>
            <div class="row pe-2">
              <div class="col-md-8"><b>Incomes:</b></div>
              <div class="col-md-4 text-end">
                <b>
                  &nbsp;&nbsp;&nbsp;
                  {{ appUtils.formatCurrency(sumIncomes) }}</b
                >
              </div>
            </div>
          </li>
          <li>
            <div class="row pe-2">
              <div class="col-md-8"><b>Expences:</b></div>
              <div class="col-md-4 text-end">
                <b>
                  &nbsp;&nbsp;&nbsp;
                  {{ appUtils.formatCurrency(sumExpenses) }}</b
                >
              </div>
            </div>
          </li>

          <li>
            <div class="row pe-2">
              <div class="col-md-8"><b>Incomes+Expences:</b></div>
              <div class="col-md-4 text-end">
                <b>
                  &nbsp;&nbsp;&nbsp;
                  {{ appUtils.formatCurrency(sumAll) }}</b
                >
              </div>
            </div>
          </li>
        </ul>
      </base-card>
    </transition>

    <!-- #region Transactions details -->
    <transition name="fade">
      <transactions-for-analysis
        :getFilteredTrans="getFilteredTrans"
        :showTransactionsFlag="showTransactionsFlag"
      ></transactions-for-analysis>
    </transition>
    <!-- #endregion -->
  </div>
</template>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
</style>

<script setup>
import { ref, computed, reactive } from 'vue';
import appUtils from '../../components/utils/appUtils.js';
import AnalysisItem from '../../components/analysis/AnalysisItem.vue';
import { useCategoryStore } from '../../stores/CategoryStore';
import CategorySelectCheckbox from '../../components/categories/CategorySelectCheckbox.vue';
import TransactionsForAnalysis from '../../components/transactions/TransactionsForAnalysis.vue';

import { useTransStore } from '../../stores/TransStore';
import { getDatabase } from 'firebase/database';
import {
  searchTransDecade,
  getInitialSearchObject,
  fixSearchObj,
} from '../../pages/transactions/utilsTransactions.js';

let yearlyArr = [];
let searchObjectMaster = {};

const searchTermin = ref(appUtils.getCurrentYear());
//const searchTermin = ref('2024-05');
const sumAll = ref(0);
const sumExpenses = ref(0);
const sumIncomes = ref(0);

let showTransactionsFlag = ref(false);
let categoryDescLong = ref('');
let searchYear1 = ref(0);
let searchYear2 = ref(0);

let analysisArrayAll = reactive([]);
let filterArray1 = reactive([]);
let filterArray2 = reactive([]);
let filterArray3 = reactive([]);
let filterArray4 = reactive([]);
let selectetGuidArr2 = reactive([]);
let selectetGuidArr3 = reactive([]);
let selectetGuidArr4 = reactive([]);
let selectedKontos = reactive([]);

function showLevel2(guid) {
  if (selectetGuidArr2.includes(guid) && getAnalysisArray2.value.length > 0)
    return true;
  else return false;
}

function showLevel3(guid) {
  if (selectetGuidArr3.includes(guid) && getAnalysisArray3.value.length > 0)
    return true;
  else return false;
}

function showLevel4(guid) {
  if (selectetGuidArr4.includes(guid) && getAnalysisArray4.value.length > 0)
    return true;
  else return false;
}

function parseSearchTerminGetArray(searchTerminValue) {
  let yearMonthArr = [];
  searchYear1.value = 0;
  searchYear2.value = 0;

  const txboxvalueArr = searchTerminValue.split(',');

  for (let i = 0; i < txboxvalueArr.length; i++) {
    txboxvalueArr[i] = txboxvalueArr[i].trim();

    if (txboxvalueArr[i].length == 7) {
      //we have a specific months

      // if year month already exist in array we don't add it
      if (yearMonthArr.indexOf(txboxvalueArr[i]) === -1)
        yearMonthArr.push(txboxvalueArr[i]);

      //yearMonthArr.push(txboxvalueArr[i]);
    } else if (txboxvalueArr[i].length == 4) {
      //we have a specific year
      yearMonthArr = appUtils.getAllMonthsOfYear(
        txboxvalueArr[i],
        yearMonthArr
      );
    } else if (txboxvalueArr[i].length == 9) {
      //we have a year1 to year2 (2023-2024)
      let tmp = txboxvalueArr[i].split('-');
      searchYear1.value = parseInt(tmp[0]);
      searchYear2.value = parseInt(tmp[1]);

      console.log(`searchYear1: ${searchYear1.value}`);
      console.log(`searchYear2: ${searchYear2.value}`);

      let tmpArrYears = appUtils.getYearsBetween(
        searchYear1.value,
        searchYear2.value
      );
      for (let i = 0; i < tmpArrYears.length; i++) {
        yearMonthArr = appUtils.getAllMonthsOfYear(
          tmpArrYears[i],
          yearMonthArr
        );
      }
    }
  }
  console.log('yearMonthArr', yearMonthArr);
  return yearMonthArr;
}

function getAnalysisForMultipleYear() {
  getAnalysisForMultipleYear1(parseSearchTerminGetArray(searchTermin.value));
}

function getAnalysisForMultipleYear1(arrayOfYearMonth) {
  showTransactionsFlag.value = false;
  yearlyArr = [];
  let promises = [];
  sumAll.value = 0;
  sumExpenses.value = 0;
  sumIncomes.value = 0;

  emptyAnalysisArray();
  for (let i = 0; i < arrayOfYearMonth.length; i++) {
    promises.push(appUtils.getAnalysisYearMonth(arrayOfYearMonth[i], true));
  }

  Promise.all(promises).then((values) => {
    console.log('getAnalysisForMultipleYear => values');
    console.log(values);
    //we have result for all months

    yearlyArr = [];
    analysisArrayAll.length = 0;
    let tmpArr = [];

    let allKontosArr = useCategoryStore().allKontos;

    for (let i = 0; i < values.length; i++) {
      if (values[i].snapshot) {
        tmpArr = prepareAnalysisLocal(values[i].snapshot);
        for (const key in allKontosArr) {
          getYearlySumAdd(tmpArr, key, yearlyArr);
        }
      }
    }
    doAnalysisForMultipleYearPostProcessing();
  });
}

function doAnalysisForMultipleYearPostProcessing() {
  yearlyArr = yearlyArr.sort(function (a, b) {
    return a.type - b.type || b.description - a.description;
  });

  Object.assign(analysisArrayAll, yearlyArr);
  Object.assign(
    filterArray1,
    yearlyArr.filter(
      (el) =>
        el.lvl === 1 &&
        (selectedKontos.length > 0 ? selectedKontos.includes(el.guid) : true)
    )
  );

  setAnalysisArray(2);
  setAnalysisArray(3);
  setAnalysisArray(4);
  countChildElements(1, yearlyArr);
  countChildElements(2, yearlyArr);
  countChildElements(3, yearlyArr);
  Object.assign(analysisArrayAll, yearlyArr);
  setAnalysisArray(2);
  setAnalysisArray(3);
  setAnalysisArray(4);
  sortArray(1);
}

function emptyArray(lvl) {
  if (lvl === 1) {
    filterArray1.length = 0;
  } else if (lvl === 2) {
    filterArray2.length = 0;
  } else if (lvl === 3) {
    filterArray3.length = 0;
  } else if (lvl === 4) {
    filterArray4.length = 0;
  }
}

function pushItem(lvl, item1) {
  if (lvl === 2) {
    filterArray2.push(item1);
  } else if (lvl === 3) {
    filterArray3.push(item1);
  } else if (lvl === 4) {
    filterArray4.push(item1);
  }
}

function sortArray(lvl) {
  if (lvl === 1) {
    filterArray1 = filterArray1.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type - b.type;
      }
      return a.description.localeCompare(b.description);
    });
  } else if (lvl === 2) {
    filterArray2 = filterArray2.sort(function (a, b) {
      return (
        a.balance - b.balance ||
        a.type - b.type ||
        b.description - a.description
      );
    });
  } else if (lvl === 3) {
    filterArray3 = filterArray3.sort(function (a, b) {
      return a.type - b.type || b.description - a.description;
    });
  } else if (lvl === 4) {
    filterArray4 = filterArray4.sort(function (a, b) {
      return a.type - b.type || b.description - a.description;
    });
  }
}

function emptyAnalysisArray() {
  emptyArray(1);
  emptyArray(2);
  emptyArray(3);
  emptyArray(4);
  selectetGuidArr2.length = 0;
  selectetGuidArr3.length = 0;
  selectetGuidArr4.length = 0;
}

function getYearlySumAdd(analysisArrayCopy, guid, yearlyArr) {
  let found = analysisArrayCopy.filter((el) => el.guid === guid)[0];

  if (found) {
    let exist = yearlyArr.filter((el) => el.guid === guid)[0];
    if (exist) {
      exist.balance = appUtils.getSumofTwoNumbers(exist.balance, found.balance);
    } else {
      yearlyArr.push(found);
    }
  }
}

function countChildElements(lvl, yearlyArr) {
  for (let i = 0; i < yearlyArr.length; i++) {
    //counting child elements
    if (yearlyArr[i].lvl === lvl) {
      const searchGuid = yearlyArr[i].guid;

      let count = 0;
      if (lvl === 1) {
        count = getAnalysisArray2.value.filter(
          (el) => el.guid_parent === searchGuid
        ).length;
      } else if (lvl === 2) {
        count = getAnalysisArray3.value.filter(
          (el) => el.guid_parent === searchGuid
        ).length;
      } else if (lvl === 3) {
        count = getAnalysisArray4.value.filter(
          (el) => el.guid_parent === searchGuid
        ).length;
      }

      if (count != 0)
        yearlyArr[i].description =
          yearlyArr[i].description + ' [' + String(count) + ']';
    }
  }
}

function addRemoveElement(parArray, guid) {
  if (parArray.includes(guid)) {
    parArray.splice(parArray.indexOf(guid), 1);
  } else {
    parArray.push(guid);
  }
}

function filterlvl(params) {
  const guid = params[0];
  const lvl = params[1];

  if (lvl === 2) {
    addRemoveElement(selectetGuidArr2, guid);
  } else if (lvl === 3) {
    addRemoveElement(selectetGuidArr3, guid);
  } else if (lvl === 4) {
    addRemoveElement(selectetGuidArr4, guid);
  }

  searchMaster(guid, lvl);
}

function setAnalysisArray(lvl) {
  emptyArray(lvl);

  for (let i = 0; i < analysisArrayAll.length; i++) {
    if (analysisArrayAll[i].lvl === lvl) {
      const categoryObjParent = appUtils.getGuidKontoObj(
        analysisArrayAll[i].guid_parent
      );
      const item1 = JSON.parse(JSON.stringify(analysisArrayAll[i]));

      item1.description =
        categoryObjParent.description + ' | ' + analysisArrayAll[i].description;

      pushItem(lvl, item1);
    }
  }
  sortArray(lvl);
}

function prepareAnalysisLocal(responseData) {
  let returnObj = appUtils.prepareAnalysis(
    responseData,
    sumAll.value,
    sumIncomes.value,
    sumExpenses.value,
    selectedKontos
  );
  sumAll.value = appUtils.fixCurrency(returnObj.sum);
  sumIncomes.value = appUtils.fixCurrency(returnObj.sumIncomesReturn);
  sumExpenses.value = appUtils.fixCurrency(returnObj.sumExpensesReturn);

  return returnObj.arr;
}

function setSelectedMulti(eventArgs) {
  let guidKonto = eventArgs.guidKonto;
  if (!guidKonto) return;

  const index = selectedKontos.indexOf(guidKonto);
  if (index === -1) selectedKontos.push(guidKonto);
  else selectedKontos.splice(index, 1);
}

const getAnalysisArray1 = computed(function () {
  return filterArray1;
});

const getAnalysisArray2 = computed(function () {
  return filterArray2;
});

const getAnalysisArray3 = computed(function () {
  return filterArray3;
});

const getAnalysisArray4 = computed(function () {
  return filterArray4;
});

const getAllKontosActiveLvl1 = computed(function () {
  let ret = appUtils.getCategory(1).filter((el) => el.active === true);

  ret = ret.sort((a, b) => {
    if (a.type !== b.type) {
      return a.type - b.type;
    }
    return a.description.localeCompare(b.description);
  });

  return ret;
});

const getFilteredTrans = computed(function () {
  const transStore = useTransStore();
  return transStore.transactions;
});

function searchTrans() {
  const db = getDatabase();
  const transStore = useTransStore();

  transStore
    .getLastTrans({
      db,
      searchObj: searchObjectMaster,
    })
    .then(() => {
      showTransactionsFlag.value = true;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      console.log('finally searchTrans()');
    });
}

function fixSearchObj1(guidKonto, lvl, searchObject) {
  //here lvl is drillevel so is actually for 1 higher than real level
  fixSearchObj(guidKonto, lvl, searchObject, categoryDescLong);
}

function searchMaster(guidKonto, lvl) {
  //here lvl is drillevel so is actually for 1 higher than real level
  console.log(`searchMaster: guidKonto: ${guidKonto} | lvl: ${lvl}`);
  let searchObject = getInitialSearchObject(guidKonto, searchTermin.value);
  fixSearchObj1(guidKonto, lvl, searchObject);

  if (searchYear1.value != 0 && searchYear2.value != 0) {
    //searching multiple years
    searchObject.searchTermin = null;
    searchObject.searchYear1 = searchYear1.value;
    searchObject.searchYear2 = searchYear2.value;
    searchTransDecadeTool(searchObject);
  } else {
    searchObjectMaster = searchObject; //params.searchObject;
    searchTrans();
  }
}

function searchTransDecadeTool(searchObject) {
  let yearArr = [];

  if (searchYear1.value && searchYear2.value) {
    for (
      let i = Number(searchYear1.value);
      i <= Number(searchYear2.value);
      i++
    ) {
      yearArr.push(i);
    }
  }
  searchTransDecade(searchObject, yearArr);
  showTransactionsFlag.value = true;
}
</script>
