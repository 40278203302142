<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">
            Search transactions
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body bg-dark text-light">
          <form @submit.prevent="">
            <!-- saved filters to firebase -->
            <div class="row">
              <div class="col-sm-3 text-end mt-1">
                <label class="col-form-label">Saved filters </label>
              </div>

              <div class="col-sm-9 mt-1">
                <div class="input-group mb-2">
                  <select
                    class="form-select form-select-sm mt-1"
                    @change="onChangeSavedFilters($event, level)"
                    id="comboSavedFilters"
                    name="comboSavedFilters"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getSavedFilters"
                      :key="item.filterName"
                      :value="item.filterName"
                    >
                      {{ item.filterName }}
                    </option>
                  </select>

                  <button
                    @click="searchMaster()"
                    data-bs-dismiss="modal"
                    data-bs-target="#exampleModal"
                    class="btn btn-warning btn-sm mt-1"
                    type="button"
                    id="button-addon2"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-solid fa-magnifying-glass"
                    />
                  </button>
                </div>
                <!-- ================ -->

                <!-- <select
                  class="form-select form-select-sm"
                  @change="onChangeSavedFilters($event, level)"
                  id="comboSavedFilters"
                  name="comboSavedFilters"
                >
                  <option :key="0" :value="0"></option>
                  <option
                    v-for="item in getSavedFilters"
                    :key="item.filterName"
                    :value="item.filterName"
                  >
                    {{ item.filterName }}
                  </option>
                </select> -->
              </div>
            </div>

            <!-- save filter -->
            <div class="row">
              <div class="col-sm-3 text-end">
                <label for="saveFilter" class="col-form-label"
                  >Save filter as
                </label>
              </div>

              <div class="col-sm-9">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm mt-1"
                    id="saveFilter"
                    v-model="saveFilterText"
                  />

                  <button
                    type="button"
                    class="btn btn-warning btn-sm mt-1"
                    @click="saveFilter()"
                  >
                    Save
                  </button>

                  <button
                    @click="deleteSavedFilter()"
                    class="btn btn-danger btn-sm mt-1"
                    type="button"
                    id="button-addon2"
                  >
                    <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
                  </button>
                </div>
              </div>
            </div>

            <!-- account links -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end mt-1"
                >Account links</label
              >
              <div class="col-sm-9 mt-2">
                <account-link
                  :settingName="'cashAcc1'"
                  @clickHandler="setCashAcc"
                >
                </account-link>

                <account-link
                  :settingName="'cashAcc2'"
                  @clickHandler="setCashAcc"
                >
                </account-link>

                <account-link
                  :settingName="'cashAcc3'"
                  @clickHandler="setCashAcc"
                >
                </account-link>

                <account-link
                  :settingName="'cashAcc4'"
                  @clickHandler="setCashAcc"
                >
                </account-link>
              </div>
            </div>

            <!-- fast links for current and previous months -->
            <div class="row mb-2">
              <label class="col-sm-3 col-form-label text-end mt-0">Month</label>

              <div class="col-sm-9 mt-1">
                <a
                  id="searchTerminLink1"
                  data-bs-dismiss="modal"
                  data-bs-target="#exampleModal"
                  class="jr-link"
                  :href="null"
                  @click="searchTrans2(appUtils.getCurrentYearMonth())"
                  >{{ appUtils.getCurrentYearMonth() }}</a
                >&nbsp;&nbsp;&nbsp;
                <a
                  id="searchTerminLink2"
                  data-bs-dismiss="modal"
                  data-bs-target="#exampleModal"
                  class="jr-link"
                  :href="null"
                  @click="searchTrans2(appUtils.getCurrentYearMonth(-1))"
                  >{{ appUtils.getCurrentYearMonth(-1) }}</a
                >

                &nbsp;&nbsp;&nbsp;
                <a
                  id="searchTerminLink3"
                  data-bs-dismiss="modal"
                  data-bs-target="#exampleModal"
                  class="jr-link"
                  :href="null"
                  @click="searchTrans2(appUtils.getCurrentYearMonth(-2))"
                  >{{ appUtils.getCurrentYearMonth(-2) }}</a
                >

                &nbsp;&nbsp;&nbsp;
                <a
                  id="searchTerminLink4"
                  data-bs-dismiss="modal"
                  data-bs-target="#exampleModal"
                  class="jr-link"
                  :href="null"
                  @click="searchTrans2(appUtils.getCurrentYearMonth(-3))"
                  >{{ appUtils.getCurrentYearMonth(-3) }}</a
                >
              </div>
            </div>

            <!-- searchYears from-to -->
            <div class="row">
              <label for="searchYear1" class="col-sm-3 col-form-label text-end"
                >Year from
              </label>

              <div class="col-sm-3">
                <!-- :value="modelSearchYear1" -->
                <input
                  class="form-control form-control-sm mt-1"
                  type="text"
                  id="searchYear1"
                  v-model="searchYear1"
                  pattern="\d{4}"
                  maxlength="4"
                  @keyup="disableSearcTerminHandler()"
                />
              </div>
              <label for="searchYear2" class="col-sm-1 col-form-label"
                >to
              </label>

              <div class="col-sm-3">
                <!-- :value="modelSearchYear2" -->
                <input
                  class="form-control form-control-sm mt-1 text-start"
                  type="text"
                  id="searchYear2"
                  v-model="searchYear2"
                  pattern="\d{4}"
                  maxlength="4"
                  @keyup="disableSearcTerminHandler()"
                />
              </div>

              <div class="col-sm-1 mt-1">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteSearchFields"
                >
                  <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
                </button>
              </div>
            </div>

            <!-- searchTermin -->
            <div class="row">
              <label for="searchTermin" class="col-sm-3 col-form-label text-end"
                >YYYY-MM
              </label>

              <div class="col-sm-9">
                <input
                  class="form-control form-control-sm mt-1"
                  type="text"
                  id="searchTermin"
                  v-model="searchTermin"
                  :disabled="disableSearchTermin"
                />
              </div>
            </div>

            <!-- searchDesc -->
            <div class="row">
              <label for="searchDesc" class="col-sm-3 col-form-label text-end"
                >Description
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control form-control-sm mt-1"
                  type="text"
                  id="searchDesc"
                  v-model="searchDesc"
                />
              </div>
            </div>

            <!-- searchAcc -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end">Account </label>

              <div class="col-sm-9 mt-1">
                <account-dropdown
                  :modelValue="searchAcc"
                  @onchange="onChangeAccSearch"
                ></account-dropdown>
              </div>
            </div>

            <!-- search Category 1 -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end" for="konto1"
                >Category 1
              </label>

              <div class="col-sm-9 mt-1">
                <category-dropdown
                  :level="1"
                  :parentCategory="0"
                  :modelValue="searchKonto1"
                  @on-change="onchangejrComp($event, 1)"
                ></category-dropdown>
              </div>
            </div>

            <!-- search Category 2 -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end" for="konto2"
                >Category 2
              </label>
              <div class="col-sm-9 mt-1">
                <category-dropdown
                  :level="2"
                  :parentCategory="searchKonto1"
                  :modelValue="searchKonto2"
                  @on-change="onchangejrComp($event, 2)"
                ></category-dropdown>
              </div>
            </div>

            <!-- search Category 3 -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end" for="konto3"
                >Category 3
              </label>
              <div class="col-sm-9 mt-1">
                <category-dropdown
                  :level="3"
                  :parentCategory="searchKonto2"
                  :modelValue="searchKonto3"
                  @on-change="onchangejrComp($event, 3)"
                ></category-dropdown>
              </div>
            </div>

            <!-- search Category 4 -->
            <div class="row">
              <label class="col-sm-3 col-form-label text-end" for="konto4"
                >Category 4
              </label>
              <div class="col-sm-9 mt-1">
                <category-dropdown
                  :level="4"
                  :parentCategory="searchKonto3"
                  :modelValue="searchKonto4"
                  @on-change="onchangejrComp($event, 4)"
                ></category-dropdown>
              </div>
            </div>

            <!-- search buttons -->

            <div>
              <div class="float-end mt-3">
                <!-- <base-button @click="saveFilter()"> Save </base-button> -->

                <base-button data-bs-dismiss="modal"> Cancel </base-button>

                <base-button
                  data-bs-dismiss="modal"
                  data-bs-target="#exampleModal"
                  @click="searchMaster()"
                  >Search <font-awesome-icon icon="fa-magnifying-glass"
                /></base-button>
              </div>
            </div>
          </form>
        </div>
        <!-- <div class="modal-footer">footer</div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
//components
import AccountDropdown from '../accounts/accountDropdown.vue';
import AccountLink from '../accounts/AccountLink.vue';

import CategoryDropdown from '../categories/CategoryDropdown.vue';

//utils
import appUtils from '../utils/appUtils.js';
import { onChangeCategory } from '../../pages/transactions/utilsTransactions.js';
import { saveToFirebase } from '../../components/utils/firebaseUtils';

//store
import { useUserStore } from '../../stores/UserStore';
import { useFiltersStore } from '../../stores/FiltersStore';

//VUE core
import { ref, defineEmits, onMounted, computed } from 'vue';

//firebase
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const userStore = useUserStore();
const disableSearchTermin = ref(false);

const searchAcc = ref('0');
const searchKonto1 = ref(null);
const searchKonto2 = ref(null);
const searchKonto3 = ref(null);
const searchKonto4 = ref(null);
const searchTermin = ref(null);
const searchYear1 = ref(null);
const searchYear2 = ref(null);
const searchDesc = ref(null);
const saveFilterText = ref(null);

const emit = defineEmits(['onsearch']);

onMounted(() => {
  loadFilter();
});

const getSavedFilters = computed(function () {
  const filtersStore = useFiltersStore();
  return filtersStore.filters;
});

function setCashAcc(parCashAcc) {
  searchAcc.value = appUtils.getSettings('appSettings', parCashAcc);
}

function deleteSavedFilter() {
  if (saveFilterText.value != null && saveFilterText.value != '') {
    appUtils
      .deleteNode(`settings/{uid}/savedFilters/${saveFilterText.value}`)
      .then(() => {
        //console.log('delete succeed');
        reloadSavedFilters();
      });
  }
}

function onChangeSavedFilters(eventArgs) {
  const tmpSavedFilters = getSavedFilters;
  for (const key in tmpSavedFilters.value) {
    if (key === eventArgs.target.value) {
      deleteSearchFields();
      saveFilterText.value = key;
      fillFieldsWithFilter(tmpSavedFilters.value[key]);
      break;
    }
  }
}

function saveFilter() {
  if (saveFilterText.value != null && saveFilterText.value.trim() != '') {
    const userUid = getAuth().currentUser.uid;
    let newKey = saveFilterText.value.trim();

    const saveObj = {
      filterName: saveFilterText.value.trim(),
      searchDesc: searchDesc.value == '' ? null : searchDesc.value,
      searchTermin: searchTermin.value == '' ? null : searchTermin.value,
      searchKonto1: searchKonto1.value == 0 ? null : searchKonto1.value,
      searchKonto2: searchKonto2.value == 0 ? null : searchKonto2.value,
      searchKonto3: searchKonto3.value == 0 ? null : searchKonto3.value,
      searchKonto4: searchKonto4.value == 0 ? null : searchKonto4.value,
      searchAccount: searchAcc.value == '0' ? null : searchAcc.value,
      searchYear1: searchYear1.value == '' ? null : searchYear1.value,
      searchYear2: searchYear2.value == '' ? null : searchYear2.value,
    };

    saveToFirebase(
      `settings/${userUid}/savedFilters/${newKey}`,
      saveObj,
      useFiltersStore().loadFilters
    );
  }
}

function fillFieldsWithFilter(storedFilter) {
  if (storedFilter) {
    // console.log('=========== storedFilter ===========');
    // console.log(storedFilter);
    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchTermin'))
      searchTermin.value = storedFilter.searchTermin;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchAccount'))
      searchAcc.value = storedFilter.searchAccount;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchDesc'))
      searchDesc.value = storedFilter.searchDesc;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchKonto1'))
      searchKonto1.value = storedFilter.searchKonto1;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchKonto2'))
      searchKonto2.value = storedFilter.searchKonto2;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchKonto3'))
      searchKonto3.value = storedFilter.searchKonto3;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchKonto4'))
      searchKonto4.value = storedFilter.searchKonto4;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchYear1'))
      searchYear1.value = storedFilter.searchYear1;

    if (Object.getOwnPropertyDescriptor(storedFilter, 'searchYear2'))
      searchYear2.value = storedFilter.searchYear2;

    disableSearcTerminHandler();
  }
}

function reloadSavedFilters() {
  try {
    const db = getDatabase();
    const filtersStore = useFiltersStore();

    filtersStore
      .loadFilters({ db })
      .then(() => {
        saveFilterText.value = null;
      })
      .catch((error) => console.log(error))
      .finally(() => {
        //console.log('finally searchTrans Analysis()');
      });
  } catch (error) {
    console.log(error);
  }
}

function loadFilter() {
  let storedFilter = {};
  storedFilter = userStore.transFilter;
  fillFieldsWithFilter(storedFilter);
}

function searchMaster() {
  let searchObject = getSearchObject();
  emit('onsearch', { searchYear1, searchYear2, searchObject });
}

function onChangeAccSearch(value) {
  searchAcc.value = value;
}

function onchangejrComp(eventArgs, level) {
  onChangeCategory(
    eventArgs,
    level,
    searchKonto1,
    searchKonto2,
    searchKonto3,
    searchKonto4
  );
}

function disableSearcTerminHandler() {
  if (searchYear1.value || searchYear2.value) {
    disableSearchTermin.value = true;
  } else disableSearchTermin.value = false;
}

function deleteSearchFields() {
  disableSearchTermin.value = false;

  searchAcc.value = '0';
  searchTermin.value = null;
  searchYear1.value = null;
  searchYear2.value = null;
  searchKonto1.value = null;
  searchKonto2.value = null;
  searchKonto3.value = null;
  searchKonto4.value = null;
  searchDesc.value = null;
}

function getSearchObject() {
  const tmp = {
    searchDesc: searchDesc.value,
    searchTermin: searchTermin.value,
    searchKonto1: searchKonto1.value == 0 ? null : searchKonto1.value,
    searchKonto2: searchKonto2.value == 0 ? null : searchKonto2.value,
    searchKonto3: searchKonto3.value == 0 ? null : searchKonto3.value,
    searchKonto4: searchKonto4.value == 0 ? null : searchKonto4.value,
    searchAccount: searchAcc.value == '0' ? null : searchAcc.value,
    searchYear1: searchYear1.value,
    searchYear2: searchYear2.value,
  };
  storeFilter(tmp);
  return tmp;
}

function storeFilter(filterObj) {
  userStore.transFilter = filterObj;
  localStorage.setItem('filter', JSON.stringify(filterObj));
}

function searchTrans2(yearMonth) {
  searchTermin.value = yearMonth;
  searchMaster();
}
</script>
