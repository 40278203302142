import {
  get,
  getDatabase,
  equalTo,
  limitToLast,
  orderByChild,
  query,
  ref,
  startAt,
  endAt,
} from 'firebase/database';

import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { getFiltering } from '../pages/transactions/utilsTransactions.js';
import { useUserStore } from './UserStore';
//import { useRoute } from 'vue-router';

// import { getAuth } from 'firebase/auth';

//HOW TO USE:
//import { useTransStore } from '../../stores/TransStore';
//import router from './../router';

export const useTransStore = defineStore('TransStore', {
  state: () => {
    return {
      transactions: [],
    };
  },
  getters: {
    getFields() {
      return [
        'date',
        'termin',
        'year',
        'amount',
        'idaccount',
        'idkonto',
        'idknt1',
        'idknt2',
        'idknt3',
        'idknt4',
        'guidaccount',
        'guidkonto',
        'guidknt1',
        'guidknt2',
        'guidknt3',
        'guidknt4',
        'description',
        'remark1',
        'search',
      ];
    },
  },
  actions: {
    setSorting(payload) {
      this.transactions = appUtils.setSortingUtil(payload, this.transactions);
    },

    rearrangeTrans(response) {
      let responseData = response; //[];
      let searchObject = null;
      let addToExistingTrans = false;

      if (response instanceof Array) {
        responseData = response[0];
        searchObject = response[1];
        addToExistingTrans = response[2];
      }

      let props = this.getFields;
      let tmpArray = appUtils.getArrayWithGuid(responseData, props);

      for (let i = 0; i < tmpArray.length; i++) {
        if (tmpArray[i].guidaccount) {
          tmpArray[i]['accDesc'] = appUtils.getGuidAccountObj(
            tmpArray[i].guidaccount
          ).description;
        } else {
          tmpArray[i]['accDesc'] = null;
        }

        if (tmpArray[i].guidknt1) {
          tmpArray[i]['kontoDesc'] = appUtils.getKontoDescFull(
            tmpArray[i].guidknt1,
            tmpArray[i].guidknt2,
            tmpArray[i].guidknt3,
            tmpArray[i].guidknt4
          );
        } else {
          tmpArray[i]['kontoDesc'] = null;
        }
      }

      //filtering
      tmpArray = getFiltering(searchObject, tmpArray);
      if (addToExistingTrans)
        this.transactions = this.transactions.concat(tmpArray.reverse());
      else this.transactions = tmpArray.reverse();
    },

    setTransactionsEmpty() {
      this.transactions = [];
    },

    getLastTrans(payload) {
      // console.log('getLastTrans A => payload');
      // console.log('getLastTrans B => payload');

      let promise = new Promise((resolve, reject) => {
        //let promise = new Promise(function (resolve, reject) {
        try {
          //this.setTransactionsEmpty();
          this.transactions = [];
          let maxRecords = 500;
          let db = payload.db;
          if (!db) db = getDatabase();

          //let url = `transactions/${getAuth().currentUser.uid}`;
          const userStore = useUserStore();
          let url = `transactions/${userStore.userId}`;
          let que = null;
          let searchObject = null;

          if (payload.searchObj) {
            searchObject = payload.searchObj;

            que = appUtils.getSearchQue({
              searchObj: payload.searchObj,
              url,
              maxRecords,
              db,
            });

            get(que)
              .then((snapshot) => {
                resolve();
                this.rearrangeTrans([snapshot.val(), searchObject, false]);
              })
              .catch((error) => {
                //console.log('getLastTrans1 => error');
                console.log(error.toString());

                console.log(JSON.stringify(error));
                // console.log(typeof error);

                if (error.toString().includes('Permission denied')) {
                  console.log('JSON.stringify(error)');
                  this.$router.replace('auth');
                  //useRoute().replace('auth');
                }
              });
          }
        } catch (error) {
          reject(new Error(error.message));
        }
      });

      return promise;
    },

    getTransDecade(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('TransStore.js => getTransDecade => payload.searchObj');
          console.log(payload.searchObj);

          let maxRecords = 2500;
          let db = payload.db;
          if (!db) db = getDatabase();

          if (payload.year) {
            const userStore = useUserStore();
            let url = `transactions/${userStore.userId}/${payload.year}`;
            let que = null;

            if (payload.searchObj.searchKonto1) {
              que = query(
                ref(db, url),
                orderByChild('guidknt1'),
                limitToLast(maxRecords),
                equalTo(payload.searchObj.searchKonto1)
              );
            } else if (payload.searchObj.searchAccount) {
              que = query(
                ref(db, url),
                orderByChild('guidaccount'),
                limitToLast(maxRecords),
                equalTo(payload.searchObj.searchAccount)
              );
            } else {
              que = query(
                ref(db, url),
                orderByChild('termin'),
                limitToLast(maxRecords),
                startAt(payload.year + '-01'),
                endAt(payload.year + '-12')
              );
            }

            get(que)
              .then((snapshot) => {
                resolve(snapshot);
              })
              .catch((error) => {
                console.log(error.toString());
                console.log(JSON.stringify(error));

                if (error.toString().includes('Permission denied')) {
                  console.log('JSON.stringify(error)');
                  this.$router.replace('auth');
                }
              });
          }
        } catch (error) {
          reject(new Error(error.message));
        }
      });

      return promise;
    },
  },
});
