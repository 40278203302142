<template>
  <div>
    <!-- <div> -->
    <!-- <pre style="color: red">start</pre>
    <pre style="color: white">{{ selectedAccLocalComp }}</pre>
    <pre style="color: white">{{ selectedAccLocal }}</pre>
    <pre style="color: red">end</pre> -->

    <!-- <pre style="color: yellow">{{ selectedAccLocal }}</pre> -->

    <select
      @change="onchange($event)"
      class="form-select form-select-sm"
      id="account"
      name="account"
      :value="modelValue"
    >
      <option :key="0" :value="0"></option>
      <option
        v-for="item in getAccountsWithGuid"
        :key="item.guid"
        :value="item.guid"
        :disabled="item.description.substring(0, 5) == '─────'"
      >
        {{ item.description }}
      </option>
    </select>
  </div>
  <!-- </div> -->
</template>

<script setup>
import { useAccountStore } from '../../stores/AccountStore';

//import { useStore } from 'vuex';
import {
  computed,
  defineEmits,
  defineProps,
  // onUpdated,
  // onMounted,
  // ref,
} from 'vue';
import appUtils from '../utils/appUtils';
//import { computed, onUpdated, ref } from 'vue';

//const store = useStore();
//let selectedAccLocal = ref('');
//let selectedAccLocal = '';

const emit = defineEmits(['onchange']);

const props = defineProps(['modelValue', 'additionalParam']);

function onchange(event) {
  if (props.additionalParam) {
    emit('onchange', {
      value: event.target.value,
      additionalParam: props.additionalParam,
    });
  } else {
    emit('onchange', event.target.value);
  }
}

const getAccountsWithGuid = computed(function () {
  const accountStore = useAccountStore();
  //console.log(accountStore.accountsWithGuid);
  let tmp = accountStore.accountsWithGuid;
  tmp = tmp.filter((el) => el.active == true);

  tmp = appUtils.sortByKey(tmp, 'order');

  //tmp = tmp.sort((a,b) => a.last_nom - b.last_nom)

  return tmp;
});
</script>
