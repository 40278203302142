<template>
  <tr :class="getBgClass(lvl)">
    <td>
      <input
        @click="setSelectedMulti($event, guid, lvl, typeofdata)"
        :checked="isclicked"
        class="form-check-input"
        type="checkbox"
        value="guid"
        :id="getCheckboxId(guid)"
      />
    </td>

    <td style="vertical-align: middle">
      <a
        :class="getMarginClass(lvl)"
        @click.prevent="selectGuid($event, [guid, lvl])"
        href
        >{{ getDescripton(description, lvl) }}</a
      >
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x01 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x02 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x03 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x04 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x05 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x06 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x07 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x08 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x09 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x10 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x11 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x12 }}
    </td>
    <td style="vertical-align: middle; text-align: right">
      {{ x13 }}
    </td>
  </tr>

  <slot name="details2"></slot>
  <slot name="details3"></slot>
  <slot name="details4"></slot>
</template>

<script>
export default {
  // mounted() {
  //   if (this.lvl == 1) {
  //     // console.log(this.selectedGuids.value);
  //     console.log(`Guid ${this.guid} isclicked ${this.isclicked}`);

  //     if (this.selectedGuids.includes(this.guid)) {
  //       //if already selected we have to make checkbox clicked
  //       console.log(this.guid);
  //     }
  //   }
  // },

  // watch: {
  //   isclicked(newVal, oldVal) {
  //     console.log(`Messisclickedage changed from ${oldVal} to ${newVal}`);
  //   },
  // },

  emits: ['select-guid', 'select-multi-id'],
  props: [
    'guid',
    'lvl',
    'x01',
    'x02',
    'x03',
    'x04',
    'x05',
    'x06',
    'x07',
    'x08',
    'x09',
    'x10',
    'x11',
    'x12',
    'x13',
    'description',
    'typeofdata',
    'isclicked',
  ],

  methods: {
    getDescripton(description, lvl) {
      if (lvl > 1) return description;
      else return description;
    },

    getMarginClass(lvl) {
      let ret = '';
      if (lvl === 1) ret = 'jrmargin1';
      if (lvl === 2) ret = 'jrmargin2';
      if (lvl === 3) ret = 'jrmargin3';
      if (lvl === 4) ret = 'jrmargin4';
      return ret;
    },

    getBgClass(lvl) {
      let ret = '';
      if (lvl === 1) ret = 'bglevel1';
      if (lvl === 2) ret = 'bglevel2';
      if (lvl === 3) ret = 'bglevel3';
      if (lvl === 4) ret = 'bglevel4';
      return ret;
    },

    getCheckboxId(guid) {
      {
        return 'check' + guid;
      }
    },

    selectGuid(event, params) {
      this.$emit('select-guid', params);
    },

    setSelectedMulti(event, guid, lvl, typeofdata) {
      //console.log(value);
      this.$emit('select-multi-id', { guid, lvl, typeofdata });
    },
  },
};
</script>

<style scoped>
.jrmargin1 {
  margin-left: 1px !important;
  color: beige;
}

.jrmargin2 {
  margin-left: 16px !important;
  color: beige;
  background-color: rgb(82, 82, 82) !important;
}

.jrmargin3 {
  margin-left: 32px !important;
  color: beige;
}

.jrmargin4 {
  margin-left: 48px !important;
  color: beige;
}
/* 
.bglevel1 {
  background-color: rgb(252, 242, 230) !important;
}

.bglevel2 {
  background-color: #ff8800 !important;
}

.bglevel3 {
  background-color: #9a47c4 !important;
  color: whitesmoke !important;
}

.bglevel4 {
  background-color: rgb(10, 143, 43) !important;
} */
</style>
