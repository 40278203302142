<template>
  <the-header></the-header>
  <router-view style="padding: 8px" v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';

export default {
  components: {
    TheHeader,
  },
  computed: {
    // didAutoLogout() {
    //   return this.$store.getters.didAutoLogout;
    // },
  },
  created() {
    let theme = localStorage.getItem('theme');
    console.log(theme);

    if (theme) {
      let themeElement = document.getElementById('theme-link');
      if (themeElement) themeElement.setAttribute('href', theme);
    }

    //this.$store.dispatch('tryLogin');
  },
  watch: {
    // didAutoLogout(curValue, oldValue) {
    //   if (curValue && curValue !== oldValue) {
    //     this.$router.replace('/coaches');
    //   }
    // },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* @import './stylesbootswatch/Cyborg.css'; */

* {
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;

  /* overflow: hidden; */
  /* overflow-y: hidden; */
  /* overscroll-behavior: none; */
  /* overscroll-behavior: none; */
  /* overflow-x: hidden; */
  /* overscroll-behavior: contain; */
  /* touch-action: pan-down; */
  /* overscroll-behavior-y: contain; */
}

body {
  /* overflow: hidden; */
  overscroll-behavior-y: none;

  margin: 0;
  /* color: red; */
  /* padding-left: 15px;
  padding-right: 15px; */
  /* background-color: #060606 !important; */
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-50px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.route-enter-active {
  transition: all 0.35s ease-out;
}

.route-leave-active {
  transition: all 0.35s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.jr-bg-black {
  background-color: rgb(0, 0, 0, 0.95);
  /* background-color: rgba(0, 0, 0, 0.85); */
}

.fade-enter-from {
  opacity: 0;
  transform: translateX(-50px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.fade-enter-active {
  transition: all 0.55s ease-out;
}

.fade-leave-active {
  transition: all 0.55s ease-in;
  /* transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1); */
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.incomebg {
  background-color: rgb(9, 112, 9) !important;
}

.incomebg:hover {
  cursor: pointer;
}

/* .accordion-button:focus {
  z-index: 3;
  outline: 0 !important;
  box-shadow: 0 0 0 0.05rem rgba(255, 189, 46, 0.753);
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='orange'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='orange'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */
</style>
