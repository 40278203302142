<template>
  <div style="margin-left: 50px">
    <input
      class="form-control"
      style="margin-bottom: 4px"
      type="text"
      id="changeMax"
      v-model="maxValue"
    />
    <input
      class="form-control"
      style="margin-bottom: 4px"
      type="text"
      id="changeMax"
      v-model="minValue"
    />
    <fusioncharts
      :type="type"
      :width="width"
      :height="height"
      :dataFormat="dataFormat"
      :dataSource="dataSource"
      @dataPlotClick="onSliceClick"
    ></fusioncharts>

    <br />
    <pre>{{ dataSource }}</pre>
  </div>
</template>

<script setup>
//import { ref, reactive, computed, onBeforeMount } from 'vue';
import { computed, reactive, ref } from 'vue';
import appUtils from '../../components/utils/appUtils';

//let type = 'column2d';
let type = 'pie2d';
let width = '100%';
let height = '500';
let dataFormat = 'json';
//let dataSource = myDataSource;

let myDataSource = reactive({});

let maxValue = ref(200);
let minValue = ref(30);

myDataSource = {
  chart: {
    caption: 'Expences/Incomes',
    //subCaption: 'For a net-worth of $1M',
    numberSuffix: ' EUR',
    showPercentValues: '0',
    showPercentInTooltip: '1',
    showlegend: '0',
    //theme: 'ocean',
    theme: 'candy',
  },
  data: [
    {
      label: 'avto [1]',
      value: '-413.8',
    },
    {
      label: 'banka [5]',
      value: '-11.86',
    },
    {
      label: 'bencin',
      value: '-91.5',
    },
    {
      label: 'darilo [1]',
      value: '-34',
    },
    {
      label: 'drugo [2]',
      value: '-18.7',
    },
    {
      label: 'izredni prih. [1]',
      value: '543.34',
    },
    {
      label: 'malica',
      value: '-29.6',
    },
    {
      label: 'nevem',
      value: '-12.9',
    },
    {
      label: 'Jakob [3]',
      value: '-456.8',
    },
    {
      label: 'pica [2]',
      value: '-49.7',
    },
    {
      label: 'pij [2]',
      value: '-70',
    },
    {
      label: 'placa [1]',
      value: '5350',
    },
    {
      label: 'SiMobil',
      value: '-24.15',
    },
    {
      label: 'stan [6]',
      value: '-174.38',
    },
    {
      label: 'stanKosovo [2]',
      value: '-79.79',
    },
    {
      label: 'stroški [1]',
      value: '3145.26',
    },
    {
      label: 'šport',
      value: '-30',
    },
    {
      label: 'trgovina',
      value: '-238.43',
    },
    {
      label: 'zdravje [1]',
      value: '-149.52',
    },
    {
      label: 'potovanjeKosovo',
      value: '-3',
    },
    {
      label: 'transport [1]',
      value: '-1.2',
    },
    {
      label: 'stanFusheKosova [1]',
      value: '-5',
    },
    {
      label: 'zivljenskoZavarovanje [1]',
      value: '-72.71',
    },
    {
      label: 'poroka',
      value: '-6500',
    },
    {
      label: 'pokojnina',
      value: '-164.43',
    },
  ],
};

function onSliceClick(e) {
  //alert('click');
  console.log(e);
  console.log(e.data.categoryLabel);
}

const dataSource = computed(() => {
  let copyObj = appUtils.getObjectCopy(myDataSource);
  //   console.log('copyObj');
  //   console.log(copyObj);

  copyObj.data = copyObj.data.filter(
    (el) =>
      Number(el.value) > maxValue.value ||
      Number(el.value) < -1 * maxValue.value
  );

  //   copyObj.data = copyObj.data.filter(
  //     (el) => Number(el.value) < 500 && Number(el.value) > -500
  //   );

  //   copyObj.data = copyObj.data.filter(
  //     (el) =>
  //       Number(el.value) < minValue.value &&
  //       Number(el.value) > -1 * minValue.value
  //   );

  copyObj.data = copyObj.data.sort((a, b) => {
    return a.value - b.value;
  });

  //   return { chart: tmpChart, data: tmpData };
  return copyObj;
});
</script>
