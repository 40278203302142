//import { initializeApp } from 'firebase/app';

export default {
  global() {
    return {
      //backendUrl:
      //  'https://testing-da3c8-default-rtdb.europe-west1.firebasedatabase.app',

      // test environment
      // firebaseConfig: {
      //   apiKey: 'AIzaSyBvsYEtztD8KhzwuUe2CZJ_pkaVRpduOyY',
      //   authDomain: 'testing-da3c8.firebaseapp.com',
      //   databaseURL:
      //     'https://testing-da3c8-default-rtdb.europe-west1.firebasedatabase.app',
      //   projectId: 'testing-da3c8',
      //   storageBucket: 'testing-da3c8.appspot.com',
      //   messagingSenderId: '438514995918',
      //   appId: '1:438514995918:web:20395dac83aaba6e02f69f',
      //   measurementId: 'G-34Q2KZ3XZ1',
      // },

      //production environment
      firebaseConfig: {
        apiKey: 'AIzaSyAPYH5z9JFjV2LBWpUPZ5921SBqrt-HnGA',
        authDomain: 'fourth-groove-252512.firebaseapp.com',
        databaseURL:
          'https://fourth-groove-252512-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'fourth-groove-252512',
        storageBucket: 'fourth-groove-252512.appspot.com',
        messagingSenderId: '204180969847',
        appId: '1:204180969847:web:169cc9d972c6e061636972',
        measurementId: 'G-WF32LCBBWC',
      },

      firebaseApp: function () {
        console.log(this.firebaseConfig);
        return null;
        //return initializeApp(this.firebaseConfig);
      },
    };
  },
};

//firebaseConfig.databaseURL
