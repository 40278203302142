<template>
  <div>
    <label for="searchText">Search&nbsp;&nbsp;</label>

    <input
      class="form-control-sm"
      type="text"
      id="searchText"
      :value="modelValue"
      @input="updateValue($event.target.value)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps(['modelValue']);

const emit = defineEmits(['update:modelValue']);

const updateValue = (newValue) => {
  emit('update:modelValue', newValue);
};
</script>
