<template>
  <div>
    <form @submit.prevent="">
      <!-- Amount -->
      <div class="row">
        <label for="amount" class="col-sm-3 col-form-label text-end"
          >Amount
        </label>

        <div class="col-sm-9 mt-1">
          <input
            class="form-control-sm"
            type="text"
            id="amount"
            v-model="amountComponent"
          />
        </div>
      </div>

      <!-- Description -->
      <div class="row">
        <label for="description" class="col-sm-3 col-form-label text-end"
          >Description
        </label>

        <div class="col-sm-9 mt-1">
          <input
            class="form-control form-control-sm"
            type="text"
            id="description"
            v-model="descriptionComponent"
          />
        </div>
      </div>

      <!-- Category 1 -->
      <div class="row">
        <label class="col-sm-3 col-form-label text-end">Category 1</label>

        <div class="col-sm-9 mt-1">
          <category-dropdown
            :level="1"
            :selectedCategory="0"
            @on-change="onChangeComp"
          ></category-dropdown>
        </div>
      </div>

      <!-- Category 2 -->
      <div class="row">
        <label class="col-sm-3 col-form-label text-end">Category 2</label>

        <div class="col-sm-9 mt-1">
          <category-dropdown
            :level="2"
            :parentCategory="selectedKonto1Component"
            @on-change="onChangeComp"
          ></category-dropdown>
        </div>
      </div>

      <!-- Category 3 -->
      <div class="row">
        <label class="col-sm-3 col-form-label text-end">Category 3</label>

        <div class="col-sm-9 mt-1">
          <category-dropdown
            :level="3"
            :parentCategory="selectedKonto2Component"
            @on-change="onChangeComp"
          ></category-dropdown>
        </div>
      </div>

      <!-- Category 4 -->
      <div class="row">
        <label class="col-sm-3 col-form-label text-end">Category 4</label>

        <div class="col-sm-9 mt-1">
          <category-dropdown
            :level="4"
            :parentCategory="selectedKonto3Component"
            @on-change="onChangeComp"
          ></category-dropdown>
        </div>
      </div>

      <!-- Remark 1 -->
      <div class="row mb-2">
        <label for="remark1" class="col-sm-3 col-form-label text-end"
          >Remark
        </label>
        <div class="col-sm-9 mt-1">
          <input
            class="form-control form-control-sm"
            type="text"
            id="remark1"
            v-model="remark1Component"
          />
        </div>
      </div>

      <div class="float-end">
        <base-button class="text-end" @click="eventCloseModal"
          >Cancel</base-button
        >
        <base-button class="text-end" @click="eventSave()"
          >Save <font-awesome-icon icon="fa-floppy-disk"
        /></base-button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from 'vue';

import CategoryDropdown from '../../components/categories/CategoryDropdown.vue';
import { onChangeCategory } from '../../pages/transactions/utilsTransactions';

const emit = defineEmits(['eventCloseModal', 'eventSave']);

const amountComponent = ref(null);
const descriptionComponent = ref(null);
const remark1Component = ref(null);
const selectedKonto1Component = ref(null);
const selectedKonto2Component = ref(null);
const selectedKonto3Component = ref(null);
const selectedKonto4Component = ref(null);

onMounted(() => {
  //const { courseId: trainingCourseId } = toRefs(props)
  //accountBalance().then(arrangeAccountGroups);
});

function eventCloseModal() {
  emit('eventCloseModal');
}

function eventSave() {
  const saveObj = {
    amount: amountComponent.value,
    description: descriptionComponent.value,
    remark1: remark1Component.value,
    category1Guid: selectedKonto1Component.value,
    category2Guid: selectedKonto2Component.value,
    category3Guid: selectedKonto3Component.value,
    category4Guid: selectedKonto4Component.value,
  };

  if (saveObj.category1Guid == '0') saveObj.category1Guid = 0;
  if (saveObj.category2Guid == '0') saveObj.category2Guid = 0;
  if (saveObj.category3Guid == '0') saveObj.category3Guid = 0;
  if (saveObj.category4Guid == '0') saveObj.category4Guid = 0;

  console.log('saveObj');
  console.log(saveObj);

  emit('eventSave', saveObj);
}

function onChangeComp(categoryGuid, level) {
  onChangeCategory(
    categoryGuid,
    level,
    selectedKonto1Component,
    selectedKonto2Component,
    selectedKonto3Component,
    selectedKonto4Component
  );
}
</script>
