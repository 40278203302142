<template>
  <!-- <tr :id="guid" :class="setclass()"> -->

  <tr :id="guid" :class="setclass()">
    <td v-show="isEditVisible">
      <button
        class="btn btn-sm bg-warning"
        @click="setSelectedId($event, guid)"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>
    </td>

    <td v-show="isCheckboxVisible">
      <input
        @click="setSelectedMulti($event, guid)"
        class="form-check-input"
        type="checkbox"
        value="guid"
        :id="getCheckboxId(guid)"
      />
    </td>

    <td :class="setclass()" style="width: 110px">
      {{ getFormatedDate }}
    </td>

    <td :class="setclass()" v-if="guidaccount">
      {{ accDesc }}
    </td>

    <td v-if="amount" :class="setclass()" style="text-align: right">
      {{ formatCurrency(amount) }}
    </td>
    <td :class="setclass()" style="text-align: right; width: 100px" v-else>
      0
    </td>

    <td v-if="desc" :class="setclass()" style="vertical-align: middle">
      {{ desc }}
    </td>
    <td v-else>
      <span v-if="desc" :class="setclass()" style="color: blue"></span>
      &nbsp;
    </td>

    <td :class="setclass()" style="vertical-align: middle">
      <!-- {{ getKontoFull }} -->
      {{ kontoDesc }}
    </td>

    <td :class="setclass()" style="vertical-align: middle">
      {{ remark1 }}
    </td>

    <td v-show="isDeleteVisible">
      <button
        class="btn btn-danger btn-sm"
        @click="deleteSelectedId($event, guid)"
      >
        <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script setup>
import appUtils from '../utils/appUtils';

//VUE core
import { defineEmits, defineProps, onMounted, computed, ref } from 'vue';

let configComponent = ref({ editVisible: true });

const props = defineProps([
  'guid',
  'guidaccount',
  'desc',
  'amount',
  'guidknt1',
  'guidknt2',
  'guidknt3',
  'guidknt4',
  'date',
  'remark1',
  'selectedtransid',
  'subcategoryDesc',
  'kontoDesc',
  'accDesc',
  'configComponent',
]);
const emit = defineEmits(['select-id', 'delete-id', 'select-multi-id']);

onMounted(() => {
  console.log('onMounted => TransactionItem');
  setParameters();
});

function setParameters() {
  appUtils.setParametersTransactionItem(configComponent, props);
}
const getFormatedDate = computed(function () {
  return appUtils.getFormatedDate(props.date);
});

const isEditVisible = computed(function () {
  return configComponent.value.editVisible;
});

const isCheckboxVisible = computed(function () {
  return configComponent.value.checkboxVisible;
});

const isDeleteVisible = computed(function () {
  return configComponent.value.deleteVisible;
});

function getCheckboxId(guid) {
  {
    return 'check' + guid;
  }
}

function formatCurrency(parNumber) {
  return appUtils.formatCurrency(parNumber);
}

function setSelectedId(event, value) {
  emit('select-id', value);
}

function deleteSelectedId(event, value) {
  emit('delete-id', value);
}

function setSelectedMulti(event, value) {
  emit('select-multi-id', value);
}

function setclass() {
  {
    return {
      isActivejr: props.selectedtransid === props.guid ? true : false,
    };
  }
}

// function data() {
//   return { isActive: false };
// }
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.75rem;
}

h3 {
  font-size: 1.3rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.3rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.litrans {
}
</style>
