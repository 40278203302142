<template>
  <div class="text-light">
    <!-- <pre>{{ searchText }}</pre> -->

    <!-- <pre>{{ categoryStore.categories }}</pre> -->

    <!-- multiSave dialog -->
    <base-dialog
      :show="!!showMultiDialog"
      :show-ok="false"
      :title="getMultiTitle"
      @close="handleCloseShowMulti"
    >
      <div class="mb-5">
        <transaction-dialog
          @event-save="saveMultiId"
          @event-close-modal="handleCloseShowMulti"
        ></transaction-dialog>
      </div>
    </base-dialog>

    <!-- {{ $t('home.header', { something: minDate }) }}
    {{ $t('home.donation', { donations: $n(1234, 'currencyFormat') }) }}

    <div>
      {{ $t('home.donation', { donations: $n(1234, 'currencyFormat') }) }}
    </div>
    <div>
      {{ $n(1234, 'currencyFormat') }}
    </div>
    <div>
      {{ appUtils.formatCurrency(1234, false) }}
      sadsad
    </div>
    <div>
      {{ $n(1234, 'currencyFormat') }}
    </div> -->

    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>

    <!-- delete dialog -->
    <base-dialog
      :show="!!showDeleteDialog"
      :show-ok="false"
      title="Delete transaction?"
      @close="handleCloseShowDelete"
    >
      <div class="tx-light mb-2">
        Are you sure you want to delete transaction
        <span class="text-warning"> {{ deleteTitleGuid }} ?</span>

        <!-- <div>{{ deleteTitleGuidDesc }}</div> -->

        <div v-html="deleteTitleGuidDesc"></div>

        <div class="float-end mt-4">
          <base-button @click="handleCloseShowDelete">Cancel</base-button>
          <base-button @click="deleteIdActual">Delete</base-button>
        </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    </base-dialog>

    <div v-if="1 == 0" class="ms-3">
      <base-button type="button" @click="importFromJson()"
        >Import JSON</base-button
      >
      <h4 class="text-warning">{{ importCurrentRow }} / {{ importCounter }}</h4>
    </div>

    <section class="mt-1 mb-2">
      <import-item @importdone="eventImportDone"></import-item>
    </section>

    <!-- Balance All -->
    <section class="mb-2">
      <balance-board></balance-board>
    </section>

    <section
      class="mb-2"
      v-for="item in globalGroupsArray"
      :key="item.guid"
      :value="item.guid"
    >
      <balance-board
        v-if="item.active == 1"
        :globalGroup="item.guid"
        :globalGroupName="item.name"
      ></balance-board>
    </section>

    <section v-if="1 == 0">
      <div class="row">
        <div class="col-md-6">
          <base-card class="bg-dark text-warning">
            <div class="text-warning">
              <h5>transData Old</h5>
            </div>
            <pre class="bg-dark text-light">{{ transDataOldComputed }}</pre>
          </base-card>
        </div>
        <div class="col-md-6">
          <base-card class="bg-dark">
            <div class="text-warning">
              <h5>transData New</h5>
            </div>
            <pre class="bg-dark text-light">{{ transDataNew }}</pre>
          </base-card>
        </div>
      </div>
    </section>

    <div class="accordion accordion-flush mt-2" id="accordionExample">
      <div class="accordion-item bg-dark">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <h4 class="text-warning mt-1">Transactions</h4>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <base-button
              class="mt-2"
              @click="addNewRec()"
              data-bs-toggle="modal"
              data-bs-target="#modaltransaction"
              >Add <font-awesome-icon icon="fa-solid fa-square-plus"
            /></base-button>

            <base-button data-bs-toggle="modal" data-bs-target="#exampleModal">
              Find <font-awesome-icon icon="fa-solid fa-filter" />
            </base-button>

            <base-button
              :disabled="transMultiId.length == 0"
              type="button"
              @click="showMultiDialog = true"
              >Edit selected
              <font-awesome-icon icon="fa-pen-to-square" />
            </base-button>

            <!-- fast search -->
            <div class="row mb-3 text-warning">
              <div class="col-sm-4 ms-1">
                <base-text-search
                  v-model="searchText"
                  class="mt-4"
                ></base-text-search>

                <!-- <label for="searchText" class="col-sm-3 col-form-label"
                  >Search
                </label>

                <br />
                <input
                  class="form-control-sm"
                  type="text"
                  id="searchText"
                  v-model="searchText"
                /> -->
              </div>
            </div>

            <!-- DIALOG SEARCH -->
            <search-dialog @onsearch="searchMaster"></search-dialog>

            <!-- DIALOG EDIT TRANS -->
            <div
              class="modal fade"
              id="modaltransaction"
              tabindex="-1"
              aria-labelledby="vLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header bg-warning">
                    <h5
                      class="modal-title fs-5 text-dark"
                      id="modaltransactionLabel"
                    >
                      {{ modalTransactionCaption }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body bg-dark text-light">
                    <form @submit.prevent="">
                      <!-- radio transfer? -->
                      <div v-if="radioTransVisible" class="row mb-3">
                        <label
                          class="col-sm-4 form-check-label text-end fw-normal text-warning"
                          for="radioTransaction1"
                          >Transfer?</label
                        >
                        <div class="col-sm-8 form-check form-switch">
                          <input
                            @click="toggleRadio"
                            class="form-check-input"
                            type="checkbox"
                            id="radioTransaction1"
                            v-model="radioTrans"
                          />
                        </div>
                      </div>

                      <!-- Date -->
                      <div class="row">
                        <label
                          class="col-sm-3 col-form-label text-end fw-normal"
                          >Date</label
                        >

                        <div class="col-sm-5 mt-1">
                          <base-date-picker
                            @ondatechange="ondatechangehere"
                            id="selectedDate"
                            :year-range="[2010, 2030]"
                            :min-date="minDate"
                            :max-date="maxDate"
                            :model-value="selectedDate"
                          >
                          </base-date-picker>

                          <!-- <Datepicker
                            id="selectedDate"
                            v-model="selectedDate"
                            format="dd.MM.yyyy"
                            :min-date="minDate"
                            :max-date="maxDate"
                            auto-apply
                            :enable-time-picker="false"
                            :year-range="[2010, 2030]"
                            locale="en-GB"
                          ></Datepicker> -->
                        </div>
                      </div>

                      <!-- termin -->
                      <!-- <div v-if="1 == 0" class="row">
                        <label
                          for="termin"
                          class="col-sm-3 col-form-label text-end fw-normal"
                          >YYYY-MM</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control-sm"
                            id="termin"
                            v-model.trim="termin"
                          />
                        </div>
                      </div> -->

                      <!-- Amount -->
                      <div class="row">
                        <label
                          for="amount"
                          class="col-sm-3 col-form-label text-end"
                          >Amount
                        </label>

                        <div class="col-sm-9 mt-1">
                          <input
                            class="form-control-sm"
                            type="text"
                            id="amount"
                            v-model="amount"
                          />
                        </div>
                      </div>

                      <!-- Description -->
                      <div class="row">
                        <label
                          for="description"
                          class="col-sm-3 col-form-label text-end"
                          >Description
                        </label>

                        <div class="col-sm-9 mt-1">
                          <input
                            class="form-control form-control-sm"
                            type="text"
                            id="description"
                            v-model.trim="description"
                          />
                        </div>
                      </div>

                      <!-- Account -->
                      <div class="row">
                        <label
                          for="account"
                          class="col-sm-3 col-form-label text-end"
                          >Account
                        </label>

                        <div class="col-sm-9 mt-1">
                          <account-dropdown
                            @onchange="onChangeAccount"
                            :modelValue="selectedAcc"
                          ></account-dropdown>

                          <!-- <select
                    class="form-select form-select-sm"
                    id="account"
                    name="selectedAcc"
                    v-model="selectedAcc"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getAccountsWithGuid"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                        </div>
                      </div>

                      <!-- Account 2 -->
                      <div v-if="radioTrans" class="row">
                        <label
                          for="account2"
                          class="col-sm-3 col-form-label text-end"
                          >Account 2
                        </label>

                        <div class="col-sm-9 mt-1">
                          <account-dropdown
                            @onchange="onChangeAccount2"
                            :modelValue="selected2Acc"
                          ></account-dropdown>
                        </div>
                      </div>

                      <!-- Category 1 -->
                      <div v-if="!radioTrans" class="row">
                        <label
                          class="col-sm-3 col-form-label text-end"
                          for="konto1"
                          >Category 1
                        </label>

                        <div class="col-sm-9 mt-1">
                          <category-dropdown
                            :level="1"
                            :parentCategory="0"
                            :modelValue="selectedKonto1"
                            @on-change="onchangejrComp($event, 1)"
                          ></category-dropdown>
                        </div>
                      </div>

                      <!-- Category 2 -->
                      <div v-if="!radioTrans" class="row">
                        <label
                          class="col-sm-3 col-form-label text-end"
                          for="konto2"
                          >Category 2
                        </label>
                        <div class="col-sm-9 mt-1">
                          <category-dropdown
                            :level="2"
                            :parentCategory="selectedKonto1"
                            :modelValue="selectedKonto2"
                            @on-change="onchangejrComp($event, 2)"
                          ></category-dropdown>
                        </div>
                      </div>

                      <!-- Category 3 -->
                      <div v-if="!radioTrans" class="row">
                        <label
                          class="col-sm-3 col-form-label text-end"
                          for="konto3"
                          >Category 3
                        </label>
                        <div class="col-sm-9 mt-1">
                          <category-dropdown
                            :level="3"
                            :parentCategory="selectedKonto2"
                            :modelValue="selectedKonto3"
                            @on-change="onchangejrComp($event, 3)"
                          ></category-dropdown>
                        </div>
                      </div>

                      <!-- Category 4 -->
                      <div v-if="!radioTrans" class="row">
                        <label
                          class="col-sm-3 col-form-label text-end align-bottom"
                          for="konto4"
                          >Category 4
                        </label>
                        <div class="col-sm-9 mt-1">
                          <category-dropdown
                            :level="4"
                            :parentCategory="selectedKonto3"
                            :modelValue="selectedKonto4"
                            @on-change="onchangejrComp($event, 4)"
                          ></category-dropdown>
                        </div>
                      </div>

                      <!-- Remark 1 -->
                      <div class="row mb-2">
                        <label
                          for="remark1"
                          class="col-sm-3 col-form-label text-end"
                          >Remark
                        </label>
                        <div class="col-sm-9 mt-1">
                          <input
                            class="form-control form-control-sm"
                            type="text"
                            id="remark1"
                            v-model.trim="remark1"
                          />
                        </div>
                      </div>

                      <div class="float-end">
                        <base-button class="text-end" data-bs-dismiss="modal"
                          >Cancel</base-button
                        >

                        <base-button
                          class="text-end"
                          data-bs-dismiss="modal"
                          data-bs-target="#modaltransaction"
                          :disabled="disableSave()"
                          @click="saveAtomic()"
                          >Save <font-awesome-icon icon="fa-floppy-disk"
                        /></base-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- END DIALOG EDIT TRANS -->

            <section>
              <base-card v-if="isLoading">
                <div v-if="isLoading">
                  <base-spinner></base-spinner>
                </div>
              </base-card>

              <base-card class="bg-dark text-light">
                <div>Number of records: {{ getFilteredTrans.length }}</div>
                <div>
                  Total: {{ appUtils.formatCurrency(sumTransComputed) }}
                </div>
                <div v-if="getMultiSelectedItemsNumber > 0">
                  Selected transactions [ {{ getMultiSelectedItemsNumber }} ,
                  Sum is {{ transMultiIdSum }} ]
                </div>
              </base-card>
            </section>

            <!-- transactions -->
            <base-card class="bg-dark text-light"
              ><table class="table table-striped table-dark">
                <transaction-header
                  @sortDataEvent="sortDataEvent"
                  @setSelectedMultiAllEvent="setSelectedMultiAll"
                >
                </transaction-header>

                <tbody class="align-middle">
                  <transaction-item
                    @select-id="selectId"
                    @delete-id="deleteId"
                    @select-multi-id="selectMultiId"
                    v-for="item in getFilteredTrans"
                    :key="item.guid"
                    :index="item.guid"
                    :guid="item.guid"
                    :guidaccount="item.guidaccount"
                    :desc="item.description"
                    :amount="item.amount"
                    :guidknt1="item.guidknt1"
                    :guidknt2="item.guidknt2"
                    :guidknt3="item.guidknt3"
                    :guidknt4="item.guidknt4"
                    :date="item.date"
                    :remark1="item.remark1"
                    :selectedtransid="getSelectedGuid"
                    :accDesc="item.accDesc"
                    :kontoDesc="item.kontoDesc"
                  ></transaction-item>
                  <!-- :termin="item.termin" -->
                </tbody>
              </table>
            </base-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dp__input {
  line-height: 1.2rem;
}
</style>

<script setup>
//components
import TransactionItem from '../../components/transactions/TransactionItem.vue';
import TransactionDialog from '../../components/transactions/TransactionDialog.vue';
import CategoryDropdown from '../../components/categories/CategoryDropdown.vue';
import BalanceBoard from '../../components/balance/BalanceBoard.vue';
import ImportItem from '../../components/import/ImportItem.vue';
import accountDropdown from '../../components/accounts/accountDropdown.vue';
import searchDialog from '../../components/transactions/SearchDialog.vue';
//import BaseLink from '../../components/ui/BaseLink.vue';
import TransactionHeader from '../../components/transactions/TransactionHeader.vue';

//UTILS
import appUtils from '../../components/utils/appUtils.js';
import { deleteTransaction } from './utilsTransactionDelete.js';
import {
  saveTransactionAtomic,
  getGuidLastLevelKonto,
  onChangeCategory,
  searchTransDecade,
  fastFilterTrans,
} from './utilsTransactions.js';

//VUE core
import { ref, toRef, computed, reactive, onMounted } from 'vue';

//firebase
import { getDatabase } from 'firebase/database';

//import Datepicker from '@vuepic/vue-datepicker';
import getTransData from './data.js';

//import { useCategoryStore } from '../../stores/CategoryStore';
//import { useUserStore } from '../../stores/UserStore';

//store
import { useTransStore } from '../../stores/TransStore';
import { useSettingStore } from '../../stores/SettingStore';
import { useUserStore } from '../../stores/UserStore';

const settingStore = useSettingStore();

//const { t } = useI18n();
//const categoryStore = useCategoryStore();
//const userStore = useUserStore();

const pageStore = useTransStore();
const userStore = useUserStore();

const minDate = '2010-01-01';
//const maxDate = appUtils.getCurrentYear() + '-12-31';
const maxDate = '2025-12-31';

let searchObjectMaster = {};

const isLoading = ref(false);
const error = ref(null);
const showDeleteDialog = ref(null);
const showMultiDialog = ref(null);
const deleteTitleGuid = ref('');
const selectedTransGuid = ref(null);
const deleteTitleGuidDesc = ref('');
const searchText = ref(null);

// const termin = ref('2024');
//const termin = ref(appUtils.getCurrentYearMonth);

const amount = ref(0);
//let selectedAcc = ref('0');
let selectedAcc = ref(null);
let selected2Acc = ref(null);

const selectedDate = ref(
  appUtils.getCurrentYearMonth() +
    '-' +
    String(new Date().getDate()).padStart(2, '0')
);

const radioTrans = ref(false);
const radioTransVisible = ref(false);

const selectedKonto1 = ref(0);
const selectedKonto2 = ref(0);
const selectedKonto3 = ref(0);
const selectedKonto4 = ref(0);

const description = ref('');
const remark1 = ref('');

const modalTransactionCaption = ref('Add transaction');
const selectedTrans = toRef({});
const transDataOld = reactive({});

const dataSorting = ref('');
const dataSortingOrder = ref('asc');

const importCounter = ref(0);
const importCurrentRow = ref(0);

const transMultiId = reactive([]);
let transMultiIdSum = ref(0);

let globalGroups = reactive([]);

onMounted(() => {
  console.log('onMounted => AddTransactionSetup');
  selectedAcc.value = appUtils.getSettings('appSettings', 'cashAcc1');
  // console.log('process.env.NODE_ENV');
  // console.log(process.env.NODE_ENV);
  // console.log(process.env.VUE_APP_NOT_SECRET_CODE);
  transMultiId.length = 0;

  globalGroups.value = settingStore.globalGroups;

  console.log('=========== globalGroups ===========');
  console.log(globalGroups.value);
  console.log(globalGroups.value.length);
  for (var i = 0; i < globalGroups.value.length; i++) {
    console.log(globalGroups.value[i]);
  }

  var storedFilter = userStore.transFilter;
  console.log('=========== storedFilter ===========');
  console.log(storedFilter);

  searchObjectMaster = storedFilter;

  // let objectToInspect = globalGroups.value;
  // let result = [];

  // while (objectToInspect !== null) {
  //   result = result.concat(Object.getOwnPropertyNames(objectToInspect));
  //   objectToInspect = Object.getPrototypeOf(objectToInspect);
  // }

  // console.log('=========== result ===========');
  // console.log(result);
});

// onUpdated(() => {
// });

// function importFromJsonNext() {
//   countDefined.value += 1;
//   importFromJson();
// }

// function importFromJsonBack() {
//   countDefined.value -= 1;
//   //if (countDefined.value < 0) countDefined.value = 0;
//   importFromJson();
// }

// function updateUserProfile() {
//   const userStore = useUserStore();
//   userStore.updateUserProfile();
// }

async function importFromJson() {
  const items = getTransData.getData();

  importCounter.value = items.length;

  for (var i = 0; i < items.length; i++) {
    selectedTransGuid.value = null;
    importCurrentRow.value = i + 1;

    let tmpAccGuid = appUtils.getGuidAcc(items[i].idaccount);
    const tmp1 = appUtils.getIdKontoObj(items[i].idknt1);
    const tmp2 = appUtils.getIdKontoObj(items[i].idknt2);
    const tmp3 = appUtils.getIdKontoObj(items[i].idknt3);
    const tmp4 = appUtils.getIdKontoObj(items[i].idknt4);

    let guidkonto = tmp1 ? tmp1.guid : null;
    guidkonto = tmp2 ? tmp2.guid : guidkonto;
    guidkonto = tmp3 ? tmp3.guid : guidkonto;
    guidkonto = tmp4 ? tmp4.guid : guidkonto;

    const transNew = {
      id_old: items[i].id,
      date: items[i].date,
      year: items[i]['date'].substring(0, 4),
      //termin: items[i]['date'].substring(0, 7),
      guidaccount: tmpAccGuid ? tmpAccGuid.guid : null,

      guidknt1: tmp1 ? tmp1.guid : null,
      guidknt2: tmp2 ? tmp2.guid : null,
      guidknt3: tmp3 ? tmp3.guid : null,
      guidknt4: tmp4 ? tmp4.guid : null,

      guidkonto: guidkonto,
      remark1: items[i].remark,
      remark2: items[i].remark2,
      remark3: items[i].remark3,
      remark4: items[i].remark4,
      remark5: items[i].remark5,
      amount: items[i].amount,
      description: items[i].description,
      search: items[i].description ? items[i].description.toLowerCase() : null,
    };

    await saveTransactionAtomic({
      new: transNew,
      old: null,
    });
  }
}

function disableSave() {
  if (getFilteredTrans.value.length != 0 || selectedTransGuid.value == null) {
    return false;
  }
  return true;
}

const getSelectedGuid = computed(function () {
  if (selectedTransGuid.value) {
    return selectedTransGuid.value;
  } else {
    return '*';
  }
});

function onchangejrComp(eventArgs, level) {
  onChangeCategory(
    eventArgs,
    level,
    selectedKonto1,
    selectedKonto2,
    selectedKonto3,
    selectedKonto4
  );
}

// function formatTextForDialog(
//   parText,
//   parCaption,
//   isDate = false,
//   isCurrency = false
// ) {
//   if (isDate) {
//     deleteTitleGuidDesc.value += parText
//       ? '<span class="text-warning">' +
//         parCaption +
//         ':</span> ' +
//         appUtils.getFormatedDate(parText) +
//         '<br />'
//       : '';
//   } else if (isCurrency) {
//     deleteTitleGuidDesc.value += parText
//       ? '<span class="text-warning">' +
//         parCaption +
//         ':</span> ' +
//         appUtils.formatCurrency(parText) +
//         '<br />'
//       : '';
//   } else {
//     deleteTitleGuidDesc.value += parText
//       ? '<span class="text-warning">' +
//         parCaption +
//         ':</span> ' +
//         parText +
//         '<br />'
//       : '';
//   }
// }

function deleteId(transGuid) {
  showDeleteDialog.value = true;

  deleteTitleGuid.value = transGuid;
  deleteTitleGuidDesc.value = '';

  for (var i = 0; i < getFilteredTrans.value.length; i++) {
    if (getFilteredTrans.value[i].guid == transGuid) {
      //find the transaction and show details in dialog
      let setDeleteText = '<br />';

      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].date,
        'Date',
        true
      );
      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].accDesc,
        'Account'
      );
      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].amount,
        'Amount',
        false,
        true
      );
      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].description,
        'Description'
      );
      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].kontoDesc,
        'Category'
      );
      setDeleteText += appUtils.formatTextForDialog(
        getFilteredTrans.value[i].remark1,
        'Remark'
      );
      deleteTitleGuidDesc.value = setDeleteText;
    }
  }
}

function deleteIdActual() {
  const searchObject = getSearchObject();
  const X = window.scrollX;
  const Y = window.scrollY;
  if (deleteTitleGuid.value) {
    deleteTransaction(deleteTitleGuid.value, searchObject).then(() => {
      //scrolling back to previous position
      window.scrollTo(X, Y);
      appUtils.showToast('Transaction Deleted');
    });
  }
  handleCloseShowDelete();
}

function handleCloseShowDelete() {
  showDeleteDialog.value = null;
  deleteTitleGuid.value = null;
}

function handleCloseShowMulti() {
  showMultiDialog.value = null;
}

function setSelectedMultiAll(eventArgs) {
  //console.log(eventArgs);

  transMultiId.length = 0;
  transMultiIdSum.value = 0;

  const trans = getFilteredTrans;
  for (var i = 0; i < trans.value.length; i++) {
    if (eventArgs.target.checked) {
      transMultiId.push(trans.value[i].guid);
      calculateSumOfSelectedTrans(trans.value[i].guid);
    }
    changeCheckBox('check' + trans.value[i].guid, eventArgs.target.checked);
  }
}

function changeCheckBox(checkId, checked) {
  //console.log('checkId', checkId);

  let elem = document.getElementById(checkId);
  if (elem) elem.checked = checked;
}

function calculateSumOfSelectedTrans(transGuid, removeFromSum = false) {
  const trans = getFilteredTrans;
  for (var i = 0; i < trans.value.length; i++) {
    if (trans.value[i].guid === transGuid) {
      console.log(trans.value[i]);

      if (removeFromSum) {
        transMultiIdSum.value = appUtils.getSumofTwoNumbers(
          transMultiIdSum.value,
          -1 * trans.value[i].amount
        );
      } else {
        transMultiIdSum.value = appUtils.getSumofTwoNumbers(
          transMultiIdSum.value,
          trans.value[i].amount
        );
      }
    }
  }
}

function selectMultiId(transGuid) {
  radioTrans.value = false;
  radioTransVisible.value = false;

  if (transMultiId.includes(transGuid)) {
    transMultiId.splice(transMultiId.indexOf(transGuid), 1);
    calculateSumOfSelectedTrans(transGuid, true);
  } else {
    transMultiId.push(transGuid);
    calculateSumOfSelectedTrans(transGuid);
  }

  console.log(transMultiId);
}

async function saveMultiId(saveObj) {
  console.log('saveObj', saveObj);
  console.log(transMultiId);

  for (var i = 0; i < transMultiId.length; i++) {
    //console.log(transMultiId[i]);
    selectId(transMultiId[i]);
    await saveAtomicMulti(saveObj);
  }

  searchTrans();
  showMultiDialog.value = false;
}

const getMultiSelectedItemsNumber = computed(function () {
  return transMultiId.length;
});

const getMultiTitle = computed(function () {
  return 'Update selected transactions [' + transMultiId.length + ']';
});

async function saveAtomicMulti(saveObj) {
  const transNew = transDataNew.value;

  if (saveObj.amount) transNew.amount = parseFloat(saveObj.amount);
  if (saveObj.description) transNew.description = saveObj.description;
  if (saveObj.remark1) transNew.remark1 = saveObj.remark1;
  if (saveObj.category1Guid) {
    transNew.guidknt1 = saveObj.category1Guid;
    transNew.guidknt2 = null;
    transNew.guidknt3 = null;
    transNew.guidknt4 = null;
    transNew.guidkonto = saveObj.category1Guid;
  }
  if (saveObj.category2Guid) {
    transNew.guidknt2 = saveObj.category2Guid;
    transNew.guidknt3 = null;
    transNew.guidknt4 = null;
    transNew.guidkonto = saveObj.category2Guid;
  }
  if (saveObj.category3Guid) {
    transNew.guidknt3 = saveObj.category3Guid;
    transNew.guidknt4 = null;
    transNew.guidkonto = saveObj.category3Guid;
  }
  if (saveObj.category4Guid) {
    transNew.guidknt4 = saveObj.category4Guid;
    transNew.guidkonto = saveObj.category4Guid;
  }

  let transOld = null;

  if (selectedTransGuid.value) transOld = transDataOld.value;

  //if value is empty - like empty string - we set it
  //to null to so it doesn't appear in database
  if (!transNew.description) transNew.description = null;
  if (!transNew.remark1) transNew.remark1 = null;
  if (!transNew.remark2) transNew.remark2 = null;
  if (!transNew.remark3) transNew.remark3 = null;
  if (!transNew.remark4) transNew.remark4 = null;

  console.log('========  saveAtomicMulti => transNew  ================');
  console.log(transNew);
  console.log('====================================');

  let promise = saveTransactionAtomic({
    new: transNew,
    old: transOld,
  });

  return promise;
}

function selectId(transGuid) {
  const trans = getFilteredTrans;

  if (!transGuid) return;
  // console.log(transGuid);
  radioTransVisible.value = false;
  radioTrans.value = false;

  for (var i = 0; i < trans.value.length; i++) {
    if (trans.value[i].guid === transGuid) {
      transDataOld.value = trans.value[i];

      if (typeof transDataOld.value.guidkonto === 'undefined')
        transDataOld.value.guidkonto = null;
      if (typeof transDataOld.value.guidknt1 === 'undefined')
        transDataOld.value.guidknt1 = null;
      if (typeof transDataOld.value.guidknt2 === 'undefined')
        transDataOld.value.guidknt2 = null;
      if (typeof transDataOld.value.guidknt3 === 'undefined')
        transDataOld.value.guidknt3 = null;
      if (typeof transDataOld.value.guidknt4 === 'undefined')
        transDataOld.value.guidknt4 = null;

      selectedTrans.value = trans.value[i];

      // console.log('selectedTrans.value');
      // console.log(selectedTrans.value);

      amount.value = selectedTrans.value.amount;
      description.value = selectedTrans.value.description;
      //termin.value = selectedTrans.value.termin;
      selectedTransGuid.value = selectedTrans.value.guid;
      modalTransactionCaption.value =
        'Edit Transaction [' + selectedTransGuid.value + ']';

      //selectedAcc.value = selectedTrans.value.idaccount;
      selectedAcc.value = selectedTrans.value.guidaccount;
      selectedKonto1.value = selectedTrans.value.guidknt1;
      selectedKonto2.value = selectedTrans.value.guidknt2;
      selectedKonto3.value = selectedTrans.value.guidknt3;
      selectedKonto4.value = selectedTrans.value.guidknt4;
      remark1.value = selectedTrans.value.remark1;
      selectedDate.value = selectedTrans.value.date;
      break;
    }
  }
}

const sumTransComputed = computed(function () {
  let suma = 0;
  for (var i = 0; i < getFilteredTrans.value.length; i++) {
    suma += Number(getFilteredTrans.value[i].amount);
    //console.log(suma);
  }
  return appUtils.fixCurrency(suma);
});

const getFilteredTrans = computed(function () {
  const transStore = useTransStore();

  if (dataSorting.value != '') {
    //we have sorting before delete and we apply it again
    // transStore.setSorting({
    //   field: dataSorting.value,
    //   order: dataSortingOrder.value,
    // });
  }
  let tmp = transStore.transactions;

  //fast search
  //if (searchText.value) tmp = appUtils.fastFilter(tmp, searchText.value);
  if (searchText.value) tmp = fastFilterTrans(tmp, searchText.value);

  //console.log(tmp);

  return tmp;
});

// function fastFilterTrans(tmp, searchText) {
//   const fieldArr = ['description', 'accDesc', 'kontoDesc', 'remark1'];

//   if (searchText.length <= 1) {
//     return tmp.filter(() => {
//       return true;
//     });
//   } else {
//     return tmp.filter(
//       appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
//     );
//   }
// }

const globalGroupsArray = computed(() => {
  console.log(settingStore.globalGroups);

  return settingStore.globalGroups;
});

function handleError() {
  error.value = null;
}

function addNewRec() {
  modalTransactionCaption.value = 'Add Transaction';
  radioTrans.value = false;
  radioTransVisible.value = true;
  selectedTransGuid.value = null;
  //termin.value = appUtils.getCurrentYearMonth();
  amount.value = 0;
  selected2Acc.value = null;
  description.value = '';
  remark1.value = '';
}

const transDataOldComputed = computed(function () {
  return transDataOld.value;
});

const transDataNew = computed(function () {
  let guidLastLevelKonto = getGuidLastLevelKonto(
    selectedKonto1.value,
    selectedKonto2.value,
    selectedKonto3.value,
    selectedKonto4.value
  );

  const transData = {
    date: appUtils.getDateAsString(selectedDate.value),
    amount: parseFloat(amount.value),
    guidaccount: selectedAcc.value ? selectedAcc.value : null,
    guidkonto: guidLastLevelKonto,
    guidknt1: selectedKonto1.value ? selectedKonto1.value : null,
    guidknt2: selectedKonto2.value ? selectedKonto2.value : null,
    guidknt3: selectedKonto3.value ? selectedKonto3.value : null,
    guidknt4: selectedKonto4.value ? selectedKonto4.value : null,
    description: description.value,
    remark1: remark1.value,
    search: description.value ? description.value.toLowerCase() : null,
  };

  if (transData.guidknt1 == '0') transData.guidknt1 = null;
  if (transData.guidknt2 == '0') transData.guidknt2 = null;
  if (transData.guidknt3 == '0') transData.guidknt3 = null;
  if (transData.guidknt4 == '0') transData.guidknt4 = null;
  if (transData.guidkonto == '0') transData.guidkonto = null;

  return transData;
});

async function saveAtomic() {
  if (selected2Acc.value && radioTrans.value) {
    selectedKonto1.value = 0;
    selectedKonto2.value = 0;
    selectedKonto3.value = 0;
    selectedKonto4.value = 0;
  }

  const transNew = transDataNew.value;
  console.log('transNew');
  console.log(transNew);

  let transOld = null;

  if (selectedTransGuid.value) transOld = transDataOld.value;

  //for expences we save amount with negative number
  if (!transOld && transNew.guidknt1 && transNew.amount > 0) {
    if (!appUtils.getGuidKontoObj(transNew.guidknt1).type) {
      transNew.amount = -1 * transNew.amount;
    }
  }

  // if value is empty - like empty string - we set it
  // to null so it doesn't appear in database
  if (!transNew.description) transNew.description = null;
  if (!transNew.remark1) transNew.remark1 = null;
  if (!transNew.remark2) transNew.remark2 = null;
  if (!transNew.remark3) transNew.remark3 = null;
  if (!transNew.remark4) transNew.remark4 = null;

  // console.log('========  transNew  ================');
  // console.log(transNew);
  // console.log('====================================');

  if (selected2Acc.value && radioTrans.value) {
    //saving 2 transactions one in + and one in -

    //added 2024.01
    if (transNew.description == null) {
      transNew.description =
        'transfer [' +
        appUtils.getGuidAccountObj(selectedAcc.value).description +
        ' - ' +
        appUtils.getGuidAccountObj(selected2Acc.value).description +
        ']';
    }

    const promise1 = saveTransactionAtomic({
      new: transNew,
      old: null,
    });

    let transNew2 = appUtils.getObjectCopy(transNew);
    transNew2.amount = -1 * transNew2.amount;
    transNew2.guidaccount = selected2Acc.value;

    const promise2 = saveTransactionAtomic({
      new: transNew2,
      old: null,
    });

    Promise.all([promise1, promise2])
      .then((values) => {
        console.log('Promise.all => values');
        console.log(values);
        console.log('Promise.all => values');
        searchTrans(values[0].guid);
      })
      .catch((error) => console.log(error));
  } else {
    saveTransactionAtomic({
      new: transNew,
      old: transOld,
    })
      .then((values) => {
        // console.log(values);

        appUtils.showToast('Transaction Saved<br>ID: ' + values.guid);

        searchTrans(values.guid);
      })
      .catch((error) => console.log(error));
  }
}

function searchMaster(params) {
  searchObjectMaster = params.searchObject;

  console.log(params);

  //searching from year to year
  if (params.searchYear1.value && params.searchYear2.value) {
    let yearArr = [];
    if (params.searchYear1.value && params.searchYear2.value) {
      for (
        let i = Number(params.searchYear1.value);
        i <= Number(params.searchYear2.value);
        i++
      ) {
        yearArr.push(i);
      }
    }

    let searchObject = getSearchObject();
    searchObject.searchTermin = null;

    searchTransDecade(searchObject, yearArr);
  } else {
    //searching in only one year
    searchTrans();
  }
}

// function searchTransDecade(searchObject, yearArr = null) {
//   const db = getDatabase();

//   const transStore = useTransStore();
//   transStore.setTransactionsEmpty();

//   let promises = [];
//   for (let i = 0; i < yearArr.length; i++) {
//     let year = String(yearArr[i]);
//     promises.push(
//       transStore.getTransDecade({
//         db,
//         year: year,
//         searchObj: searchObject,
//       })
//     );
//   }

//   Promise.all(promises).then((values) => {
//     values.forEach((snapshot) => {
//       console.log(snapshot);
//       transStore.rearrangeTrans([snapshot.val(), searchObject, true]);
//     });
//     // console.log(values);
//   });
// }

function searchTrans(guid = null) {
  const db = getDatabase();

  console.log('======= transStore.getLastTrans ============= ');
  const transStore = useTransStore();

  transStore
    .getLastTrans({
      db,
      searchObj: getSearchObject(),
    })
    .then(() => {
      if (guid) selectId(guid);

      for (var i = 0; i < transMultiId.length; i++) {
        changeCheckBox('check' + transMultiId[i], true);
      }
    })
    .catch((error) => console.log(error))
    .finally(() => {
      console.log('finally searchTrans()');
    });
}

function onChangeAccount(value) {
  selectedAcc.value = value;
}

function onChangeAccount2(value) {
  selected2Acc.value = value;
}

function eventImportDone() {
  searchTrans();
}

// function sortData(field) {
//   appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
// }

function sortDataEvent(eventArgs) {
  console.log(eventArgs);
  appUtils.setSortingParams(
    eventArgs.field,
    dataSorting,
    dataSortingOrder,
    pageStore
  );
}

function toggleRadio(eventArgs) {
  console.log(eventArgs.target.checked);
  radioTrans.value = eventArgs.target.checked;
}

function getSearchObject() {
  console.log('============ getSearchObject ============');
  console.log(searchObjectMaster);

  return searchObjectMaster;
}

function ondatechangehere(eventArgs) {
  console.log('onDateChange');
  console.log(eventArgs);
  selectedDate.value = eventArgs;
}
</script>
