import appUtils from './appUtils.js';

export default {
  getYears() {
    var objYear = {};
    objYear.current = appUtils.getCurrentYear();
    var yearInt = parseInt(objYear.current);
    objYear.previous = (yearInt - 1).toString();
    objYear.next = (yearInt + 1).toString();
    //console.log(objYear);
    return objYear;
  },

  getYearsOffset(offset) {
    var objYear = {};
    var currentYear = appUtils.getCurrentYear();
    var yearInt = parseInt(currentYear) + offset;
    objYear.current = yearInt.toString();
    objYear.previous = (yearInt - 1).toString();
    objYear.next = (yearInt + 1).toString();
    return objYear;
  },

  selectGuidUtil(parArray, guid) {
    if (parArray.includes(guid)) {
      parArray.splice(parArray.indexOf(guid), 1);
    } else {
      parArray.push(guid);
    }
  },

  initializeSums(parObject) {
    for (let j = 1; j < 14; j++) {
      let month = 'x' + ('0' + String(j)).slice(-2);
      parObject[month] = 0;
    }
  },

  initializeArr(parArray, categoryArray) {
    parArray.length = 0;

    for (let i = 0; i < categoryArray.length; i++) {
      let item = {};
      item.guid = categoryArray[i].guid;
      item.guid_parent = categoryArray[i].guid_parent;
      item.description = categoryArray[i].description;
      item.lvl = categoryArray[i].lvl;
      item.type = categoryArray[i].type;

      for (let j = 1; j < 14; j++) {
        let month = 'x' + ('0' + String(j)).slice(-2);
        item[month] = 0;
      }
      parArray.push(item);
    }
  },

  getSumMonths(parArray) {
    //get sum of all months
    for (let j = 0; j < parArray.length; j++) {
      for (let i = 1; i < 13; i++) {
        let month = 'x' + ('0' + String(i)).slice(-2);
        parArray[j].x13 = appUtils.getSumofTwoNumbers(
          parArray[j].x13,
          parArray[j][month]
        );
      }
    }
  },

  prepareAnalysisYearUtil(
    parArray,
    parCategoryArray,
    params,
    lvl = 0,
    arrTotalIncomes = [],
    arrTotalExpences = []
  ) {
    let responseData = params.snapshot;
    const month = appUtils.getMonth(params.yearMonth);

    for (let i = 0; i < parCategoryArray.length; i++) {
      let item = {};
      item.description = parCategoryArray[i].description;

      for (const key in responseData) {
        if (parCategoryArray[i].guid === key) {
          for (let j = 0; j < parArray.length; j++) {
            if (parArray[j].guid === parCategoryArray[i].guid) {
              parArray[j]['x' + month] = responseData[key].balance
                ? responseData[key].balance
                : 0;
            }
          }

          if (lvl === 1 && parCategoryArray[i].type === true) {
            //for lvl1 category we have to count total
            arrTotalIncomes['x' + month] = appUtils.getSumofTwoNumbers(
              arrTotalIncomes['x' + month],
              responseData[key].balance
            );
          }

          if (lvl === 1 && parCategoryArray[i].type === false) {
            //for lvl1 category we have to count total
            arrTotalExpences['x' + month] = appUtils.getSumofTwoNumbers(
              arrTotalExpences['x' + month],
              responseData[key].balance
            );
          }

          break;
        }
      }
    }
  },

  countChildren(arrayParent, arrayChild) {
    for (let i = 0; i < arrayParent.length; i++) {
      if (arrayParent[i].x13 != 0) {
        //we have some balance on category, search for child if they exists and change description
        let counter = 0;
        // console.log(arrayParent[i].description);
        for (let j = 0; j < arrayChild.length; j++) {
          if (
            arrayParent[i].guid === arrayChild[j].guid_parent &&
            arrayChild[j].x13 != 0
          )
            counter++;
        }

        if (counter > 0) {
          arrayParent[i].description =
            arrayParent[i].description + ' [' + String(counter) + ']';
        }
      }
    }
  },

  getYearlyTotals(arrTotalIncomes, arrTotalExpenses, arrTotalSavings) {
    let sumExpenceAll = 0;
    let sumIncomeAll = 0;
    let sumSavingAll = 0;
    for (let i = 1; i < 13; i++) {
      let month = 'x' + ('0' + String(i)).slice(-2);

      arrTotalSavings[month] = appUtils.fixCurrency(
        arrTotalIncomes[month] + arrTotalExpenses[month]
      );

      sumSavingAll += appUtils.fixCurrency(arrTotalSavings[month]);

      sumExpenceAll = appUtils.getSumofTwoNumbers(
        sumExpenceAll,
        arrTotalExpenses[month]
      );

      sumIncomeAll = appUtils.getSumofTwoNumbers(
        sumIncomeAll,
        arrTotalIncomes[month]
      );
    }
    arrTotalIncomes.x13 = sumIncomeAll;
    arrTotalExpenses.x13 = sumExpenceAll;
    arrTotalSavings.x13 = sumSavingAll;
  },

  prepareAnalysisYear(
    params,
    arrDataYearLvl1,
    arrDataYearLvl2,
    arrDataYearLvl3,
    arrDataYearLvl4,
    arrTotalIncomes,
    arrTotalExpenses
  ) {
    if (!params.snapshot) {
      //console.log('!responseData');
      return;
    }

    this.prepareAnalysisYearUtil(
      arrDataYearLvl1,
      appUtils.getCategory(1),
      params,
      1,
      arrTotalIncomes,
      arrTotalExpenses
    );

    if (arrDataYearLvl2) {
      this.prepareAnalysisYearUtil(
        arrDataYearLvl2,
        appUtils.getCategory(2),
        params
      );
    }

    if (arrDataYearLvl3) {
      this.prepareAnalysisYearUtil(
        arrDataYearLvl3,
        appUtils.getCategory(3),
        params
      );
    }
    if (arrDataYearLvl4) {
      this.prepareAnalysisYearUtil(
        arrDataYearLvl4,
        appUtils.getCategory(4),
        params
      );
    }
  },
};
