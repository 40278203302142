<template>
  <div style="margin-left: 50px">
    <fusioncharts
      :type="type"
      :width="width"
      :height="height"
      :dataFormat="dataFormat"
      :dataSource="dataSource"
      @dataPlotClick="onSliceClick"
    ></fusioncharts>

    <br />
    <pre>{{ dataSource }}</pre>
  </div>
</template>

<script>
// import VueFusionCharts from 'vue-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import Charts from 'fusioncharts/fusioncharts.charts';

export default {
  methods: {
    onSliceClick: function (e) {
      //alert('click');
      console.log(e);
      console.log(e.data.categoryLabel);
      var isSliced = e.data.isSliced;
      if (isSliced) {
        this.lastActive = this.radioValue = 'none';
      } else {
        this.lastActive = this.radioValue = e.data.index;
      }
    },
  },

  //enableMultiSlicing: '1',
  //showValues: '1',
  //showlegend: '0',

  data() {
    const myDataSource = {
      chart: {
        caption: 'Recommended Portfolio Split',
        subCaption: 'For a net-worth of $1M',
        numberSuffix: ' EUR',
        showPercentValues: '0',
        showPercentInTooltip: '1',
        showlegend: '0',

        theme: 'candy',
      },
      data: [
        {
          label: 'avto [1]',
          value: '-413.8',
        },
        {
          label: 'banka [5]',
          value: '-11.86',
        },
        {
          label: 'bencin',
          value: '-91.5',
        },
        {
          label: 'darilo [1]',
          value: '-34',
        },
        {
          label: 'drugo [2]',
          value: '-18.7',
        },
        {
          label: 'izredni prih. [1]',
          value: '543.34',
        },
        {
          label: 'malica',
          value: '-29.6',
        },
        {
          label: 'nevem',
          value: '-12.9',
        },
        {
          label: 'Jakob [3]',
          value: '-456.8',
        },
        {
          label: 'pica [2]',
          value: '-49.7',
        },
        {
          label: 'pij [2]',
          value: '-70',
        },
        {
          label: 'placa [1]',
          value: '5350',
        },
        {
          label: 'SiMobil',
          value: '-24.15',
        },
        {
          label: 'stan [6]',
          value: '-174.38',
        },
        {
          label: 'stanKosovo [2]',
          value: '-79.79',
        },
        {
          label: 'stroški [1]',
          value: '3145.26',
        },
        {
          label: 'šport',
          value: '-30',
        },
        {
          label: 'trgovina',
          value: '-238.43',
        },
        {
          label: 'zdravje [1]',
          value: '-149.52',
        },
        {
          label: 'potovanjeKosovo',
          value: '-3',
        },
        {
          label: 'transport [1]',
          value: '-1.2',
        },
        {
          label: 'stanFusheKosova [1]',
          value: '-5',
        },
        {
          label: 'zivljenskoZavarovanje [1]',
          value: '-72.71',
        },
        {
          label: 'poroka',
          value: '-6500',
        },
        {
          label: 'pokojnina',
          value: '-164.43',
        },
      ],
    };

    return {
      type: 'pie2d',
      width: '100%',
      height: '500',
      dataFormat: 'json',
      dataSource: myDataSource,
    };
  },
};
</script>
