<template>
  <tr :id="guid" :class="setclass()">
    <td>
      <button
        class="btn btn-sm bg-warning"
        @click="setSelectedId($event, guid)"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>
    </td>
    <td v-show="showActive == 1">
      <!-- <input
        type="text"
        :value="active"
        @input="$emit('update:active', $event.target.value)"
      /> -->

      <div class="col-sm-8 form-check form-switch">
        <input
          disabled
          @click="toggleRadio($event)"
          class="form-check-input"
          type="checkbox"
          :checked="active"
        />
      </div>
    </td>

    <td :class="setclass()">
      {{ guid }}
    </td>

    <td :class="setclass()">{{ description }}</td>

    <td :class="setclass()">{{ order }}</td>
  </tr>
</template>

<script>
//import appUtils from '../utils/appUtils';

export default {
  props: [
    'guid',
    'description',
    'selectedguid',
    'active',
    'order',
    'showActive',
  ],
  emits: ['select-id', 'update:active', 'updateradio'],

  data() {
    // if (this.$props.active) {
    //   console.log('TRUE');
    // } else {
    //   console.log('FALSE');
    // }

    return { isActive: true };
  },

  methods: {
    // formatCurrency(parNumber) {
    //   return appUtils.formatCurrency(parNumber);
    // },

    toggleRadio(event) {
      this.$emit('updateradio', [event.target.checked, this.active, this.guid]);
    },

    setSelectedId(event, value) {
      this.$emit('select-id', {
        guid: value,
        description: this.description,
        active: this.active,
        order: this.order,
      });
    },

    setclass() {
      {
        return {
          isActivejr: this.selectedguid === this.guid ? true : false,
        };
      }
    },
  },

  computed: {
    getComputed123() {
      return '';
    },
  },
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.75rem;
}

h3 {
  font-size: 1.3rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.3rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
