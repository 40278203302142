<template>
  <!-- style="background-color: aqua" -->
  <a class="jr-link" :href="null" @click="sortDataComp(fieldName)">{{
    fieldTitle
  }}</a>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps(['fieldName', 'fieldTitle']);
const emit = defineEmits(['sortData']);

function sortDataComp() {
  emit('sortData', props.fieldName);
}
</script>
