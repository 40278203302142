<template>
  <thead>
    <tr class="text-warning">
      <th v-show="isEditVisible">Edit</th>
      <th v-show="isCheckboxVisible">
        <input
          id="checkboxMultiAll"
          @click="setSelectedMultiAll($event)"
          class="form-check-input"
          type="checkbox"
        />
      </th>
      <th>
        <base-link
          :fieldName="'date'"
          :fieldTitle="'Date'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th>
        <base-link
          :fieldName="'accDesc'"
          :fieldTitle="'Account'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th class="text-end">
        <base-link
          :fieldName="'amount'"
          :fieldTitle="'Amount'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th>
        <base-link
          :fieldName="'description'"
          :fieldTitle="'Description'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th>
        <base-link
          :fieldName="'kontoDesc'"
          :fieldTitle="'Category'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th>
        <base-link
          :fieldName="'remark1'"
          :fieldTitle="'Remark'"
          @sort-data="sortData"
        ></base-link>
      </th>
      <th v-show="isDeleteVisible">Delete</th>
    </tr>
  </thead>
</template>

<script setup>
//component added 2024-05-23
//components
import BaseLink from '../../components/ui/BaseLink.vue';

//VUE core
import { defineEmits, defineProps, onMounted, computed, ref } from 'vue';
import appUtils from '../utils/appUtils';

const props = defineProps(['configComponent']);
const emit = defineEmits(['sortDataEvent', 'setSelectedMultiAllEvent']);

let configComponent = ref({ editVisible: true });

onMounted(() => {
  console.log('onMounted => TransactionHeader');
  setParameters();
});

function setParameters() {
  appUtils.setParametersTransactionItem(configComponent, props);
}

function sortData(field) {
  emit('sortDataEvent', { field });
}

function setSelectedMultiAll(eventArgs) {
  //  emit('setSelectedMultiAllEvent', { eventArgs });
  emit('setSelectedMultiAllEvent', eventArgs);
}

const isEditVisible = computed(function () {
  return configComponent.value.editVisible;
});

const isCheckboxVisible = computed(function () {
  return configComponent.value.checkboxVisible;
});

const isDeleteVisible = computed(function () {
  return configComponent.value.deleteVisible;
});
</script>
