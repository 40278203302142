<template>
  <!-- <pre>{{ props }}</pre> -->

  <div>
    <input
      @click="setSelectedMultiComp($event, item.guid)"
      class="form-check-input"
      type="checkbox"
      :id="getCheckboxId(item.guid)"
    />
    &nbsp;
    <!-- value="guid" -->

    <label
      class="form-check-label"
      :class="getBgClass(item.type)"
      :for="getCheckboxId(item.guid)"
    >
      &nbsp;
      {{ item.description }}&nbsp;
    </label>
  </div>
</template>

<style scoped>
/* .incomebg {
  background-color: rgb(9, 112, 9);
} */
</style>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['setSelectedMulti']);
defineProps(['item']);

function setSelectedMultiComp(eventArgs, itemGuid) {
  console.log('itemGuid', itemGuid);
  emit('setSelectedMulti', { eventArgs, guidKonto: itemGuid });
}

function getCheckboxId(guid) {
  return 'check' + guid;
}

function getBgClass(guidKontoType) {
  return guidKontoType ? 'incomebg' : '';
}
</script>
