import { child, get, ref } from 'firebase/database';

import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { getAuth } from 'firebase/auth';

//HOW TO USE:
//import { useAccountStore } from '../../stores/AccountStore';
//const accountStore = useAccountStore();

export const useAccountStore = defineStore('AccountStore', {
  state: () => {
    return {
      accounts: [],
      accountsWithGuid: [],
      accountGroups: [],
    };
  },
  getters: {
    hasAccounts() {
      return this.accounts && this.accounts.length > 0;
    },

    getAccountFields() {
      return [
        'id',
        'description',
        'iduser',
        'insdtm',
        'insuser',
        'isdefault',
        'upddtm',
        'upduser',
        'active',
        'order',
      ];
    },
  },

  actions: {
    setSorting(payload) {
      this.accountsWithGuid = appUtils.setSortingUtil(
        payload,
        this.accountsWithGuid
      );
    },

    loadAccountsFirebase(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('loadAccountsFirebase');
          const db = payload.db;
          let userUid = getAuth().currentUser.uid;

          get(child(ref(db), `accounts/${userUid}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                this.accounts = snapshot.val();

                let props = this.getAccountFields;
                let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);
                //this.accountsWithGuid = tmpArray;

                //replace all null values for active field with true
                const updatedData = tmpArray.map((item) => {
                  if (item.active === null) {
                    return { ...item, active: true };
                  } else if (item.active === undefined) {
                    return { ...item, active: true };
                  }
                  return item;
                });

                this.accountsWithGuid = updatedData;
                // console.log('=========== updatedData ===========');
                // console.log(updatedData);
                resolve();
              } else {
                resolve();
                this.accounts = [];
                this.accountsWithGuid = [];
                // console.log('No data available.');
              }
            })
            .catch((error) => {
              reject(error.message);
              throw error;
              // console.error(error);
            });
        } catch (error) {
          reject(error.message);
          throw error;
        }
      });
      return promise;
    },

    loadAccountGroups(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('=== AccountStore => loadAccountGroups ===');
          const db = payload.db;
          let userUid = getAuth().currentUser.uid;

          get(child(ref(db), `settings/${userUid}/accountGroups`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                console.log(snapshot.val());

                let responseData = snapshot.val();
                let tmp = [];
                //let tmp2 = [];

                for (const key in responseData) {
                  // console.log('===================================');
                  // console.log(key);
                  // console.log('===================================');
                  let item = {};
                  item.suma = 0;
                  let accArr = [];
                  //console.log(responseData[key]['description']);
                  for (const key1 in responseData[key]) {
                    if (key1 == 'description' || key1 == 'globalGroup') {
                      // item.description = responseData[key][key1];
                      item[key1] = responseData[key][key1];
                    } else accArr.push(key1);
                    //console.log(key1 + ': ' + responseData[key][key1]);
                  }
                  item.accounts = accArr;
                  item.guid = key;
                  tmp.push(item);

                  // let item2 = appUtils.getObjectCopy(item);
                  // item2.guid = key;
                  // tmp2.push(item2);
                  // console.log('----------------------');
                }
                console.log('--------- accountGroups -------------');
                console.log(tmp);
                console.log('----------------------');
                this.accountGroups = tmp;
                // this.accountGroupsWithGuid = appUtils.getArrayWithGuid(tmp2, [
                //   'guid',
                //   'description',
                //   'globalGroup',
                // ]);

                // console.log(
                //   '============ AccountStore => this.accountGroupsWithGuid ============ '
                // );
                // console.log(this.accountGroupsWithGuid);

                resolve();
              } else {
                console.log('No data available.');
                this.accountGroups = [];
                //this.accountGroupsWithGuid = [];

                resolve();
              }
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          reject(error);
          throw error;
        }
      });
      return promise;
    },

    // saveAccountNew(payload) {
    //   //console.log(payload.length);
    //   console.log(payload);

    //   const firebaseConfig = config.global().firebaseConfig;
    //   const app = initializeApp(firebaseConfig);
    //   const db = getDatabase(app);
    //   const auth = getAuth();
    //   let userUid = auth.currentUser.uid;

    //   for (var i = 0; i < payload.length; i++) {
    //     //if (i < 10) {
    //     let newPostKey = push(child(ref(db), 'accounts')).key;

    //     set(ref(db, 'accounts/' + userUid + '/' + newPostKey), payload[i])
    //       .then(() => {})
    //       .catch((error) => {
    //         throw error;
    //       });
    //     //}
    //   }
    // },
  },
});
