import { defineStore } from 'pinia';
import { loadFromFirebasePinia } from './../components/utils/firebaseUtils';
import appUtils from '../components/utils/appUtils.js';

//import { ref } from 'vue';

//HOW TO USE:
//import { useSettingStore } from '../../stores/SettingStore';
//const settingStore = useSettingStore();

export const useSettingStore = defineStore('SettingStore', {
  state: () => {
    return {
      settings: [],
      currencySymbol: 'EUR',
      globalGroups: [],
    };
  },
  getters: {
    getSettingsFields() {
      return ['id', 'idval'];
    },

    getGlobalGroupsFields() {
      return ['name', 'active'];
    },
  },
  actions: {
    setSorting(payload) {
      this.globalGroups = appUtils.setSortingUtil(payload, this.globalGroups);
    },

    setSettings(payload) {
      this.settings = payload;
      // this.globalGroups = payload.globalGroups;

      //setting the GLOBAL GROUPS
      this.globalGroups = [];

      const data = payload.globalGroups;
      this.globalGroups = appUtils.getArrayWithGuid(
        data,
        this.getGlobalGroupsFields
      );

      // for (const key in payload.globalGroups) {
      //   console.log(`key: ${key}`);
      //   this.globalGroups.push(payload.globalGroups[key]);
      // }

      console.log('SettingStore => globalGroups');
      console.log(this.globalGroups);
    },

    async loadSettings(payload) {
      return loadFromFirebasePinia(payload, 'settings', this.setSettings);
    },

    loadCurrencySymbol() {
      console.log('currencySymbol');
      let tmp = localStorage.getItem('currencySymbol');
      if (tmp) this.currencySymbol = tmp;
      console.log(tmp);
    },
  },
});

// export const useSettingStore = defineStore('SettingStore', () => {
//   let settings = ref([]);
//   const getSettingsFields = ref(['id', 'idval']);
//   // const getSettingsFields = computed(() => {
//   //   return ['id', 'idval'];
//   // });

//   function setSettings(payload) {
//     settings = payload;
//   }

//   async function loadSettings(payload) {
//     console.log('useSettingStore.loadSettings => payload');
//     console.log(payload);
//     return loadFromFirebasePinia(payload, 'settings', setSettings);
//   }

//   return { settings, getSettingsFields, setSettings, loadSettings };

// });
