<template>
  <div class="card bg-dark text-warning">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.8rem;
  margin: 1rem auto;
  /* max-width: 40rem; */
  max-width: 100%;
}
</style>
