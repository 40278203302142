<template>
  <div class="accordion accordion-flush" id="accordionExample">
    <div class="accordion-item bg-dark">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed bg-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <h4 class="text-warning mt-1">Import</h4>
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="row mb-2">
            <div class="col-2">
              <base-button
                type="button"
                class="form-control"
                @click="subImport()"
                >Import</base-button
              >
            </div>
            <div class="col-3">
              <!-- class="form-control" -->
              <account-dropdown
                :modelValue="selectedAcc"
                @onchange="onChangeAccount"
              ></account-dropdown>
            </div>
          </div>

          <div class="col-3 align-items-start mb-2"></div>

          <textarea
            id="importarea"
            v-model="importText"
            style="width: 100%; height: 300px"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { ref, defineEmits, onMounted } from 'vue';
import appUtils from '../utils/appUtils';
import { saveTransactionAtomic } from '../../pages/transactions/utilsTransactions.js';
import accountDropdown from '../accounts/accountDropdown.vue';
import { useRulesStore } from '../../stores/RulesStore';

const emit = defineEmits(['importdone']);

let importText = ref('');
let selectedAcc = ref('0');
let cashAcc = ref('0');

onMounted(() => {
  cashAcc.value = appUtils.getSettings('appSettings', 'cashAcc1');
});

function onChangeAccount(value) {
  selectedAcc.value = value;
}

function getImportDatetime() {
  return 'IMPORT ' + moment().format('YYYY.MM.DD HH:mm:ss');
}

async function subImport() {
  if (selectedAcc.value == '0') return;

  let selectedAccObj = appUtils.getGuidAccountObj(selectedAcc.value);
  if (selectedAccObj.description == 'Nova KBM') {
    subImportNkbm()
      .then(() => emit('importdone'))
      .catch((error) => {
        throw error.message;
      });
  }

  if (selectedAccObj.description == 'ProCredit') {
    await subProCredit()
      .then(() => emit('importdone'))
      .catch((error) => {
        throw error.message;
      });
  }

  if (selectedAccObj.description == 'AC/EU') {
    subAceu()
      .then(() => emit('importdone'))
      .catch((error) => {
        throw error.message;
      });
  }
}

function ruleFoundFillData(transObj, rule) {
  //console.log('ruleFoundFillData');
  transObj.guidknt1 = rule.guidknt1 ? rule.guidknt1 : null;
  transObj.guidknt2 = rule.guidknt2 ? rule.guidknt2 : null;
  transObj.guidknt3 = rule.guidknt3 ? rule.guidknt3 : null;
  transObj.guidknt4 = rule.guidknt4 ? rule.guidknt4 : null;
}

function checkRules(transObj) {
  const rulesStore = useRulesStore();
  const tmpRules = rulesStore.rulesGuid;

  for (let i = 0; i < tmpRules.length; i++) {
    //console.log(transObj);
    //console.log(tmpRules[i]);

    const text1 = tmpRules[i].text1 ? tmpRules[i].text1 : '';
    let text2 = tmpRules[i].text2;
    let text3 = tmpRules[i].text3;

    const operator = tmpRules[i].operator.toLowerCase();
    const fieldName = tmpRules[i].field.toLowerCase();

    let found = false;

    if (
      tmpRules[i].guidaccount == transObj.guidaccount &&
      (fieldName == 'description' || fieldName == 'remark2')
    ) {
      if (
        (operator == 'includes' &&
          transObj[fieldName].toLowerCase().includes(text1.toLowerCase())) ||
        (operator == 'equals' &&
          transObj[fieldName].toLowerCase() == text1.toLowerCase())
      )
        found = true;

      if (found) {
        if (text2 && text3) {
          //if we have also text2 and text3 - amount have to be between these 2 limits
          if (
            // Math.abs(transObj.amount) >= Math.abs(Number(text2)) &&
            // Math.abs(transObj.amount) <= Math.abs(Number(text3))
            transObj.amount >= Number(text2) &&
            transObj.amount <= Number(text3)
          ) {
            ruleFoundFillData(transObj, tmpRules[i]);
          }
        } else {
          ruleFoundFillData(transObj, tmpRules[i]);
        }
      }
    }
  }
}

async function subProCredit() {
  try {
    var lines = importText.value.split('\n');
    var tmpRemark = getImportDatetime();

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') {
        var tmp = appUtils.getTmp(lines[i]);
        if (tmp.length > 0) {
          var tmpDesc = '';
          var tmpAmount = '' + tmp[4];

          var tmpDebitCredit = tmp[3];
          if (tmpDebitCredit === 'D') {
            tmpAmount = '-' + tmp[4];
          } /* C=credit, D=debit */
          tmpAmount = tmpAmount.replace(',', '');

          tmpDesc = tmp[7].trim();
          if (tmpDesc === '') {
            tmpDesc = tmp[6];
          }

          var transObj = {
            date: moment(moment(tmp[2], 'DD.MM.YYYY')).format('YYYY-MM-DD'),
            termin: moment(moment(tmp[2], 'DD.MM.YYYY')).format('YYYY-MM'),
            year: moment(moment(tmp[2], 'DD.MM.YYYY')).format('YYYY'),
            amount: Number(tmpAmount),
            guidaccount: selectedAcc.value,
            description: tmpDesc ? tmpDesc : null,
            remark1: tmpRemark ? tmpRemark : null,
            remark2: tmp[11] ? tmp[11] : null,
            remark3: tmp[12] ? tmp[12] : null,
            search: tmpDesc ? tmpDesc.toLowerCase() : null,
          };

          checkRules(transObj);

          var transObj1 = {};
          if (
            tmp[6] ===
            'Tërheqje e parave nga bankomati Tërheqje në para të gatshme'
          ) {
            /* ATM withdrawal */
            if (cashAcc.value != '0') {
              Object.assign(transObj1, transObj);
              tmpAmount = tmpAmount.replace('-', '');
              transObj1.amount = Number(tmpAmount);
              transObj1.guidaccount = cashAcc.value;
            }
          }
        }

        await saveAtomicImport(transObj);
        // promises.push(saveAtomicImport(transObj));
        if (appUtils.isEmptyObj(transObj1) == false) {
          await saveAtomicImport(transObj1);
        }
      }
    }
  } catch (error) {
    console.log(error);
    //throw error;
  }
}

async function subImportNkbm() {
  try {
    var lines = importText.value.split('\n');
    var tmpRemark = getImportDatetime();

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') {
        var tmp = lines[i].split(';');
        if (tmp.length > 0) {
          var tmpAmount = '';
          var tmpDesc = '';

          if (tmp[5] != '0,00' && tmp[5] != '') {
            tmpAmount = '' + tmp[5];
          }
          if (tmp[6] != '0,00' && tmp[6] != '') {
            tmpAmount = '-' + tmp[6];
          }

          tmpAmount = tmpAmount.replace('.', '');
          tmpAmount = tmpAmount.replace(',', '.');
          tmpDesc = tmp[8].trim();
          let tmpDescReplace = tmpDesc.replaceAll('È', 'Č');

          var transObj = {
            date: moment(moment(tmp[4], 'DD-MM-YYYY')).format('YYYY-MM-DD'),
            termin: moment(moment(tmp[4], 'DD-MM-YYYY')).format('YYYY-MM'),
            year: moment(moment(tmp[4], 'DD-MM-YYYY')).format('YYYY'),
            amount: Number(tmpAmount),
            guidaccount: selectedAcc.value,
            description: tmpDesc,
            remark1: tmpRemark,
            remark2: tmp[11],
            remark3: tmp[12],
            search: tmpDesc.toLowerCase(),
          };

          checkRules(transObj);
          transObj.description = tmpDescReplace;

          // appUtils.conlog(tblmainobj);
          await saveAtomicImport(transObj);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

async function subAceu() {
  try {
    var lines = importText.value.split('\n');
    var tmpRemark = getImportDatetime();
    var tmpAmount = '';

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') {
        var tmp = lines[i].split(';');
        if (tmp.length > 0) {
          tmp[5] = tmp[5].replace('−', '-');
          tmp[5] = tmp[5].replace('.', '');

          if (tmp[5] != '') {
            if (tmp[5].substring(0, 1) === '-') {
              tmpAmount = tmp[5].substring(1, tmp[5].length - 1);
            } else {
              tmpAmount = '-' + tmp[5];
            }
          }
          tmpAmount = tmpAmount.replace(',', '.');
          var tmpDesc = tmp[7].trim();
          let tmpDescReplace = tmpDesc.replaceAll('È', 'Č');

          var transObj = {
            date: moment(moment(tmp[3], 'DD.MM.YYYY')).format('YYYY-MM-DD'),
            termin: moment(moment(tmp[3], 'DD.MM.YYYY')).format('YYYY-MM'),
            year: moment(moment(tmp[3], 'DD.MM.YYYY')).format('YYYY'),
            amount: Number(tmpAmount),
            guidaccount: selectedAcc.value,
            description: tmpDesc ? tmpDesc : null,
            remark1: tmpRemark ? tmpRemark : null,
            remark2: lines[i],
            search: tmpDesc ? tmpDesc.toLowerCase() : null,
          };

          checkRules(transObj);
          transObj.description = tmpDescReplace;
        }

        await saveAtomicImport(transObj);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

async function saveAtomicImport(transNew) {
  let promise = new Promise(function (resolve, reject) {
    try {
      setTimeout(() => {
        saveTransactionAtomic({
          selectedTransGuid: null,
          new: transNew,
          old: null,
        })
          .then(() => {
            resolve();
          })
          .catch((error) => console.log(error));
      }, 1);
    } catch (error) {
      reject(new Error(error.message));
    }
  });
  return promise;
}
</script>
