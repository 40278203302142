import {
  child,
  get,
  getDatabase,
  push,
  ref as reffb,
  update,
} from 'firebase/database';

import _this from './utilsTransactions.js';
import appUtils from '../../components/utils/appUtils.js';
import config from '../../store/global.js';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useTransStore } from '../../stores/TransStore';

export default {
  addBalanceKonto(db, updates, guidKonto, termin, newBalance) {
    let promise = new Promise(function (resolve, reject) {
      try {
        //let newBalance = data.balance;
        let userUid = getAuth().currentUser.uid;

        if (guidKonto) {
          const url = `balance_konto/${userUid}/${termin}/${guidKonto}`;

          get(child(reffb(db), url))
            .then((snapshot) => {
              if (snapshot.exists()) {
                newBalance += snapshot.val().balance;
                newBalance = newBalance.toFixed(2);
                newBalance = Number(newBalance);
              }

              updates[url] = {
                balance: newBalance,
                description: appUtils.getGuidKontoObj(guidKonto).description,
                guidKonto: guidKonto,
              };

              resolve({ url, new_balance: newBalance });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },

  addBalanceAccount(db, updates, pDataDescription, amount, url) {
    // console.log(' ================= addBalanceAccount ========== ');
    // console.log(pDataDescription);
    // console.log(amount);
    // console.log(url);

    let promise = new Promise(function (resolve, reject) {
      try {
        let data = { balance: 0, description: pDataDescription };
        amount = parseFloat(amount);

        get(child(reffb(db), url))
          .then((snapshot) => {
            if (snapshot.exists()) {
              data.balance = snapshot.val().balance + amount;
              //data.description = snapshot.val().description;
            } else {
              data.balance = amount;
              data.description = pDataDescription;
            }
            data.balance = Number(data.balance);
            data.balance =
              Math.round((data.balance + Number.EPSILON) * 100) / 100;

            updates[url] = data;

            resolve({ url, new_balance: data.balance });
          })
          .catch((error) => {
            appUtils.conlog(error);
          });
      } catch (error) {
        reject(new Error(error.message));
        console.log(error);
      }
    });

    return promise;
  },

  //-------------------------------------------------------------------------------
  addBalanceKontoUpdate(db, updates, pData, amount, url) {
    let promise = new Promise(function (resolve, reject) {
      try {
        amount = parseFloat(amount);
        let data = {
          balance: 0,
          description: appUtils.getGuidKontoObj(pData.guidKonto).description,
          guidKonto: pData.guidKonto,
        };

        get(child(reffb(db), url))
          .then((snapshot) => {
            if (snapshot.exists()) {
              data.balance = appUtils.getSumofTwoNumbers(
                snapshot.val().balance,
                amount
              );
            } else {
              data.balance = amount;
            }
            data.balance = Number(data.balance);

            if (data.balance == 0) {
              //in case balance on konto is 0 we remove record
              updates[url] = null;
            } else {
              updates[url] = data;
            }

            resolve({ url, new_balance: data.balance });
          })
          .catch((error) => {
            appUtils.conlog(error);
          });
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },

  changeBalanceKonto(
    db,
    updates,
    // kontoOldObj,
    // kontoNewObj,
    dataOld,
    dataNew,
    fieldName
  ) {
    const kontoOldObj = {
      guidKonto: dataOld[fieldName] ? dataOld[fieldName] : {},
    };
    const kontoNewObj = {
      guidKonto: dataNew[fieldName] ? dataNew[fieldName] : {},
    };

    let kontoSelected = dataOld[fieldName];
    let kontoCurrent = dataNew[fieldName];
    let initamount = dataOld.amount;
    let amount = dataNew.amount;
    let termin = dataNew.termin;
    let initTermin = dataOld.termin;
    let userUid = getAuth().currentUser.uid;

    // appUtils.conlog('*******************');
    // appUtils.conlog(kontoOldObj);
    // appUtils.conlog(kontoNewObj);
    // appUtils.conlog(kontoSelected);
    // appUtils.conlog(kontoCurrent);
    // appUtils.conlog(initamount);
    // appUtils.conlog(amount);
    // appUtils.conlog(termin);
    // appUtils.conlog(initTermin);
    // appUtils.conlog('*******************');

    let promise = new Promise(function (resolve, reject) {
      try {
        if (!kontoSelected || typeof kontoSelected === 'undefined') {
          kontoSelected = '';
        }
        if (!kontoCurrent || typeof kontoCurrent === 'undefined') {
          kontoCurrent = '';
        }

        let promise1 = null;
        let promise2 = null;
        let promise3 = null;

        let url = '';
        if (kontoSelected != kontoCurrent || termin != initTermin) {
          if (kontoSelected !== '') {
            url = `balance_konto/${userUid}/${initTermin}/${kontoOldObj.guidKonto}`;

            // console.log('url 1');
            // console.log(url);
            // console.log('');

            promise1 = _this.addBalanceKontoUpdate(
              db,
              updates,
              kontoOldObj,
              -1 * initamount,
              url
            );
          }

          if (kontoCurrent != 0 && kontoCurrent != '') {
            url = `balance_konto/${userUid}/${termin}/${kontoNewObj.guidKonto}`;

            // console.log('url 2');
            // console.log(url);
            // console.log('');

            promise2 = _this.addBalanceKontoUpdate(
              db,
              updates,
              kontoNewObj,
              amount,
              url
            );
          }
        }

        if (
          kontoSelected === kontoCurrent &&
          amount != initamount &&
          termin === initTermin
        ) {
          if (kontoCurrent != 0 && kontoCurrent != '') {
            url = `balance_konto/${userUid}/${termin}/${kontoNewObj.guidKonto}`;

            // console.log('url 3');
            // console.log(url);
            // console.log('');

            let tmpAmount = amount - initamount;
            if (Math.abs(tmpAmount) < 0.00001) {
              tmpAmount = 0;
              console.log('rounded to 0');
              console.log('amount', amount);
              console.log('initamount', initamount);
            }

            promise3 = _this.addBalanceKontoUpdate(
              db,
              updates,
              kontoNewObj,
              tmpAmount,
              url
            );
          }
        }

        Promise.all([promise1, promise2, promise3])
          .then((values) => {
            resolve(values);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },
};

export async function saveTransactionAtomic(data) {
  data.action = 'update';
  if (data.old == null) {
    //console.log('data.action = insert');
    data.action = 'insert';
  }

  console.log('saveTransactionAtomic => data');
  console.log(data);

  let promise = new Promise(function (resolve, reject) {
    try {
      //appUtils.conlog('saveTransactionAtomic');

      if (!data.new.amount) data.new.amount = 0;

      if (!data.new.year) {
        if (typeof data.new.date === 'string') {
          data.new.year = data.new.date.substring(0, 4);
        } else {
          data.new.year = String(data.new.date.getFullYear());
        }
      }
      if (!data.new.termin) {
        //console.log('termin is null!!!!');

        if (typeof data.new.date === 'string') {
          //console.log('termin is null!!!!  typeof data.new.date === string');
          data.new.termin = data.new.date.substring(0, 7);
        } else {
          //console.log('termin is null!!!!  else');
          const yyyy = String(data.new.date.getFullYear());
          let mm = String(data.new.date.getMonth() + 1).padStart(2, '0'); // Months start at 0!
          data.new.termin = yyyy + '-' + mm;
        }
        // console.log(data.new.termin);
      }

      if (!data.new.search) {
        data.new.search = data.new.description
          ? data.new.description.toLowerCase()
          : null;
      }

      const firebaseConfig = config.global().firebaseConfig;
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);
      const auth = getAuth();
      let userUid = auth.currentUser.uid; //'nUvZEZYT4qftJdJ9PBB3sxutoM03';
      let newPostKey = null;

      // auth.currentUser
      //   .getIdToken()
      //   .then((idToken) => {
      //     if (localStorage.getItem('token') != idToken) {
      //       throw new Error('Invalid token provided: ');
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('Error getting ID token:', error);
      //   });

      //let amoutDifference = parseFloat(data.new.amount);
      if (data.action == 'update') {
        //existing transaction
        newPostKey = data.old.guid; // data.selectedTransGuid;
      } else {
        newPostKey = push(child(reffb(db), 'transactions')).key;
      }

      const updates = {};

      let url = '';
      let prom10 = null;
      let prom11 = null;
      let prom12 = null;
      let prom13 = null;

      //------------------------------------------
      // -- dealing with CATEGORY insert/update --
      //------------------------------------------
      if (data.action === 'insert') {
        if (data.new.guidknt1) {
          prom10 = _this.addBalanceKonto(
            db,
            updates,
            data.new.guidknt1,
            data.new.termin,
            parseFloat(data.new.amount)
          );
        }
        if (data.new.guidknt2) {
          prom11 = _this.addBalanceKonto(
            db,
            updates,
            data.new.guidknt2,
            data.new.termin,
            parseFloat(data.new.amount)
          );
        }
        if (data.new.guidknt3) {
          prom12 = _this.addBalanceKonto(
            db,
            updates,
            data.new.guidknt3,
            data.new.termin,
            parseFloat(data.new.amount)
          );
        }
        if (data.new.guidknt4) {
          prom13 = _this.addBalanceKonto(
            db,
            updates,
            data.new.guidknt4,
            data.new.termin,
            parseFloat(data.new.amount)
          );
        }
      }
      let prom1 = null;
      let prom2 = null;
      let prom3 = null;
      let prom4 = null;
      if (data.action === 'update') {
        prom1 = _this.changeBalanceKonto(
          db,
          updates,
          data.old,
          data.new,
          'guidknt1'
        );
        prom2 = _this.changeBalanceKonto(
          db,
          updates,
          data.old,
          data.new,
          'guidknt2'
        );
        prom3 = _this.changeBalanceKonto(
          db,
          updates,
          data.old,
          data.new,
          'guidknt3'
        );
        prom4 = _this.changeBalanceKonto(
          db,
          updates,
          data.old,
          data.new,
          'guidknt4'
        );
      }

      //-------------------------------------------------
      // -- dealing with ACCOUNT balance insert/update --
      //-------------------------------------------------
      url = `balance_acc/${userUid}/${data.new.guidaccount}`;
      let prom5 = null;
      let prom6 = null;
      let prom7 = null;
      let prom8 = null;
      if (data.action === 'insert') {
        if (data.new.guidaccount) {
          let accDesc = appUtils.getGuidAccountObj(
            data.new.guidaccount
          ).description;

          prom5 = _this.addBalanceAccount(
            db,
            updates,
            accDesc,
            data.new.amount,
            url
          );
        }
      } else if (data.action === 'update') {
        if (data.new.guidaccount === data.old.guidaccount) {
          if (data.new.guidaccount && data.new.amount != data.old.amount) {
            let accDesc = appUtils.getGuidAccountObj(
              data.new.guidaccount
            ).description;

            prom6 = _this.addBalanceAccount(
              db,
              updates,
              accDesc,
              data.new.amount - data.old.amount,
              url
            );
          }
        }
        if (data.new.guidaccount != data.old.guidaccount) {
          if (data.old.guidaccount) {
            let accDesc = appUtils.getGuidAccountObj(
              data.old.guidaccount
            ).description;

            url = `balance_acc/${userUid}/${data.old.guidaccount}`;
            prom7 = _this.addBalanceAccount(
              db,
              updates,
              accDesc,
              -1 * data.new.amount,
              url
            );
          }
          if (data.new.guidaccount) {
            url = `balance_acc/${userUid}/${data.new.guidaccount}`;

            let accDesc = appUtils.getGuidAccountObj(
              data.new.guidaccount
            ).description;

            prom8 = _this.addBalanceAccount(
              db,
              updates,
              accDesc,
              data.new.amount,
              url
            );
          }
        }
      }

      //--------------------------------------------
      //-- dealing with TRANSACTION insert/update --
      //--------------------------------------------
      let year = data.new.termin.substring(0, 4);

      //if we change year we have to move transaction to other 'year' node
      if (data.action === 'update') {
        let initYear = data.old.termin.substring(0, 4);
        if (year != initYear) {
          updates[
            'transactions/' + userUid + '/' + initYear + '/' + newPostKey
          ] = null;
          //appUtils.conlog('data.action === update && year != initYear');
        }
      }

      updates['transactions/' + userUid + '/' + year + '/' + newPostKey] =
        data.new;

      Promise.all([
        prom1,
        prom2,
        prom3,
        prom4,
        prom5,
        prom6,
        prom7,
        prom8,
        prom10,
        prom11,
        prom12,
        prom13,
      ])
        .then(() => {
          //appUtils.conlog(values);
          update(reffb(db), updates).then(() => {
            resolve({ guid: newPostKey });
            // searchTrans();
          });
        })
        .catch((error) => {
          //error.value = error.message;
          //appUtils.conlog(error);
          reject(new Error(error.message));
        });
    } catch (error) {
      //appUtils.conlog(error);
      reject(new Error(error.message));
    }
  });
  return promise;
}

export function getFiltering(searchObject, tmpArray) {
  if (searchObject) {
    //termin
    if (searchObject.searchTermin && searchObject.searchTermin.length == 7) {
      tmpArray = appUtils.getFilterTrans(
        tmpArray,
        searchObject.searchTermin,
        'termin'
      );
    }

    tmpArray = appUtils.getFilterTrans(
      tmpArray,
      searchObject.searchKonto1,
      'guidknt1'
    );

    tmpArray = appUtils.getFilterTrans(
      tmpArray,
      searchObject.searchKonto2,
      'guidknt2'
    );

    tmpArray = appUtils.getFilterTrans(
      tmpArray,
      searchObject.searchKonto3,
      'guidknt3'
    );

    tmpArray = appUtils.getFilterTrans(
      tmpArray,
      searchObject.searchKonto4,
      'guidknt4'
    );

    tmpArray = appUtils.getFilterTrans(
      tmpArray,
      searchObject.searchAccount,
      'guidaccount'
    );

    //getFilterTrans(tmpArray, searchObjectField, arrayObjectField)
    // tmpArray = tmpArray.filter(
    //   (el) => el[arrayObjectField] === searchObjectField
    // );

    if (searchObject.searchDesc) {
      tmpArray = tmpArray.filter((el) => {
        if (el.search) {
          return el.search.includes(searchObject.searchDesc.toLowerCase());
        } else return false;
      });
    }
  }
  return tmpArray;
}

export async function savePeriodicTransaction(data) {
  data.action = 'update';
  if (data.old == null) {
    data.action = 'insert';
  }

  let promise = new Promise(function (resolve, reject) {
    try {
      appUtils.conlog('savePeriodicTransaction');

      if (!data.new.amount) data.new.amount = 0;
      const firebaseConfig = config.global().firebaseConfig;

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);
      const auth = getAuth();
      let userUid = auth.currentUser.uid;
      let guidKey = null;

      //let amoutDifference = parseFloat(data.new.amount);
      if (data.action == 'update') {
        //existing ittem
        guidKey = data.old.guid;
        //amoutDifference = parseFloat(data.new.amount - data.old.amount);
      } else {
        guidKey = push(child(reffb(db), 'periodic')).key;
      }

      const updates = {};

      //--------------------------------------------
      //-- dealing with PERIODIC trans. insert/update --
      //--------------------------------------------

      updates['periodic/' + userUid + '/' + guidKey] = data.new;

      update(reffb(db), updates).then(() => {
        resolve({ guid: guidKey });
      });
    } catch (error) {
      //appUtils.conlog(error);
      reject(new Error(error.message));
    }
  });
  return promise;
}

export function getGuidLastLevelKonto(guid1, guid2, guid3, guid4) {
  if (guid1 == '0') guid1 = 0;
  if (guid2 == '0') guid2 = 0;
  if (guid3 == '0') guid3 = 0;
  if (guid4 == '0') guid4 = 0;

  let guidLast = guid1 ? guid1 : null;
  guidLast = guid2 ? guid2 : guidLast;
  guidLast = guid3 ? guid3 : guidLast;
  guidLast = guid4 ? guid4 : guidLast;
  return guidLast;
}

export function onChangeCategory(
  categoryGuid,
  level,
  catGuid1,
  catGuid2,
  catGuid3,
  catGuid4
) {
  if (catGuid1 == '0') catGuid1 = 0;
  if (catGuid2 == '0') catGuid2 = 0;
  if (catGuid3 == '0') catGuid3 = 0;
  if (catGuid4 == '0') catGuid4 = 0;

  // console.log('onChangeCategory');
  // console.log(categoryGuid);

  if (level === 1) {
    catGuid1.value = categoryGuid;
    catGuid2.value = 0;
    catGuid3.value = 0;
    catGuid4.value = 0;
  } else if (level === 2) {
    catGuid2.value = categoryGuid;
    catGuid3.value = 0;
    catGuid4.value = 0;
  } else if (level === 3) {
    catGuid3.value = categoryGuid;
    catGuid4.value = 0;
  } else if (level === 4) {
    catGuid4.value = categoryGuid;
  }
}

export function searchTransDecade(searchObject, yearArr = null) {
  console.log(searchObject);
  console.log(yearArr);

  const db = getDatabase();

  const transStore = useTransStore();
  transStore.setTransactionsEmpty();

  let promises = [];
  for (let i = 0; i < yearArr.length; i++) {
    let year = String(yearArr[i]);
    promises.push(
      transStore.getTransDecade({
        db,
        year: year,
        searchObj: searchObject,
      })
    );
  }

  Promise.all(promises).then((values) => {
    values.forEach((snapshot) => {
      console.log(snapshot);
      transStore.rearrangeTrans([snapshot.val(), searchObject, true]);
    });
    // console.log(values);
  });
}

export function getInitialSearchObject(guidKonto, searchTermin) {
  //searchTermin: searchTermin.value == '' ? null : searchTermin.value,

  return {
    filterName: '',
    searchDesc: null,
    searchTermin: searchTermin == '' ? null : searchTermin,
    searchKonto1: guidKonto,
    searchKonto2: null,
    searchKonto3: null,
    searchKonto4: null,
    searchAccount: null,
    searchYear1: null,
    searchYear2: null,
  };
}

export function getCatDescLong(kontoObj1, kontoObj2, kontoObj3, kontoObj4) {
  let ret = '';
  if (arguments.length > 3) ret += kontoObj4.description + ' / ';
  if (arguments.length > 2) ret += kontoObj3.description + ' / ';
  if (arguments.length > 1) ret += kontoObj2.description + ' / ';
  if (arguments.length > 0) ret += kontoObj1.description;
  return ret;
}

export function fastFilterTrans(tmp, searchText) {
  const fieldArr = ['description', 'accDesc', 'kontoDesc', 'remark1'];

  if (searchText.length <= 2) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}

export function fixSearchObj(guidKonto, lvl, searchObject, categoryDescLong) {
  //here lvl is drillevel so is actually for 1 higher than real level

  let catObj1; //category object
  let catObj2;
  let catObj3;
  let catObj4;

  if (lvl > 2) {
    catObj1 = appUtils.getGuidKontoObj(guidKonto);
    catObj2 = appUtils.getGuidKontoObj(catObj1.guid_parent);

    searchObject.searchKonto1 = catObj1.guid_parent;
    searchObject.searchKonto2 = guidKonto;
  }
  if (lvl > 3) {
    catObj3 = appUtils.getGuidKontoObj(catObj2.guid_parent);

    searchObject.searchKonto1 = catObj2.guid_parent;
    searchObject.searchKonto2 = catObj1.guid_parent;
    searchObject.searchKonto3 = guidKonto;
  }
  if (lvl > 4) {
    catObj4 = appUtils.getGuidKontoObj(catObj3.guid_parent);

    searchObject.searchKonto1 = catObj3.guid_parent;
    searchObject.searchKonto2 = catObj2.guid_parent;
    searchObject.searchKonto3 = catObj1.guid_parent;
    searchObject.searchKonto4 = guidKonto;
  }

  if (lvl == 2) {
    let guidKontoObj = appUtils.getGuidKontoObj(guidKonto);
    categoryDescLong.value = getCatDescLong(guidKontoObj);
  } else if (lvl == 3) {
    categoryDescLong.value = getCatDescLong(catObj1, catObj2);
  } else if (lvl == 4) {
    categoryDescLong.value = getCatDescLong(catObj1, catObj2, catObj3);
  } else if (lvl == 5) {
    categoryDescLong.value = getCatDescLong(catObj1, catObj2, catObj3, catObj4);
  }
}
