import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { loadFromFirebasePinia } from '../components/utils/firebaseUtils';

//HOW TO USE:
//import { usePeriodicStore } from '../../stores/PeriodicStore';
//const periodicStore = usePeriodicStore();

export const usePeriodicStore = defineStore('PeriodicStore', {
  state: () => {
    return {
      periodic: [],
      periodicGuid: [],
    };
  },
  getters: {
    getPeriodicFields() {
      return [
        'description',
        'amount',
        'guidaccount',
        'guidknt1',
        'guidknt2',
        'guidknt3',
        'guidknt4',
        'remark1',
        'orderPeriodic',
      ];
    },
  },
  actions: {
    setSorting(payload) {
      this.periodicGuid = appUtils.setSortingUtil(payload, this.periodicGuid);
    },

    setPeriodic(payload) {
      this.periodic = payload;
    },

    loadPeriodic(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          loadFromFirebasePinia(payload, 'periodic', this.setPeriodic).then(
            (snapshot) => {
              if (snapshot) {
                console.log('loadPeriodic.snapshot');
                console.log(snapshot.val());

                let props = this.getPeriodicFields;
                let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);

                for (let i = 0; i < tmpArray.length; i++) {
                  if (tmpArray[i].guidaccount) {
                    tmpArray[i]['accDesc'] = appUtils.getGuidAccountObj(
                      tmpArray[i].guidaccount
                    ).description;
                  } else {
                    tmpArray[i]['accDesc'] = null;
                  }

                  if (tmpArray[i].guidknt1) {
                    tmpArray[i]['kontoDesc'] = appUtils.getKontoDescFull(
                      tmpArray[i].guidknt1,
                      tmpArray[i].guidknt2,
                      tmpArray[i].guidknt3,
                      tmpArray[i].guidknt4
                    );
                  } else {
                    tmpArray[i]['kontoDesc'] = null;
                  }
                }

                // console.log('tmpArray');
                // console.log(tmpArray);
                this.periodicGuid = tmpArray;

                resolve();
              } else {
                this.periodicGuid = [];
                resolve();
              }
            }
          );
        } catch (error) {
          console.log(error);

          reject(error);
          throw error;
        }
      });

      return promise;
    },
  },
});
