<template>
  <div class="text-light">
    <!-- <pre class="text-warning">{{ getAllAccounts }}</pre> -->

    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>

    <!-- <base-button @click="importFromJson">Import JSON</base-button> -->

    <div class="text-warning mt-2 mb-3">
      <h4>Accounts</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <base-card>
      <li>
        <base-text-search v-model="searchText"></base-text-search>
      </li>
    </base-card>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- radio -->

              <div class="row mb-3">
                <!-- <label
                  class="col-sm-3 form-check-label fw-normal text-warning"
                  for="radioactive"
                  >Active</label
                > -->

                <label
                  for="radioactive"
                  class="col-sm-3 form-check-label text-end"
                >
                  <span>&nbsp;&nbsp;&nbsp;Active</span>
                </label>

                <div class="col-sm-8 ms-3 form-check form-switch">
                  <input
                    @click="toggleRadio"
                    class="form-check-input"
                    type="checkbox"
                    id="radioactive"
                    v-model="activeModal"
                  />
                </div>

                <!-- <label
                  class="col-sm-4 form-check-label text-end fw-normal text-warning"
                  for="radioTransaction"
                  >Active</label
                >
                <div class="col-sm-8 form-check form-switch">
                  <input
                    @click="toggleRadio()"
                    class="form-check-input"
                    type="checkbox"
                    id="radioTransaction"
                    v-model="active"
                  />
                </div> -->
              </div>

              <!-- Description -->
              <div class="row mb-1 mt-4">
                <label
                  for="description"
                  class="col-sm-3 col-form-label text-end"
                  >Description
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="description"
                    v-model.trim="description"
                  />
                </div>
              </div>

              <!-- Order -->
              <div class="row mb-3 mt-1">
                <label for="order" class="col-sm-3 col-form-label text-end"
                  >Order
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="number"
                    id="order"
                    v-model.trim="order"
                  />
                </div>
              </div>

              <div class="float-end mb-2">
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="saveAccounts()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <!-- <section>
      <base-card>
        <p>AccountList.vue</p>
        <base-button @click="saveAccounts">Accounts IMPORT</base-button>
      </base-card>
    </section> -->

    <base-card class="bg-dark text-light">
      <table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>
            <th>
              <a class="jr-link" :href="null" @click="sortData('active')"
                >Active</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('guid')">Guid</a>
            </th>
            <th>
              <a class="jr-link" :href="null" @click="sortData('description')"
                >Description</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('order')"
                >Order</a
              >
            </th>
          </tr>
        </thead>

        <tbody>
          <account-item
            @select-id="selectId"
            @updateradio="updateRadio"
            v-for="item in getAllAccounts"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :description="item.description"
            :selectedguid="getSelectedGuid"
            :order="item.order"
            :show-active="'1'"
            v-model:active="item.active"
          ></account-item>
        </tbody>
      </table>
    </base-card>
  </div>
</template>

<script setup>
// import getData from './accountData.js';
import { useAccountStore } from '../../stores/AccountStore';
import AccountItem from '../../components/accounts/AccountItem.vue';
import { ref, computed, reactive, onMounted } from 'vue';
import {
  loadFromFirebasePinia,
  saveToFirebaseWithOptions,
} from '../../components/utils/firebaseUtils';
import appUtils from '../../components/utils/appUtils';

const pageStore = useAccountStore();

//const isLoading = ref(false);
//const selectedItem = toRef({});
const error = ref(null);
const description = ref('');
const order = ref(0);
const activeModal = ref(true);
const selectedguid = ref('');
const modalTransactionCaption = ref('Add account');
const searchText = ref(null);
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const allAccounts = reactive([]);

onMounted(() => {
  console.log('mounted');
  allAccounts.value = pageStore.accountsWithGuid;
  //console.log(allAccounts.value);
  sortData('order');
});

const getAllAccounts = computed(function () {
  let tmp = pageStore.accountsWithGuid;
  if (searchText.value) tmp = fastFilterAcc(tmp, searchText.value);
  return tmp;
});

const getSelectedGuid = computed(function () {
  if (selectedguid.value) {
    return selectedguid.value;
  } else {
    return '';
  }
});

function updateRadio(par) {
  let elem = allAccounts.value.find((x) => x.guid === par[2]);
  elem.active = par[0];
}

function updateAccount() {
  loadFromFirebasePinia({}, 'accounts', null, selectedguid.value).then(
    (snapshot) => {
      //we get from firebase original object
      let saveObj = appUtils.getObjectCopy(snapshot.val());

      let optionObj = { url: `accounts/{uid}/${selectedguid.value}` };
      saveNewUpdateCommon(saveObj, optionObj);
    }
  );
}

function saveNewUpdateCommon(saveObj, optionObj) {
  saveObj.description = description.value;
  saveObj.active = activeModal.value;
  saveObj.order = order.value;

  saveToFirebaseWithOptions(
    optionObj,
    saveObj,
    pageStore.loadAccountsFirebase
  ).then(() => {
    allAccounts.value = pageStore.accountsWithGuid;
    sortData(dataSorting.value, true);
  });
}

function saveNewAccount() {
  let optionObj = { url: `accounts/{uid}/{newKey}` };
  saveNewUpdateCommon({}, optionObj);
}

function saveAccounts() {
  if (selectedguid.value) {
    updateAccount();
  } else {
    saveNewAccount();
  }
}

function disableSave() {
  return false;
}

function addNewRec() {
  modalTransactionCaption.value = 'Add account';
  selectedguid.value = null;
  description.value = '';
  order.value = 0;
}

function sortData(field, reload = false) {
  console.log(
    `sortData | field=${field} | dataSorting=${dataSorting.value} | dataSortingOrder=${dataSortingOrder.value}`
  );

  if (reload) {
    if (field == dataSorting.value) {
      if (dataSortingOrder.value == 'asc') {
        dataSortingOrder.value = 'desc';
      } else {
        dataSortingOrder.value = 'asc';
      }
      appUtils.setSortingParams(
        field,
        dataSorting,
        dataSortingOrder,
        pageStore
      );
    }
  } else {
    appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
  }
}

function selectId(parObj) {
  console.log(parObj);
  selectedguid.value = parObj.guid;
  description.value = parObj.description;
  order.value = parObj.order;
  activeModal.value = parObj.active;
  modalTransactionCaption.value = 'Edit account [' + parObj.guid + ']';
}

function handleError() {
  error.value = null;
}

function toggleRadio(eventArgs) {
  console.log(eventArgs.target.checked);
  activeModal.value = eventArgs.target.checked;
}

function fastFilterAcc(tmp, searchText) {
  const fieldArr = ['description', 'accDesc', 'kontoDesc', 'remark1'];

  if (searchText.length <= 1) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}
</script>
