<template>
  <li v-if="balance != 0" :class="getBgClass(lvl, type)">
    <div :id="guid" @click="selectGuid($event, [guid, drillvl])">
      <div class="row pe-2">
        <div class="col-md-8">{{ description }}</div>
        <div class="col-md-4 text-end">
          <span v-if="balance < 0" :class="getClass(balance)"
            ><b> &nbsp;&nbsp;&nbsp; {{ getFormatedBalance() }} </b></span
          >
          <span v-if="balance > 0" :class="getClass(balance)"
            ><b> &nbsp;&nbsp;&nbsp; {{ getFormatedBalance() }} </b></span
          >
        </div>
      </div>
    </div>
    <slot name="details2"></slot>
    <slot name="details3"></slot>
    <slot name="details4"></slot>
  </li>
</template>

<script>
import appUtils from '../utils/appUtils.js';

export default {
  emits: ['select-guid'],
  props: ['guid', 'description', 'balance', 'drillvl', 'lvl', 'type'],

  methods: {
    selectGuid(event, params) {
      this.$emit('select-guid', params);
    },

    getFormatedBalance() {
      return appUtils.formatCurrency(this.balance);
    },

    getClass(balance) {
      if (balance < 0) return 'expence';
      else return 'income';
    },

    getBgClass(lvl, type) {
      let ret = '';

      if (type == true) ret = 'incomebg';
      else ret = 'expencebg';

      // if (!type) {
      //   // if (balance < 0) {
      //   if (description.includes('[')) ret = 'expencebg';
      //   else ret = 'expencebgwithoutpointer';
      // } else {
      //   if (description.includes('[')) ret = 'incomebg';
      //   else 'incomebgwithoutpointer';
      // }

      if (lvl === 2) ret = 'bglevel2';
      if (lvl === 3) ret = 'bglevel3';
      if (lvl === 4) ret = 'bglevel4';
      // console.log(`description: ${description} | ret: ${ret}`);
      return ret;
    },
  },
};
</script>

<style>
/* .incomebg {
  background-color: rgb(9, 112, 9);
} */

/* .incomebg {
  background-color: rgb(0, 55, 0);
}

.incomebgwithoutpointer {
  background-color: rgb(0, 55, 0);
} */

.expencebgwithoutpointer {
  cursor: default;
}

.expencebgwithoutpointer:hover {
  cursor: default;
}

.expence {
  color: rgb(223, 0, 0);
}

.expencebg:hover {
  cursor: pointer;
}

.bglevel2 {
  background-color: #343a40;
}

.bglevel3 {
  background-color: #495057;
}

.bglevel4 {
  background-color: #5e666d;
}
</style>
