<template>
  <a
    v-if="appUtils.getSettings('appSettings', props.settingName)"
    id="searchDefaultAcc1"
    class="jr-link me-2"
    :href="null"
    @click="clickHandler(props.settingName)"
    >{{
      appUtils.getGuidAccountObj(
        appUtils.getSettings('appSettings', props.settingName)
      ).description
    }}</a
  >
</template>

<script setup>
//utils
import appUtils from '../utils/appUtils.js';
import { defineProps, defineEmits } from 'vue';

const props = defineProps(['settingName']);
const emit = defineEmits(['clickHandler']);

function clickHandler(par) {
  emit('clickHandler', par);
}
</script>
