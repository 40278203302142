//import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { loadFromFirebasePinia } from '../components/utils/firebaseUtils';

//HOW TO USE:
//import { useFiltersStore } from '../../stores/FiltersStore';
//const filtersStore = useFiltersStore();

export const useFiltersStore = defineStore('FiltersStore', {
  state: () => {
    return {
      filters: [],
    };
  },

  actions: {
    setFilters(payload) {
      this.filters = payload;
    },

    loadFilters(payload) {
      this.filters = [];

      let promise = new Promise((resolve, reject) => {
        try {
          loadFromFirebasePinia(
            payload,
            'settings/',
            this.setFilters,
            'savedFilters'
          ).then((snapshot) => {
            if (snapshot) {
              resolve();
            } else {
              resolve();
            }
          });
        } catch (error) {
          reject(error);
          throw error;
        }
      });

      return promise;
    },
  },
});
