<template>
  <div class="text-light">
    <!-- <pre class="text-warning">{{ getAllAccounts }}</pre> -->

    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>

    <div class="text-warning mt-2 mb-3">
      <h4>Global Groups</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- radio -->

              <div class="row mb-3">
                <!-- <label
                  class="col-sm-3 form-check-label fw-normal text-warning"
                  for="radioactive"
                  >Active</label
                > -->

                <label
                  for="radioactive"
                  class="col-sm-3 form-check-label text-end"
                >
                  <span>&nbsp;&nbsp;&nbsp;Active</span>
                </label>

                <div class="col-sm-8 ms-3 form-check form-switch">
                  <input
                    @click="toggleRadio"
                    class="form-check-input"
                    type="checkbox"
                    id="radioactive"
                    v-model="activeModal"
                  />
                </div>

                <!-- <label
                  class="col-sm-4 form-check-label text-end fw-normal text-warning"
                  for="radioTransaction"
                  >Active</label
                >
                <div class="col-sm-8 form-check form-switch">
                  <input
                    @click="toggleRadio()"
                    class="form-check-input"
                    type="checkbox"
                    id="radioTransaction"
                    v-model="active"
                  />
                </div> -->
              </div>

              <!-- Description -->
              <div class="row mb-3 mt-4">
                <label
                  for="description"
                  class="col-sm-3 col-form-label text-end"
                  >Name
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="description"
                    v-model.trim="description"
                  />
                </div>
              </div>

              <div class="float-end mb-2">
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="save()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <base-card class="bg-dark text-light">
      <table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>
            <th>
              <a class="jr-link" :href="null" @click="sortData('active')"
                >Active</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('guid')">Guid</a>
            </th>
            <th>
              <a class="jr-link" :href="null" @click="sortData('name')">Name</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <account-item
            @select-id="selectId"
            @updateradio="updateRadio"
            v-for="item in getAllAccounts"
            :key="item.name"
            :index="item.name"
            :guid="item.guid"
            :description="item.name"
            :selectedguid="getSelectedGuid"
            :show-active="'1'"
            v-model:active="item.active"
          ></account-item>
        </tbody>
      </table>
    </base-card>
  </div>
</template>

<script setup>
import { useSettingStore } from '../../stores/SettingStore';
import AccountItem from '../../components/accounts/AccountItem.vue';
import { ref, computed, reactive, onMounted } from 'vue';
import {
  loadFromFirebasePinia,
  saveToFirebaseWithOptions,
} from '../../components/utils/firebaseUtils';
import appUtils from '../../components/utils/appUtils';

const pageStore = useSettingStore();

const error = ref(null);
const description = ref('');
const activeModal = ref(true);
const selectedguid = ref('');
const modalTransactionCaption = ref('Add global group');

const allGlobalGroups = reactive([]);
const dataSorting = ref('');
const dataSortingOrder = ref('asc');

onMounted(() => {
  console.log('GlobalGroups => mounted');
  allGlobalGroups.value = pageStore.globalGroups; // .accountsWithGuid;
});

const getAllAccounts = computed(function () {
  return pageStore.globalGroups;
  // return allAccounts.value;
});

const getSelectedGuid = computed(function () {
  if (selectedguid.value) {
    return selectedguid.value;
  } else {
    return '';
  }
});

function updateRadio(par) {
  let elem = allGlobalGroups.value.find((x) => x.guid === par[2]);
  elem.active = par[0];
}

function updateData() {
  loadFromFirebasePinia(
    {},
    'settings',
    null,
    'globalGroups/' + selectedguid.value
  ).then((snapshot) => {
    // console.log(snapshot.val());

    //we get from firebase original object
    let saveObj = appUtils.getObjectCopy(snapshot.val());
    // console.log(saveObj);

    let optionObj = {
      url: `settings/{uid}/globalGroups/${selectedguid.value}`,
    };
    //console.log(optionObj);
    saveNewUpdateCommon(saveObj, optionObj);
  });
}

function saveNewUpdateCommon(saveObj, optionObj) {
  saveObj.name = description.value;
  saveObj.active = activeModal.value;

  saveToFirebaseWithOptions(optionObj, saveObj, pageStore.loadSettings).then(
    () => {
      allGlobalGroups.value = pageStore.accountsWithGuid;
    }
  );
}

function saveNewData() {
  let optionObj = { url: `settings/{uid}/globalGroups/{newKey}` };
  saveNewUpdateCommon({}, optionObj);
}

function save() {
  if (selectedguid.value) {
    updateData();
  } else {
    saveNewData();
  }
}

function disableSave() {
  return false;
}

function addNewRec() {
  modalTransactionCaption.value = 'Add global group';
  selectedguid.value = null;
  description.value = '';
}

function sortData(field) {
  appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
}

function selectId(parObj) {
  console.log(parObj);
  selectedguid.value = parObj.guid;
  description.value = parObj.description;
  activeModal.value = parObj.active;
  modalTransactionCaption.value = 'Edit global group [' + parObj.guid + ']';
}

function handleError() {
  error.value = null;
}

function toggleRadio(eventArgs) {
  console.log(eventArgs.target.checked);
  activeModal.value = eventArgs.target.checked;
}
</script>
