import { getAuth, updateProfile } from 'firebase/auth';

import { defineStore } from 'pinia';

//import { loadFromFirebase } from './../components/utils/firebaseUtils';

//HOW TO USE:
//import { useUserStore } from '../../stores/UserStore';
//const userStore = useUserStore();

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      userId: null,
      transFilter: {},
    };
  },
  getters: {},
  actions: {
    dummy() {
      console.log('a12345');
      return true;
    },

    updateUserProfile() {
      const auth = getAuth();
      updateProfile(auth.currentUser, {
        displayName: 'Jakob Aljaz Ribic',
        photoURL:
          'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5b51c74d78079611441b52cf/272edb1c-5d05-4e93-980f-891a5f3ee97d/128',
      })
        .then(() => {
          console.log('Profile updated!');
        })
        .catch((error) => {
          console.log('Error while updating Profile!', error);
        });
    },

    async registerUser(payload) {
      let authObj = {
        email: payload.email,
        password: payload.password,
        returnSecureToken: true,
      };

      console.log(authObj);

      let url =
        'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBvsYEtztD8KhzwuUe2CZJ_pkaVRpduOyY';

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(authObj),
      });

      console.log('response');
      console.log(response);
      const responseData = await response.json();

      if (!response.ok) {
        const error = new Error(
          responseData.message ||
            'Failed to authenticate. Check your login data.'
        );
        throw error;
      }

      // const expiresIn = +responseData.expiresIn * 1000;
      // const expiresIn = 5000;
      // const expirationDate = new Date().getTime() + expiresIn;

      // timer = setTimeout(function () {
      //   context.dispatch('autoLogout');
      // }, expiresIn);

      // context.commit('setUser', {
      //   token: responseData.idToken,
      //   userId: responseData.localId,
      //   email: responseData.email,
      // });
    },
  },
});
