<template>
  <div class="accordion accordion-flush" :id="accordionId">
    <div class="accordion-item bg-dark">
      <h2 class="accordion-header" :id="accordionHeaderId">
        <button
          class="accordion-button collapsed bg-dark"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#' + accordionFlushId"
          aria-expanded="false"
          :aria-controls="accordionFlushId"
        >
          <h4 class="text-warning mt-1">
            Balance
            {{
              props.globalGroup ? '[ ' + props.globalGroupName + ' ]' : ' All'
            }}
          </h4>
          <!-- <span class="text-warning mt-1"><h4>Balance</h4></span> -->
        </button>
      </h2>
      <div
        :id="accordionFlushId"
        class="accordion-collapse collapse"
        :aria-labelledby="accordionHeaderId"
        :data-bs-parent="'#' + accordionId"
      >
        <div class="accordion-body">
          <base-card class="bg-dark text-light">
            <table class="table table-striped table-dark">
              <tbody class="align-middle">
                <tr>
                  <td class="text-warning"><b>Total:</b></td>
                  <td class="text-warning"></td>
                  <td class="text-end text-warning">
                    <b> {{ appUtils.formatCurrency(getGrandTotal) }}</b>
                  </td>
                </tr>

                <tr
                  v-for="item in getAccountGroups.value"
                  :key="item.description"
                >
                  <td class="me-5" v-if="item.suma != 0">
                    {{ item.description }}
                  </td>

                  <td v-if="item.suma != 0">
                    <table class="table table-light" style="width: 50%">
                      <tbody class="align-middle">
                        <template
                          v-for="item2 in item.accounts"
                          :key="item2.key"
                        >
                          <!-- {{ item2 }} -->

                          <template
                            v-for="item3 in getAccountBalance.value"
                            :key="item3.guid"
                          >
                            <tr v-if="item3.guid == item2">
                              <td
                                v-if="item3.balance != 0"
                                class="jr-balance text-start"
                              >
                                {{ item3.description }}
                              </td>
                              <td
                                v-if="item3.balance != 0"
                                class="jr-balance text-end"
                              >
                                {{ appUtils.formatCurrency(item3.balance) }}
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </td>

                  <td v-if="item.suma != 0" class="text-end">
                    {{ appUtils.formatCurrency(item.suma) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </base-card>
        </div>
      </div>
    </div>
  </div>

  <div></div>
</template>

<script setup>
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { reactive, onMounted, onBeforeMount, computed, defineProps } from 'vue';
//import { ref, toRef, reactive, computed, onMounted, onUpdated } from 'vue';
import config from '../../store/global.js';
import { initializeApp } from 'firebase/app';
import appUtils from '../utils/appUtils';
import { reject } from 'q';
import { useAccountStore } from '../../stores/AccountStore';

let balanceAcc = reactive([]);
let accountGroups = reactive([]);
let accountGroups2 = reactive([]);

let accordionId = 'accordionId_0';
let accordionHeaderId = 'accordionHeaderId_0';
let accordionFlushId = 'accordionFlushId_0';

const props = defineProps(['globalGroup', 'globalGroupName']);

onBeforeMount(() => {
  //console.log('BalanceBoard => onBeforeMount');
  accordionId = 'accordionId_' + props.globalGroup;
  accordionHeaderId = 'accordionHeaderId_' + props.globalGroup;
  accordionFlushId = 'accordionFlushId_' + props.globalGroup;
});

onMounted(() => {
  // console.log('BalanceBoard => onMounted');
  // console.log('globalGroup', props.globalGroup);
  accountBalance().then(arrangeAccountGroups);
});

function arrangeAccountGroups() {
  //accountGroups2.length = 0;
  accountGroups2.value = [];

  const accountStore = useAccountStore();
  accountGroups.value = accountStore.accountGroups;

  for (let i = 0; i < accountGroups.value.length; i++) {
    //console.log(accountGroups.value[i].description);
    let tmp = {};
    let suma = 0;
    for (let j = 0; j < accountGroups.value[i]['accounts'].length; j++) {
      //console.log('============>' + accountGroups.value[i]['accounts'][j]);
      //suma = getValueBalance(accountGroups.value[i]['accounts'][j]);
      //accountGroups.value[i].suma = suma;
      tmp = accountGroups.value[i];
      suma = appUtils.getSumofTwoNumbers(
        suma,
        getValueBalance(accountGroups.value[i]['accounts'][j])
      );
      //console.log(suma);
      //accountGroups.value[i] = tmp;
    }
    tmp.suma = suma;

    if (props.globalGroup) {
      if (accountGroups.value[i]['globalGroup'] == props.globalGroup) {
        accountGroups2.value.push(tmp);
      }
    } else {
      accountGroups2.value.push(tmp);
    }
  }

  // console.log(accountGroups2);
}

function getValueBalance(account) {
  //console.log('Find this guid:<' + account + '>');
  let ret = 0;
  for (let i = 0; i < balanceAcc.value.length; i++) {
    //console.log('     <' + balanceAcc.value[i].guid + '>');
    if (balanceAcc.value[i].guid === account) {
      //console.log('   BINGO! ' + balanceAcc.value[i].balance);
      ret = balanceAcc.value[i].balance;
      break;
    }
  }
  return ret;
}

function accountBalance() {
  let promise = new Promise((resolve) => {
    try {
      const firebaseConfig = config.global().firebaseConfig;
      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);

      // console.log('getAuth');
      // console.log(getAuth());
      //console.log(getAuth().currentUser.uid);
      if (!getAuth().currentUser) return false;

      const balanceAccFb = ref(db, `balance_acc/${getAuth().currentUser.uid}`);

      onValue(balanceAccFb, (snapshot) => {
        const data = snapshot.val();
        balanceAcc.value = appUtils.getArrayWithGuid(data, [
          'balance',
          'description',
        ]);
        console.log(balanceAcc);
        resolve();
        arrangeAccountGroups();
      });
    } catch (error) {
      reject(error.message);
      throw error;
    }
  });

  return promise;
}

const getAccountBalance = computed(function () {
  return balanceAcc;
});

const getAccountGroups = computed(function () {
  return accountGroups2;
});

const getGrandTotal = computed(function () {
  let tmp = 0;
  if (accountGroups2.value) {
    for (let i = 0; i < accountGroups2.value.length; i++) {
      tmp = appUtils.getSumofTwoNumbers(tmp, accountGroups2.value[i].suma);
      // console.log(accountGroups2.value[i].suma);
    }
  }
  return tmp;
});
</script>
