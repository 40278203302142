<template>
  <tr :id="guid" :class="setclass()">
    <td>
      <!-- {{ count }} -->

      <button
        class="btn btn-sm bg-warning me-2"
        @click="setSelectedId($event, [guid, id, lvl, description])"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>

      <button
        v-if="lvl < 4"
        class="btn btn-sm bg-warning me-2"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
        @click="setNewChildCategory([guid, id, lvl, description])"
      >
        <font-awesome-icon icon="fa-solid fa-square-plus" />
      </button>

      <button
        v-if="isPlusVisible()"
        class="btn btn-sm bg-warning me-2"
        @click="showChildren($event, [guid, id, lvl, description])"
      >
        <font-awesome-icon icon="fa-arrow-turn-down" />
      </button>
    </td>

    <td :class="setclass()">
      <div class="col-sm-8 form-check form-switch">
        <!-- disabled -->
        <input
          @click="changeActive($event, guid)"
          class="form-check-input"
          type="checkbox"
          :checked="active"
        />
      </div>
    </td>

    <td :class="setclass()">
      {{ getDesc }}
    </td>

    <td>
      <slot name="cat2"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  emits: [
    'select-id',
    'delete-id',
    'change-active',
    'select-id-new-child',
    'show-children',
  ],
  props: [
    'guid',
    'id',
    'description',
    'lvl',
    'count',
    'active',
    'selectedguid1',
    'selectedguid2',
    'selectedguid3',
    'selectedguid4',
  ],

  //activated() {},

  data() {
    // return { isActive: false, selectedId: null };
    return { isActive: false };
  },
  methods: {
    isPlusVisible() {
      if (this.count > 0) return true;
      else return false;
    },

    setSelectedId(event, params) {
      //console.log('params');
      //console.log(params);
      this.$emit('select-id', params);
    },

    setNewChildCategory(params) {
      console.log('params');
      console.log(params);
      //params.lvl = this.lvl + 1;

      this.$emit('select-id-new-child', params);
    },

    showChildren(event, params) {
      this.$emit('show-children', params);
    },

    show() {
      console.log(this.guid);
      console.log(this.selectedguid1);
    },

    deleteSelectedId(event, value) {
      this.$emit('delete-id', value);
    },

    changeActive(event, value) {
      this.$emit('change-active', event, value);
    },

    setclass() {
      {
        return {
          isActivejr:
            (this.selectedguid1 === this.guid && this.lvl === 1) ||
            (this.selectedguid2 === this.guid && this.lvl === 2) ||
            (this.selectedguid3 === this.guid && this.lvl === 3) ||
            (this.selectedguid4 === this.guid && this.lvl === 4)
              ? true
              : false,
        };
      }
    },
  },

  computed: {
    getKontoFull() {
      let ret = '';
      if (this.idknt1) ret += this.idknt1;
      if (this.idknt2) ret += ' | ' + this.idknt2;
      if (this.idknt3) ret += ' | ' + this.idknt3;
      if (this.idknt4) ret += ' | ' + this.idknt4;
      return ret;
    },

    getDesc() {
      let ret = this.description;
      if (this.count) ret += ' (' + this.count.toString() + ')';
      return ret;
    },
  },
};
</script>

<style scoped>
li {
  /* margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px; */
  /* padding: 0.75rem; */

  margin: 0.5rem 0;
  border: 4px solid #412ac0;
  /* border: 4px solid #424242; */
  border-radius: 5px;
  padding: 0.75rem;
}

h3 {
  font-size: 1.3rem;
}

h3,
h4 {
  margin: 0rem 0;
}

/* div {
  margin: 0.3rem 0;
} */

.actions {
  display: flex;
  justify-content: flex-end;
}

/* .isActivejr {
  background-color: #3d008d5b !important;
} */

/*.litrans {
} */
</style>
