<template>
  <div class="jr-bg-black text-light">
    <div class="text-warning mt-2 mb-3">
      <h4>Account Groups</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <base-card>
      <li>
        <base-text-search v-model="searchText"></base-text-search>
      </li>
    </base-card>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- radio -->

              <!-- <div class="row mb-3">
                <label
                  for="radioactive"
                  class="col-sm-3 form-check-label text-end"
                >
                  <span>&nbsp;&nbsp;&nbsp;Active</span>
                </label>

                <div class="col-sm-8 ms-3 form-check form-switch">
                  <input
                    @click="toggleRadio"
                    class="form-check-input"
                    type="checkbox"
                    id="radioactive"
                    v-model="activeModal"
                  />
                </div>              
              </div> -->

              <!-- Description -->
              <div class="row mb-3 mt-4">
                <label
                  for="description"
                  class="col-sm-3 col-form-label text-end"
                  >Name
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="description"
                    v-model.trim="accountsGroupName"
                  />
                </div>
              </div>

              <!-- v-model.trim="description" -->

              <div class="float-end mb-2">
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="save()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <base-card class="bg-dark text-light">
      <table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>

            <!-- <th>
              <a class="jr-link" :href="null" @click="sortData('guid')"
                >Active</a
              >
            </th> -->
            <th>
              <a class="jr-link" :href="null" @click="sortData('guid')">Guid</a>
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('description')"
                >Name</a
              >
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- @updateradio="updateRadio" -->
          <account-item
            @select-id="selectId"
            v-for="item in getAccountGroups"
            :key="item.name"
            :index="item.name"
            :guid="item.guid"
            :description="item.description"
            :selectedguid="getSelectedGuid"
            :show-active="'0'"
          ></account-item>
          <!-- v-model:active="item.active" -->
        </tbody>
      </table>
    </base-card>

    <!-- <div class="text-light">
      <pre>{{ getAccountGroups.value }}</pre>
      <div>=================================</div>
      <pre>{{ accountGroups.value }}</pre>
    </div> -->

    <div class="ms-2 mt-4 text-warning">
      <h4>Account Groups Details</h4>

      <div class="text-light" v-if="1 == 0">
        <pre>{{ getSettings }}</pre>
      </div>

      <!-- ===================================== -->

      <base-card class="bg-dark text-light">
        <div class="container-fluid float-start alternate-row-colors">
          <div
            class="row pt-3 pb-3 ps-1 align-middle"
            v-for="item in getAccountGroups"
            :key="item.description"
          >
            <!-- <div>{{ item.description }}</div> -->

            <div class="col-3">
              <base-card>
                <div>Global groups</div>
                <hr />
                <global-groups-dropdown
                  class="mb-3"
                  @onchange="onChangeGlobalGroup"
                  :modelValue="item.globalGroup"
                  :accountGroup="item.guid"
                ></global-groups-dropdown>
                <base-button @click="updateGlobalGroup(item.guid)"
                  >Update</base-button
                >
              </base-card>
            </div>

            <div class="col-6">
              <base-card>
                <div class="mb-1">
                  Accounts in group <b>[{{ item.description }}]</b>
                </div>
                <div class="text-light">Guid: {{ item.guid }}</div>
                <hr />
                <div
                  class="row ms-1 me-1 pt-2 pb-2 text-light"
                  v-for="item2 in item.accounts"
                  :key="item2"
                >
                  <div class="col-10">
                    {{ getDesc(item2) }}
                  </div>

                  <div class="col-2">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="removeAccountFromGroup(item.guid, item2)"
                    >
                      <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
                    </button>

                    <!-- <base-button
                        
                        >X</base-button -->
                  </div>
                </div>
              </base-card>
            </div>

            <div class="col-3">
              <base-card>
                <div>Accounts</div>
                <hr />
                <account-dropdown
                  class="mb-3"
                  :modelValue="item.addAccount"
                  :additionalParam="item.guid"
                  @onchange="onAddAccount"
                ></account-dropdown>
                <base-button @click="addAccountToGroup(item.guid)"
                  >Add</base-button
                >
              </base-card>
            </div>

            <!-- <div class="col-2">2</div>
            <div class="col-2">3</div>
            <div class="col-2">4</div> -->
            <hr />
          </div>
        </div>
      </base-card>

      <!-- ===================================== -->
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUpdated, computed, ref, reactive } from 'vue';
import { getDatabase, ref as reffb, push, child } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useAccountStore } from '../../stores/AccountStore';
import { useSettingStore } from '../../stores/SettingStore';
import {
  saveToFirebase,
  saveToFirebaseWithOptions,
  loadFromFirebasePinia,
} from '../../components/utils/firebaseUtils';

import accountDropdown from '../../components/accounts/accountDropdown.vue';
import GlobalGroupsDropdown from '../../components/globalGroups/GlobalGroupsDropdown';
import AccountItem from '../../components/accounts/AccountItem.vue';
import appUtils from '../../components/utils/appUtils';

let userUid = null;
let db = null;
let accountsGroupName = ref('');
let accountGroups = reactive([]);
let accounts = reactive([]);
const selectedguid = ref('');
const modalTransactionCaption = ref('Add account group');
const accountStore = useAccountStore();
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const searchText = ref(null);

onMounted(() => {
  userUid = getAuth().currentUser.uid;
  db = getDatabase();

  loadAccountGroups();
  accounts.value = accountStore.accountsWithGuid;
});
onUpdated(() => {});

function loadAccountGroups() {
  accountGroups.value = accountStore.accountGroups;

  accountGroups.value.forEach((element) => {
    element.addAccount = '';
  });
}

function onAddAccount(values) {
  let foundObject = accountGroups.value.find(
    (obj) => obj.guid === values.additionalParam
  );

  if (foundObject) {
    if (values.value != '0') foundObject.addAccount = values.value;
  }
}

function onChangeGlobalGroup(value) {
  let foundObject = accountGroups.value.find(
    (obj) => obj.guid === value.accountGroup
  );

  if (foundObject) {
    foundObject.globalGroup = value.value;
    if (value.value == '') foundObject.globalGroup = null;
  }
}

const getAccountGroups = computed(function () {
  let tmp = accountStore.accountGroups;
  if (searchText.value) {
    tmp = fastFilterAccGroups(tmp, searchText.value);
    console.log('getAccountGroups');
  }
  return tmp;
});

function fastFilterAccGroups(tmp, searchText) {
  const fieldArr = ['description'];

  if (searchText.length <= 1) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}

const getSettings = computed(function () {
  const tmp = useSettingStore().settings;
  return tmp;
});

function getDesc(guid) {
  //console.log(guid);
  if (guid && guid != undefined) {
    if (accounts.value) {
      let tmp = accounts.value.filter((el) => el.guid == guid);
      if (tmp.length == 1) {
        return tmp[0].description;
      } else return '';
    }
  }
  return '';
}

function updateGlobalGroup(guid) {
  let saveObj = appUtils.getCopyOfObject(accountGroups, guid, 'guid');

  if (saveObj) {
    if (accountsGroupName.value) saveObj.description = accountsGroupName.value;

    saveObj.accounts.forEach((element) => {
      saveObj[element] = 1;
    });
    updateGroupsPrivate(saveObj, guid);
  }
}

function removeAccountFromGroup(guidGroup, guidAccount) {
  // console.log(`guidGroup:${guidGroup} | guidAccount:${guidAccount}`);
  let saveObj = appUtils.getCopyOfObject(accountGroups, guidGroup, 'guid');

  saveObj.accounts.forEach((element) => {
    saveObj[element] = 1;
  });

  delete saveObj[guidAccount];
  updateGroupsPrivate(saveObj, guidGroup);
}

function addAccountToGroup(guidGroup) {
  // console.log(`guid:${guidGroup}`);
  let saveObj = appUtils.getCopyOfObject(accountGroups, guidGroup, 'guid');

  if (saveObj.addAccount != '') {
    saveObj.accounts.push(saveObj.addAccount);

    saveObj.accounts.forEach((element) => {
      saveObj[element] = 1;
    });

    updateGroupsPrivate(saveObj, guidGroup);
  }
}

function updateGroupsPrivate(saveObj, guid) {
  //deleting unnecessary properties
  delete saveObj.guid;
  delete saveObj.suma;
  delete saveObj.accounts;
  delete saveObj.addAccount;

  let path = `settings/${userUid}/accountGroups/${guid}`;
  // console.log(path);

  saveToFirebase(
    path,
    saveObj,
    accountStore.loadAccountGroups,
    loadAccountGroups
  );
}

function disableSave() {
  return false;
}

const getSelectedGuid = computed(function () {
  if (selectedguid.value) {
    return selectedguid.value;
  } else {
    return '';
  }
});

function selectId(parObj) {
  console.log(parObj);
  selectedguid.value = parObj.guid;
  accountsGroupName.value = parObj.description;
  // activeModal.value = parObj.active;
  modalTransactionCaption.value = 'Edit account group [' + parObj.guid + ']';
}

function addNewRec() {
  modalTransactionCaption.value = 'Add account group';
  selectedguid.value = null;
  accountsGroupName.value = '';
}

function sortData(field) {
  appUtils.setSortingParams(field, dataSorting, dataSortingOrder);
}

function save() {
  if (selectedguid.value) {
    updateData();
  } else {
    saveNewData();
  }
}

function saveNewData() {
  var saveObj = {
    description: accountsGroupName.value,
  };

  let newKey = push(child(reffb(db), `settings/${userUid}/accountGroups`)).key;

  saveToFirebase(
    `settings/${userUid}/accountGroups/${newKey}`,
    saveObj,
    accountStore.loadAccountGroups,
    loadAccountGroups
  );
}

function updateData() {
  loadFromFirebasePinia(
    {},
    'settings',
    null,
    'accountGroups/' + selectedguid.value
  ).then((snapshot) => {
    // console.log(snapshot.val());

    //we get from firebase original object
    let saveObj = appUtils.getObjectCopy(snapshot.val());

    let optionObj = {
      url: `settings/{uid}/accountGroups/${selectedguid.value}`,
    };
    saveNewUpdateCommon(saveObj, optionObj);
  });
}

function saveNewUpdateCommon(saveObj, optionObj) {
  saveObj.description = accountsGroupName.value;

  saveToFirebaseWithOptions(
    optionObj,
    saveObj,
    accountStore.loadAccountGroups
  ).then(() => {
    //console.log('Done saveNewUpdateCommon');
    accountGroups.value = accountStore.accountGroups;
  });
}
</script>

<style scoped>
/* Custom CSS for alternate row colors */
.alternate-row-colors .row:nth-child(even) {
  background-color: #212529; /* Alternate color for even rows */
}
.alternate-row-colors .row:nth-child(odd) {
  background-color: #2c3034; /* Alternate color for odd rows */
}
</style>
