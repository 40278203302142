import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { loadFromFirebasePinia } from '../components/utils/firebaseUtils';

//HOW TO USE:
//import { useRulesStore } from '../../stores/RulesStore';
//const rulesStore = useRulesStore();

export const useRulesStore = defineStore('RulesStore', {
  state: () => {
    return {
      rules: [],
      rulesGuid: [],
    };
  },
  getters: {
    getRulesFields() {
      return [
        'guidaccount',
        'field',
        'operator',
        'text1',
        'text2',
        'text3',
        'guidknt1',
        'guidknt2',
        'guidknt3',
        'guidknt4',
      ];
    },
  },
  actions: {
    setSorting(payload) {
      this.rulesGuid = appUtils.setSortingUtil(payload, this.rulesGuid);
    },

    setRules(payload) {
      this.rules = payload;
    },

    loadRules(payload) {
      this.rules = [];

      let promise = new Promise((resolve, reject) => {
        try {
          loadFromFirebasePinia(payload, 'rules', this.setRules).then(
            (snapshot) => {
              if (snapshot) {
                this.addAdditionalFields(snapshot);
                resolve();
              } else {
                resolve();
              }
            }
          );
        } catch (error) {
          reject(error);
          throw error;
        }
      });

      return promise;
    },

    addAdditionalFields(snapshot) {
      let props = this.getRulesFields;
      // console.log(props);
      let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);

      for (let i = 0; i < tmpArray.length; i++) {
        if (tmpArray[i].guidaccount) {
          tmpArray[i]['accDesc'] = appUtils.getGuidAccountObj(
            tmpArray[i].guidaccount
          ).description;
        } else {
          tmpArray[i]['accDesc'] = null;
        }

        if (tmpArray[i].guidknt1) {
          tmpArray[i]['kontoDesc'] = appUtils.getKontoDescFull(
            tmpArray[i].guidknt1,
            tmpArray[i].guidknt2,
            tmpArray[i].guidknt3,
            tmpArray[i].guidknt4
          );
        } else {
          tmpArray[i]['kontoDesc'] = null;
        }
      }

      //   console.log('rules => tmpArray');
      //   console.log(tmpArray);
      this.rulesGuid = tmpArray;
      //context.commit('setRulesGuid', tmpArray);
    },
  },
});
