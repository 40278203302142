<template>
  <div>
    <select
      @change="onchange($event)"
      class="form-select form-select-sm"
      id="globalGroup"
      name="globalGroup"
      :value="modelValue"
    >
      <option :key="null" :value="null"></option>
      <option
        v-for="item in getGlobalGroup"
        :key="item.guid"
        :value="item.guid"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { useSettingStore } from '../../stores/SettingStore';
import { computed, defineEmits, defineProps } from 'vue';

const settingStore = useSettingStore();

const emit = defineEmits(['onchange']);

const props = defineProps(['modelValue', 'accountGroup']);

function onchange(event) {
  emit('onchange', {
    value: event.target.value,
    accountGroup: props.accountGroup,
  });
}

const getGlobalGroup = computed(function () {
  let tmp = settingStore.globalGroups;
  tmp = tmp.filter((el) => el.active == true);
  return tmp;
});
</script>
