import { child, get, ref } from 'firebase/database';

import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { getAuth } from 'firebase/auth';

//HOW TO USE:
//import { useInvestSymbolStore } from '../../stores/InvestSymbolStore';
//const investSymbolStore = useInvestSymbolStore();

export const useInvestSymbolStore = defineStore('InvestSymbolStore', {
  state: () => {
    return {
      symbols: [],
      symbolsWithGuid: [],
    };
  },
  getters: {
    getSymbolFields() {
      return ['symbol', 'description', 'active', 'type', 'currency', 'order'];
    },
  },

  actions: {
    setSorting(payload) {
      this.symbolsWithGuid = appUtils.setSortingUtil(
        payload,
        this.symbolsWithGuid
      );
    },

    loadInvestSymbolsFirebase(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('loadInvestSymbolsFirebase');
          const db = payload.db;
          let userUid = getAuth().currentUser.uid;

          get(child(ref(db), `invest_symbols/${userUid}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                this.symbols = snapshot.val();

                let props = this.getSymbolFields;
                let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);
                //replace all null values for active field with true
                // const updatedData = tmpArray.map((item) => {
                //   if (item.active === null) {
                //     return { ...item, active: true };
                //   } else if (item.active === undefined) {
                //     return { ...item, active: true };
                //   }
                //   return item;
                // });

                this.symbolsWithGuid = tmpArray;
                // console.log('=========== updatedData ===========');
                // console.log(updatedData);
                resolve();
              } else {
                resolve();
                this.symbols = [];
                this.symbolsWithGuid = [];
                // console.log('No data available.');
              }
            })
            .catch((error) => {
              reject(error.message);
              throw error;
              // console.error(error);
            });
        } catch (error) {
          reject(error.message);
          throw error;
        }
      });
      return promise;
    },
  },
});
