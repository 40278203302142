<template>
  <Datepicker
    id="selectedDate"
    @update:modelValue="ondatechange"
    :model-value="modelValue"
    format="dd.MM.yyyy"
    :min-date="minDate"
    :max-date="maxDate"
    :enable-time-picker="false"
    :year-range="yearRange"
    auto-apply
    locale="en-GB"
  ></Datepicker>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import { defineProps, defineEmits } from 'vue';

defineProps(['yearRange', 'minDate', 'maxDate', 'modelValue']);
const emit = defineEmits(['ondatechange']);

function ondatechange(eventArgs) {
  //console.log('selectDate:', eventArgs);
  emit('ondatechange', eventArgs);
}
</script>
