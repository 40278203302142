<template>
  <base-card v-if="props.showTransactionsFlag">
    <!-- <pre class="text-warning">{{ props.showTransactionsFlag }}</pre> -->

    <h5>Transactions</h5>

    <li>
      <base-text-search v-model="searchText"></base-text-search>
    </li>

    <li>
      <span class="text-warning">Category:</span
      ><span class="text-light">&nbsp;&nbsp;{{ categoryDescLong }}</span>
    </li>

    <li class="bg-dark text-light">
      <span class="text-warning">Number of records:</span>
      {{ getFilteredTrans.length }}
      <div>
        <span class="text-warning">Total:</span>
        {{ appUtils.formatCurrency(sumTransComputed) }}
      </div>
    </li>

    <li class="pe-2">
      <table class="table table-striped table-dark">
        <transaction-header
          @sortDataEvent="sortDataEvent"
          :configComponent="{
            editVisible: false,
            checkboxVisible: false,
            deleteVisible: false,
          }"
        >
        </transaction-header>

        <tbody class="align-middle">
          <transaction-item
            v-for="item in getFilteredTrans"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :guidaccount="item.guidaccount"
            :desc="item.description"
            :amount="item.amount"
            :guidknt1="item.guidknt1"
            :guidknt2="item.guidknt2"
            :guidknt3="item.guidknt3"
            :guidknt4="item.guidknt4"
            :date="item.date"
            :remark1="item.remark1"
            :selectedtransid="null"
            :accDesc="item.accDesc"
            :kontoDesc="item.kontoDesc"
            :configComponent="{
              editVisible: false,
              checkboxVisible: false,
              deleteVisible: false,
            }"
          ></transaction-item>
        </tbody>
      </table>
    </li>
  </base-card>
</template>

<style scoped>
/* .jractive {
  background-color: #282828;
  font-weight: bold;
} */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  /* padding: 0.75rem; */
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem; */

  padding: 0.5rem 0rem 0.5rem 0.5rem;
}

/* .a {
  color: aqua;
} */
</style>

<script setup>
//component added 2024-08-13

//VUE core
import { defineProps, onMounted, computed, ref } from 'vue';

//components
import TransactionHeader from '../../components/transactions/TransactionHeader.vue';
import TransactionItem from '../../components/transactions/TransactionItem.vue';

//utils
import appUtils from '../utils/appUtils';
import { fastFilterTrans } from '../../pages/transactions/utilsTransactions.js';

//store
import { useTransStore } from '../../stores/TransStore';

let categoryDescLong = ref('');

const pageStore = useTransStore();
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const searchText = ref(null);
const props = defineProps(['getFilteredTrans', 'showTransactionsFlag']);

onMounted(() => {
  //console.clear();
  console.log('onMounted => TransactionsForAnalysis');
});

const getFilteredTrans = computed(function () {
  let tmp = props.getFilteredTrans;
  //fast search
  if (searchText.value) tmp = fastFilterTrans(tmp, searchText.value);
  return tmp;
});

function sortDataEvent(eventArgs) {
  console.log('AnalysisList => sortDataEvent');

  appUtils.setSortingParams(
    eventArgs.field,
    dataSorting,
    dataSortingOrder,
    pageStore
  );
}

const sumTransComputed = computed(function () {
  let suma = 0;
  for (var i = 0; i < getFilteredTrans.value.length; i++) {
    suma += Number(getFilteredTrans.value[i].amount);
  }
  return appUtils.fixCurrency(suma);
});
</script>
